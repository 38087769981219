import React, { useState, useRef } from "react";
import { Container, Col, Row } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import { compose } from "recompose";
import Validator from "validatorjs";
import { useDispatch } from "react-redux";
import {
  supportValidation,
  supportValidationMsg,
  errorMessage,
  errorCode,
} from "../../util/Validatios";
import LoadingIndicator from "../../components/LoadingIndicator";
import { withFirebase } from "../../components/Firebase";
import FloatingInputBox from "../../components/FloatingInputBox";
import useEnterKeyListener from "../../helpers/useEnterKeyListener";
import { postSupportRequest } from "../../Action/support";
import { fbSetEvent, fbSetParams } from "../../Action/firebaseAnalytics";
import { CAPTCHA_FAILURE } from "../../constants/firebaseAnalytics";
import "./index.css";

const Support = ({ firebase }) => {
  const [inputVal, setInputVal] = useState({});
  const [error, setError] = useState("");
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isTyping, setIsTyping] = useState(false);
  const dispatch = useDispatch();
  const supportCaptchaRef = useRef(null);
  const onChangeHandler = (evt) => {
    const value = evt.target.value;
    setInputVal({
      ...inputVal,
      [evt.target.name]: value,
    });
  };

  const onInputFocusHandler = () => {
    setIsTyping(true);
  };

  const onPointerOutHandler = () => {
    setIsTyping(false);
  };

  const validate = () => {
    let validation = new Validator(
      {
        name: inputVal.name,
        emailAddress: inputVal.emailAddress,
        message: inputVal.message,
      },
      supportValidation,
      supportValidationMsg,
    );

    if (validation.fails()) {
      let errorMessages = "";

      if (validation.errors.get("name").length) {
        errorMessages = validation.errors.get("name");
      } else if (validation.errors.get("emailAddress").length) {
        errorMessages = validation.errors.get("emailAddress");
      } else if (validation.errors.get("message").length) {
        errorMessages = validation.errors.get("message");
      }

      setError(errorMessages);
      return false;
    }
    return true;
  };

  const handleResetCaptchaContainer = () => {
    if (supportCaptchaRef?.current?.innerHTML) {
      supportCaptchaRef.current.innerHTML = `<div id="supportRecaptcha" className="recaptcha-container"></div>`;
    }
  };

  const handleFBEvents = (eventName, params) => {
    dispatch(fbSetEvent(eventName));
    dispatch(fbSetParams(params));
  };

  const onSupportFormSubmit = () => {
    setError("");
    setSuccess(false);
    if (!validate()) {
      return;
    }

    setLoading(true);

    firebase
      .recaptchaVerifier("supportRecaptcha")
      .then(() => {
        postSupportRequest(inputVal)
          .then((result) => {
            if (result.status === 200) {
              setSuccess(true);
            } else {
              setError(errorMessage.others);
            }
            handleResetCaptchaContainer();
            setLoading(false);
          })
          .catch((e) => {
            if (e && e.code === errorCode.missingParams) {
              setError(e.message);
            } else {
              setError(errorMessage.others);
            }
            handleResetCaptchaContainer();
            setLoading(false);
          });
      })
      .catch((error) => {
        handleResetCaptchaContainer();
        if (!error.code && error.indexOf(CAPTCHA_FAILURE) !== -1) {
          handleFBEvents(CAPTCHA_FAILURE, {});
        }
      });
  };

  useEnterKeyListener({
    querySelectorToExecuteClick: "#supportSubmitBtn",
  });

  return (
    <div className="support-main-container privacy-mobile-cotainer">
      <div ref={supportCaptchaRef} className="recaptcha-container">
        <div id="supportRecaptcha" className="recaptcha-container"></div>
      </div>

      <div className="privacy-container support-section">
        <Container className="support-inner-container">
          <div className="support-description">
            <Row className="privacy-title-row">
              <Col lg={6} className="support-img-col">
                <div>
                  <img src="/images/mail_send.svg" className="img-fluid" alt="group" />
                </div>
              </Col>
              <Col lg={6} className="support-input-container">
                {loading && <LoadingIndicator loaderStyle="homePageLoader" />}
                <div className="support-title">How can we help?</div>
                <div className="support-sub-title">
                  Submit your inquiry below and we’ll get back to you shortly.
                </div>
                <div className="pb-3">
                  <FloatingInputBox
                    id="name"
                    name="name"
                    placeholder="Your name"
                    type="text"
                    className="secondary-input-container"
                    value={inputVal.name}
                    onChangeHandler={onChangeHandler}
                  />
                </div>
                <div className="pb-3">
                  <FloatingInputBox
                    id="emailAddress"
                    name="emailAddress"
                    placeholder="Your email"
                    type="text"
                    className="secondary-input-container"
                    value={inputVal.emailAddress}
                    onChangeHandler={onChangeHandler}
                  />
                </div>
                <div className="message-input-container">
                  <FloatingInputBox
                    id="message"
                    name="message"
                    placeholder="Your message"
                    type="text"
                    className="secondary-input-container messasge-input"
                    value={inputVal.message}
                    onChangeHandler={onChangeHandler}
                    isMultiple={true}
                    componentType="textarea"
                    onFocusHandler={onInputFocusHandler}
                    onPointerOutHandler={onPointerOutHandler}
                  />
                </div>

                <div className="lbl-resend-email msg-top-space">
                  {success &&
                    `We have received your message, we'll contact you back as soon as we can. Thank you!`}
                </div>

                {error ? (
                  <div className="error-msg msg-top-space">
                    <p>{error}</p>
                  </div>
                ) : (
                  <></>
                )}

                <div className="">
                  <button
                    type="submit"
                    id={!isTyping ? "supportSubmitBtn" : ""}
                    className="btn-support-submit"
                    onClick={() => onSupportFormSubmit()}
                  >
                    Submit
                  </button>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </div>
  );
};

export default compose(withRouter, withFirebase)(Support);
