import React, { useEffect, useState } from "react";
import { Modal, Form, Button } from "react-bootstrap";
import { useDispatch } from "react-redux";
import Home from "../Home";
import { getQrApplication } from "../../Action/applications";
import { fbSetScreenName, fbSetEvent, fbSetParams } from "../../Action/firebaseAnalytics";
import {
  OPEN_SCREEN,
  QR_APPLY_SCREEN,
  USER_ERROR,
} from "../../constants/firebaseAnalytics";
import LoadingIndicator from "../../components/LoadingIndicator2";
import { useParams, useHistory } from "react-router-dom";
import "./index.css";

const QrApply = () => {
  const { id } = useParams();

  const [error, setError] = useState("");
  const [errorTitle, setErrorTitle] = useState("");
  const [showQrApply, setShowQrApply] = useState(false);
  const [modalCloseStatus, setModalCloseStatus] = useState("close-left");
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const [qrData, setQrData] = useState(null);
  const [selectedRoles, setSelectedRoles] = useState([]);

  const handleQrApplyModal = (status) => {
    setShowQrApply(status);
  };

  const handleModalCloseState = (status) => {
    setModalCloseStatus(status);
  };

  const handleCloseModal = () => {
    handleModalCloseState("close-right");
    handleQrApplyModal(false);
    history.replace("/");
  };

  const handleBackDrops = () => {
    handleModalCloseState("close-drop");
    handleQrApplyModal(false);
    history.replace("/");
  };

  useEffect(() => {
    setShowQrApply(true);
  }, []);

  const handleFBEvents = (eventName, params) => {
    dispatch(fbSetParams(params));
    dispatch(fbSetEvent(eventName));
  };

  const handlefbSetScreen = () => {
    dispatch(fbSetScreenName(QR_APPLY_SCREEN));
    handleFBEvents(OPEN_SCREEN, {});
  };

  const handleEmail = () => {
    if (!selectedRoles.length && qrData.roles.length > 1) {
      setError("Please select at least one role before proceeding.");
      return;
    }

    let emailSubject;
    let emailBody;

    if (!selectedRoles.length || selectedRoles.length === 1) {
      // Single role logic
      const roleString = selectedRoles.length ? selectedRoles[0] : qrData.roles[0];
      emailSubject = `Interest in ${roleString} Position at ${qrData.companyName}`;
      emailBody = `Dear Hiring Manager,

I am writing to express my interest in the ${roleString} position at ${qrData.companyName} in ${qrData.city}. I am confident in my ability to contribute effectively to your team.

[Here you can include a brief introduction about yourself, your relevant experiences, and why you are interested in this role.]

Thank you for considering my application. I am very interested in the opportunity to discuss my candidacy further.

Best regards,
[Your Name]`;
    } else {
      // Multiple roles logic
      const rolesString = selectedRoles.join(', ');
      emailSubject = `Interest in Positions at ${qrData.companyName}`;
      emailBody = `Dear Hiring Manager,

I am writing to express my interest in joining the team at ${qrData.companyName} in ${qrData.city}. I am particularly interested in the following roles: ${rolesString}. I am confident in my ability to contribute effectively to your team in any of these capacities.

[Here you can include a brief introduction about yourself, your relevant experiences, and why you are interested in these roles.]

Thank you for considering my application. I am very interested in the opportunity to discuss my candidacy further for any of the roles that match my skills and experience.

Best regards,
[Your Name]`;
    }

    const mailToLink = `mailto:${qrData.emailAddress}?subject=${encodeURIComponent(emailSubject)}&body=${encodeURIComponent(emailBody)}`;
    window.location.href = mailToLink;
  };

  const handleRoleCheckboxChange = (role) => {
    setSelectedRoles(prevRoles => {
      if (prevRoles.includes(role)) {
        return prevRoles.filter(r => r !== role);
      } else {
        return [...prevRoles, role];
      }
    });
  };

  useEffect(() => {
    if (error) {
      error.split("\n").map((item, index) => {
        setTimeout(() => {
          handleFBEvents(USER_ERROR, {
            message: item,
          });
        }, 200);
        return false;
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  useEffect(() => {
    if (showQrApply) {
      handlefbSetScreen();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showQrApply]);

  useEffect(() => {
    if (id) {
      setLoading(true);

      getQrApplication(id)
        .then(response => {
          setQrData(response.data);
          setLoading(false);
        })
        .catch(error => {
          if (error?.response?.status === 403) {
            setError("The QR code you've scanned hasn't been set up yet. Please verify the code and try again. If you believe this is an error, please contact the job poster or our support team for further instructions.");
          } else {
            setError("At this moment we're unable to access the job connected to this QR code. Please ensure you're connected to the internet and try scanning again. If the issue persists, please reach out to our support team for assistance.");
          }
          setLoading(false);
        });
    }
    // Only execute this effect when the 'id' changes
  }, [id]);

  return (
    <>
      {loading ? <LoadingIndicator /> :

        <Modal
          centered
          show={showQrApply}
          onHide={() => handleBackDrops()}
          className={`auth-modal login-modal ${showQrApply ? "right" : modalCloseStatus}`}
          id="app-modal"
        >
          <Modal.Header style={{ display: 'flex', justifyContent: 'flex-end' }}>
            {!loading && !error && <span
              className="model-cancel-job"
              onClick={() => {
                handleCloseModal();
                handleModalCloseState("close-right");
              }}
            >
              <span className="cancel-job">Cancel</span>
              <img src="/icon/cancel.svg" className="back-arrow cancel-icon" alt="cancel" />
            </span>}
          </Modal.Header>
          {loading && <LoadingIndicator loaderStyle="auth-loader" />}
          <Modal.Body className="auth-modal-body reset-pass-body">
            {(error || !qrData) ?
              (error.startsWith("The QR code you've scanned hasn't been set up yet.") ?
                <><div className="deftle-modal-title">QR Code not registered yet</div>
                  <div className="error-message pt-3">{error}</div>
                  <button
                    className="deftle-modal-button mt-3 deftle-modal-button-enabled"
                    onClick={handleCloseModal}
                  >
                    close
                  </button></>
                : <><div className="deftle-modal-title deftle-error-color">Oops, something went wrong!</div>
                  <div className="error-message pt-3">{error}</div>
                  <button
                    className="deftle-modal-button mt-3 deftle-modal-button-enabled"
                    onClick={handleCloseModal}
                  >
                    close
                  </button></>
              ) :
              (
                <div>
                  <div className="deftle-modal-title">Join the team at {qrData.companyName}, {qrData.city}!</div>
                  <div>
                    {qrData.roles.length === 1 ? (
                      <p className='defle-modal-text'>At {qrData.companyName}, we are looking for dynamic individuals to join us as {qrData.roles[0]}.<br /><br />Click 'Continue to Email' to introduce yourself directly to the hiring manager.<br /><br />We are excited about your interest in the opportunity!</p>
                    ) : (
                      <>
                        <p className='defle-modal-text'>At {qrData.companyName}, we are looking for dynamic individuals for roles such as:</p>
                        <div className="text-left pl-5">
                          {qrData.roles.map(role => (
                            <Form.Check
                              custom
                              className="deftle-modal-checkbox"
                              type="checkbox"
                              id={`custom-checkbox-${role}`}
                              label={role}
                              key={role}
                              checked={selectedRoles.includes(role)}
                              onChange={() => handleRoleCheckboxChange(role)}
                            />
                          ))}
                        </div>
                        <p className='defle-modal-text'>Select at least one role above and click 'Continue to Email' to introduce yourself directly to the hiring manager.</p><p className='defle-modal-text'>We are excited about your interest in the opportunity!</p>
                      </>
                    )}
                    <button
                      className={`deftle-modal-button mt-3 ${(selectedRoles.length > 0 || qrData.roles.length === 1) ? 'deftle-modal-button-enabled' : 'deftle-modal-button-disabled'}`}
                      onClick={handleEmail}
                      disabled={selectedRoles.length === 0 && qrData.roles.length > 1}
                    >
                      Continue to Email
                    </button>
                  </div>
                </div>
              )}
          </Modal.Body>
        </Modal>
      }
    </>
  );
};

export default QrApply;
