import React, { useEffect } from "react";
import { Modal } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import { compose } from "recompose";
import { useDispatch } from "react-redux";
import { withFirebase } from "../../components/Firebase";
import InterceptBrowserButton from "../../components/InterceptBrowserButton";
import {
  OPEN_SCREEN,
  APPLY_JOB_DESCRIPTION_SCREEN,
} from "../../constants/firebaseAnalytics";
import {
  fbSetScreenName,
  fbSetEvent,
  fbSetParams,
} from "../../Action/firebaseAnalytics";
import {
  handleGetPrivacyPolicy,
  handleGetTermsCondition,
} from "../../constants/termsPrivacy";
import "./index.css";
import "./privacyPolicyModal.css";

const PrivacyPolicyModal = ({
  isPrivacyView,
  handlePrivacyViewer,
  isPrivacyModal,
  descriptions,
}) => {
  const dispatch = useDispatch();

  const handleFBEvents = (eventName, params) => {
    dispatch(fbSetParams(params));
    dispatch(fbSetEvent(eventName));
  };

  const handlefbSetScreen = () => {
    dispatch(fbSetScreenName(APPLY_JOB_DESCRIPTION_SCREEN));
    handleFBEvents(OPEN_SCREEN, {});
  };

  useEffect(() => {
    if (isPrivacyView) {
      handlefbSetScreen();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPrivacyView]);

  return (
    <>
      {isPrivacyView && (
        <InterceptBrowserButton
          isHandleBack={true}
          handleBack={handlePrivacyViewer}
        />
      )}
      <Modal
        centered
        show={isPrivacyView}
        onHide={() => handlePrivacyViewer(false)}
        className={`modal-90w auth-modal public-profile-modal-class ${
          isPrivacyView ? "right" : "close-right"
        }`}
      >
        <Modal.Header>
          <div>
            <div
              data-dismiss="modal"
              onClick={() => handlePrivacyViewer(false)}
              className="auth-modal-back"
            >
              <img
                src="/icon/back_arrow.svg"
                className="back-arrow"
                alt="back"
              />
              <span className="label-back"> Back</span>
            </div>
          </div>
        </Modal.Header>
        <Modal.Body className="job-modal-body info-detail-body doc-view-modal">
          <>
            <div className="desc-up-label privacy-modal-title">
              <span>
                {isPrivacyModal ? "Our privacy policy" : "Our terms of service"}
              </span>
            </div>
            <div className="privacy-desc-view-area">
              <div
                dangerouslySetInnerHTML={
                  isPrivacyModal
                    ? handleGetPrivacyPolicy()
                    : handleGetTermsCondition()
                }
              ></div>
            </div>
            <div className="login-btn skip-checkbox">
              <button
                type="button"
                className={`btn-job-select btn-joe-con btn-job-enable`}
                onClick={() => handlePrivacyViewer(false)}
              >
                close
              </button>
            </div>
          </>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default compose(withRouter, withFirebase)(PrivacyPolicyModal);
