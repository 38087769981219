import axios from "axios";
import { AUTH_TOKEN, API_URL } from "../constants/keys";

const axiosApi = axios.create({
  baseURL: API_URL,
});

export const setAuthHeader = async (token = false) => {
  const authToken = localStorage.getItem(AUTH_TOKEN) || token;
  axiosApi.defaults.headers.Authorization = "Bearer " + authToken;
  axiosApi.defaults.headers.forceerror = "false";
};

export const setDefaultAuth = async (timeout, forceerror) => {
  console.log({ timeout }, { forceerror });
  axiosApi.defaults.headers.timeout = timeout;
  axiosApi.defaults.headers.forceerror = `${forceerror}`;
};

function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

axiosApi.interceptors.request.use(async (config) => {
  sleep(axiosApi.defaults.headers.timeout || 0); //TODO remove, just to temporarly test delays
  return config;
});

// if a 401 happens, when token is expired
axiosApi.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (error?.response?.status === 401) {
      console.log({ "token is expired": error });
    }
    return Promise.reject(error);
  },
);

setAuthHeader();

export default axiosApi;
