import React from "react";
import "./index.css";
const Loader = require("react-loader");

const LoadingIndicator = ({ loaderStyle }) => {
  return (
    <div className={`${loaderStyle || "loaderparent"}`}>
      <Loader loaded={false} />
    </div>
  );
};

export default LoadingIndicator;
