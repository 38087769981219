import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import { compose } from "recompose";
import Validator from "validatorjs";
import { useDispatch } from "react-redux";
import * as ROUTES from "../../constants/routes";
import { withFirebase } from "../../components/Firebase";
import { fetchUserInformation } from "../../Action/authentication";
import Home from "../Home";
import { fbSetScreenName, fbSetEvent, fbSetParams } from "../../Action/firebaseAnalytics";
import { signupValidationMessage, changePassword, errorMessage, errorCode } from "../../util/Validatios";
import {
  OPEN_SCREEN,
  APP_BUTTON,
  RESET_PASSWORD_SCREEN,
  SAVE_PASSWORD,
  API_ERROR,
  GET_PROFILE,
  FIREBASEAUTH_ERROR,
  USER_ERROR,
} from "../../constants/firebaseAnalytics";
import LoadingIndicator from "../../components/LoadingIndicator";
import FloatingInputBox from "../../components/FloatingInputBox";
import useEnterKeyListener from "../../helpers/useEnterKeyListener";
import { setAuthHeader } from "../../ApiConfig";
import { RESET_PASSWORD, APP_NAME } from "../../constants/keys";
// import "./index.css";

const ResetPassword = ({ firebase, oobCode, history }) => {
  const [error, setError] = useState("");
  const [showResetPass, setShowResetPass] = useState(false);
  const [inputVal, setInputVal] = useState({
    password: "",
    retypePassword: "",
  });
  const [modalCloseStatus, setModalCloseStatus] = useState("close-left");
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const validate = () => {
    let validation = new Validator(
      {
        password: inputVal.password,
        password_confirmation: inputVal.retypePassword,
      },
      changePassword,
      signupValidationMessage,
    );

    if (validation.fails()) {
      let errorMessages = "";

      if (validation.errors.get("password").length) {
        errorMessages = validation.errors.first("password");
      } else if (validation.errors.get("password_confirmation").length) {
        errorMessages = validation.errors.get("password_confirmation");
      }

      setError(errorMessages);
      return false;
    }
    return true;
  };

  const onChangeHandler = (evt) => {
    setError("");
    const value = evt.target.value;
    setInputVal({
      ...inputVal,
      [evt.target.name]: value,
    });
  };

  const loaderHandler = (status) => {
    setLoading(status);
  };

  const resetPasswordSubmit = () => {
    handleFBEvents(APP_BUTTON, {
      cta: SAVE_PASSWORD,
    });

    if (!validate()) {
      return;
    }

    loaderHandler(true);
    const { password } = inputVal;

    firebase
      .resetPassword(oobCode, password)
      .then((result) => {
        if (result) {
          firebase.auth.onAuthStateChanged((authUser) => {
            authUser.getIdToken().then(async (idToken) => {
              await setAuthHeader();
              fetchUserInformation()
                .then(async (userRes) => {
                  loaderHandler(false);
                  setShowResetPass(false);
                  if (!result.user.emailVerified) {
                    history.push(ROUTES.EMAIL_CONFIRMATION, {
                      messageTitle: "Your email address is not verified yet",
                    });
                  } else {
                    handleFBEvents(API_ERROR, {
                      status: userRes.status,
                      apiCall: GET_PROFILE,
                    });
                    setTimeout(() => {
                      history.push(ROUTES.HOME, { isStopAnimation: true });
                    }, 200);
                  }
                })
                .catch((error) => {
                  handleFBEvents(API_ERROR, {
                    status: error.response.status,
                    apiCall: GET_PROFILE,
                  });

                  setError(errorMessage.others);
                  loaderHandler(false);
                });
            });
          });
        }
      })
      .catch((error) => {
        loaderHandler(false);
        handleFBEvents(FIREBASEAUTH_ERROR, {
          method: RESET_PASSWORD,
          message: error.message,
        });

        if (error.code === errorCode.actionCodeExpire) {
          setError(errorMessage.actionCodeExpire);
        } else {
          setError(errorMessage.actionCodeExpire);
        }
      });
  };

  const handleResetModal = (status) => {
    setShowResetPass(status);
  };

  const handleModalCloseState = (status) => {
    setModalCloseStatus(status);
  };

  const handleCloseResetModal = () => {
    handleModalCloseState("close-right");
    handleResetModal(false);
  };

  const handleBackDrops = () => {
    handleModalCloseState("close-drop");
    handleResetModal(false);
  };

  useEffect(() => {
    setShowResetPass(true);
  }, []);

  const handleFBEvents = (eventName, params) => {
    dispatch(fbSetParams(params));
    dispatch(fbSetEvent(eventName));
  };

  const handlefbSetScreen = () => {
    dispatch(fbSetScreenName(RESET_PASSWORD_SCREEN));
    handleFBEvents(OPEN_SCREEN, {});
  };

  useEffect(() => {
    if (error) {
      error.split("\n").map((item, index) => {
        setTimeout(() => {
          handleFBEvents(USER_ERROR, {
            message: item,
          });
        }, 200);
        return false;
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  useEffect(() => {
    if (showResetPass) {
      handlefbSetScreen();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showResetPass]);

  useEnterKeyListener({
    querySelectorToExecuteClick: "#resetPasswordBtn",
  });

  return (
    <>
      <Home />
      <Modal
        centered
        show={showResetPass}
        onHide={() => handleBackDrops()}
        className={`auth-modal login-modal ${showResetPass ? "right" : modalCloseStatus}`}
        id="app-modal"
      >
        <Modal.Header>
          <div className="reset-password-back">
            <span onClick={() => handleCloseResetModal()}>
              <img src="/icon/back_arrow.svg" className="back-arrow back-icon" alt="back" />
              <span className="label-back">Back</span>
            </span>
          </div>
        </Modal.Header>
        <Modal.Body className="auth-modal-body reset-pass-body">
          {loading && <LoadingIndicator loaderStyle="homePageLoader" />}
          <div>
            <div className="credentials-details">Please enter your new password</div>

            <div>
              <div className="form-floating">
                <FloatingInputBox
                  id="password"
                  name="password"
                  placeholder={`${APP_NAME}’s Password`}
                  type="password"
                  className="form-floating-input auth-floating-input"
                  value={inputVal.password || ""}
                  onChangeHandler={onChangeHandler}
                />
              </div>
            </div>

            <div>
              <div className="form-floating">
                <FloatingInputBox
                  id="retypePassword"
                  name="retypePassword"
                  placeholder="Re-enter password"
                  type="password"
                  className="form-floating-input auth-floating-input"
                  value={inputVal.retypePassword || ""}
                  onChangeHandler={onChangeHandler}
                />
              </div>
            </div>
            <div className="password-detalis">
              <p>Passwords: min 8 char, 1 lowercase, 1 uppercase, 1 number, 1 special char</p>
            </div>
            {error ? (
              <div className="error-msg">
                <p>{error}</p>
              </div>
            ) : (
              <></>
            )}
            <div className="login-btn">
              <button
                type="button"
                id="resetPasswordBtn"
                className="btn-pwa-signUp btn-reset-pass"
                onClick={() => resetPasswordSubmit()}
              >
                save password
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default compose(withRouter, withFirebase)(ResetPassword);
