import { APP_NAME } from "./keys";

export const OPEN_SCREEN = APP_NAME.toLowerCase() + "_screen_open";
export const CLOSE_SCREEN = APP_NAME.toLowerCase() + "_screen_close";
export const HOME_SCREEN = APP_NAME.toLowerCase() + "-home";
export const LOGIN_CHOICE = APP_NAME.toLowerCase() + "-login-choice";
export const REGISTRATION_CHOICE = APP_NAME.toLowerCase() + "-registration-choice";
export const LOGIN_EMAIL_CHOICE = APP_NAME.toLowerCase() + "-login-email";
export const REGISTRATION_EMAIL_CHOICE = APP_NAME.toLowerCase() + "-registration-email";
export const FORGOT_PASSWORD_SCREEN = APP_NAME.toLowerCase() + "-forgot-password";
export const RESET_PASSWORD_SCREEN = APP_NAME.toLowerCase() + "-reset-password";
export const EMAIL_VERIFY_SCREEN = APP_NAME.toLowerCase() + "-verify-email";
export const ACCOUNT_VERIFIED_SCREEN = APP_NAME.toLowerCase() + "-account-verified";
export const VIEW_SETTINGS_SCREEN = APP_NAME.toLowerCase() + "-view-settings";
export const EDIT_SETTINGS_SCREEN = APP_NAME.toLowerCase() + "-edit-settings";
export const SECURITY_CHECK_SCREEN = APP_NAME.toLowerCase() + "-security-check";
export const CHANGE_PASSWORD_SCREEN = APP_NAME.toLowerCase() + "-change-password";
export const VIEW_RESUMES_SCREEN = APP_NAME.toLowerCase() + "-view-resumes";
export const VIEW_COVERS_SCREEN = APP_NAME.toLowerCase() + "-view-covers";
export const APPLY_AUTHORIZED_SCREEN = APP_NAME.toLowerCase() + "-apply-authorized";
export const APPLY_ENTER_PASSWORD_SCREEN = APP_NAME.toLowerCase() + "-apply-enter-password";
export const APPLY_FEDERATED_LOGIN_SCREEN = APP_NAME.toLowerCase() + "-apply-federated-login";
export const APPLY_NEW_USER_SCREEN = APP_NAME.toLowerCase() + "-apply-new-user";
export const APPLY_OOPS_SCREEN = APP_NAME.toLowerCase() + "-apply-oops";
export const APPLY_JOB_DESCRIPTION_SCREEN = APP_NAME.toLowerCase() + "-apply-job-description";
export const APPLY_CHOICE_SCREEN = APP_NAME.toLowerCase() + "-apply-choice";
export const APPLY_COVER_SCREEN = APP_NAME.toLowerCase() + "-apply-cover";
export const APPLY_UPLOAD_COVER_SCREEN = APP_NAME.toLowerCase() + "-apply-upload-cover";
export const APPLY_RESUME_SCREEN = APP_NAME.toLowerCase() + "-apply-resume";
export const APPLY_UPLOAD_RESUME_SCREEN = APP_NAME.toLowerCase() + "-apply-upload-resume";
export const APPLY_VIEW_COVER_SCREEN = APP_NAME.toLowerCase() + "-view-cover";
export const APPLY_VIEW_RESUME_SCREEN = APP_NAME.toLowerCase() + "-view-resume";
export const APPLY_EXPERIENCE_SCREEN = APP_NAME.toLowerCase() + "-apply-experience";
export const APPLY_SUBMIT_SCREEN = APP_NAME.toLowerCase() + "-apply-submit";
export const APPLY_SUCCESS = APP_NAME.toLowerCase() + "-apply-success";
export const EDIT_EXPERIENCE = APP_NAME.toLowerCase() + "-edit-experience";
export const CAPTCHA_FAILURE = APP_NAME.toLowerCase() + "-captcha-failure";
export const SEARCH_JOB_SCREEN = APP_NAME.toLowerCase() + "-search-job";
export const SEARCH_JOB_RESULT_SCREEN = APP_NAME.toLowerCase() + "-search-job-result";
export const JOB_DETAIL_SCREEN = APP_NAME.toLowerCase() + "-job-detail";
export const UPLOAD_RESUME_SCREEN = APP_NAME.toLowerCase() + "-upload-resume";
export const UPLOAD_NEW_RESUME_SCREEN = APP_NAME.toLowerCase() + "-upload-new-resume";
export const LAST_UPLOAD_RESUME_SCREEN = APP_NAME.toLowerCase() + "-last-upload-resume";
export const NO_PREVIOUS_UPLOAD_RESUME_SCREEN = APP_NAME.toLowerCase() + "-no-previous-upload-resume";
export const QR_APPLY_SCREEN = APP_NAME.toLowerCase() + "-qr-apply";

//------- Events -------//

export const APP_BUTTON = APP_NAME.toLowerCase() + "_button";
export const LOGIN_WITH_EMAIL = "login_with_email";
export const SIGN_UP = "sign_up";
export const LOGIN_WITH_GOOGLE = "login_with_google";
export const LOGIN_WITH_APPLE = "login_with_apple";
export const LOGIN_WITH_FACEBOOK = "login_with_facebook";
export const EMAIL_LOGIN = "email_login";
export const SESSION_EXPIRE = "expire";
export const SESSION_PERSIST = "persist";
export const EMAIL_REGISTER = "register";
export const RESET_PASSWORD = "reset_password";
export const SAVE_PASSWORD = "save_new_password";
export const SAVE_SETTINGS = "save_settings";
export const VERIFY_PASSWORD = "verify_password";
export const CHANGE_PASSWORD = "change_password";
export const AUTHORIZED_CONTINUE = "authorized_continue";
export const PASSWORD_CONTINUE = "password_continue";
export const GOOGLE_CONTINUE = "google_continue";
export const FACEBOOK_CONTINUE = "facebook_continue";
export const APPLE_CONTINUE = "apple_continue";
export const APPLY_LOGIN = "apply_login";
export const APPLY_SIGNUP = "apply_signup";
export const APPLY_NOT_FOUND = APP_NAME.toLowerCase() + "-apply-not-found";
export const APPLY_OOPS = APP_NAME.toLowerCase() + "-apply-oops";
export const APPLY_CHOICE_CONTINUE = "apply-choice-continue";
export const APPLY_COVER_CONTINUE = "apply-cover-continue";
export const APPLY_UPLOAD_COVER_CONTINUE = "apply-upload-cover-continue";
export const APPLY_RESUME_CONTINUE = "apply-resume-continue";
export const APPLY_UPLOAD_RESUME_CONTINUE = "apply-upload-resume-continue";
export const APPLY_EXPERIENCE_CONTINUE = "apply-experience-continue";
export const SUBMIT_APPLICATION = "submit_application";
export const DELETE_EXPERIENCE = "delete_experience";
export const SAVE_EXPERIENCE = "save_experience";
export const SESSION_WARNING = APP_NAME.toLowerCase() + "_session_warning";
export const EXTEND_SESSION = APP_NAME.toLowerCase() + "_extend_session";
export const SESSION_EXPIRED = "deflte_session_expired";
export const IDLE_TIMEOUT = "idle_timeout";
export const SEARCH_JOB = "search-job";
export const EXPAND_JOB = "expand-job";
export const APPLY_JOB = "apply-job";
export const UPLOAD_CLOSE = "upload-close";
export const UPLOAD_RESUME = "upload-resume";

export const WEBAPP_ERROR = APP_NAME.toLowerCase() + "_webapp_error";
export const USER_ERROR = APP_NAME.toLowerCase() + "_user_error";
export const FIREBASEAUTH_ERROR = APP_NAME.toLowerCase() + "_firebaseauth_error";

//-------- API calls event-------- //

export const API_ERROR = APP_NAME.toLowerCase() + "_api_error";
export const FILE_UPLOAD = "file_upload";
export const GET_FILE_URL = "get_file_url";
export const FILE_DOWNLOAD = "file_download";
export const SUBMIT_JOB = "submit_job";
export const GET_RESUMES_LIST = "get_resumes_list";
export const GET_COVERS_LIST = "get_covers_list";
export const GET_VIDEOS_LIST = "get_videos_list";
export const GET_PROFILE = "get_profile";
export const SET_PROFILE = "set_profile";
export const DELETE_RESUME = "delete_resume";
export const DELETE_COVER = "delete_cover";
export const RENAME_COVER = "rename_cover";
export const RENAME_RESUME = "rename_resume";
export const GET_OPENING = "get_opening";
export const GET_APPLICATION = "get_application";
export const SAVE_RESUME = "save_resume";
export const GET_RESUME_URLS = "get_resume_urls";
export const GET_LATEST_RESUME = "get_latest_resume";
