import { SetValue } from "../util/DataRepositry";
import { ApiGet, ApiPut } from "../util/Networking";
import { EMAIL, FIRST_NAME, LAST_NAME, AUTH_TOKEN } from "../constants/keys";

export const updateUserInformation = async (
  firstName,
  lastName,
  emailAddress
) => {
  SetValue(FIRST_NAME, firstName);
  SetValue(LAST_NAME, lastName);
  SetValue(EMAIL, emailAddress);
  return new Promise((resolve, reject) => {
    ApiPut(`/profile`, {
      firstName: firstName,
      lastName: lastName,
      emailAddress: emailAddress,
    })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        console.log("action error", error);
        reject(error);
      });
  });
};

export const updateUserProfileInfo = async (userProfileDetail) => {
  return new Promise((resolve, reject) => {
    ApiPut(`/profile`, userProfileDetail)
      .then(function (response) {
        SetValue(FIRST_NAME, userProfileDetail.firstName);
        SetValue(LAST_NAME, userProfileDetail.lastName);
        SetValue(EMAIL, userProfileDetail.emailAddress);
        resolve(response);
      })
      .catch(function (error) {
        console.log("action error", error);
        reject(error);
      });
  });
};

export const fetchUserInformation = () => {
  return new Promise((resolve, reject) => {
    ApiGet(`/profile`)
      .then(function (response) {
        SetValue(FIRST_NAME, response.data.firstName);
        SetValue(LAST_NAME, response.data.lastName);
        SetValue(EMAIL, response.data.emailAddress);
        resolve(response);
      })
      .catch(function (error) {
        reject(error);
      });
  });
};

export const resetAuthToken = (authUser, idToken) => {
  SetValue(AUTH_TOKEN, idToken);
};

export const resetLocalAuthToken = (idToken) => {
  SetValue(AUTH_TOKEN, idToken);
};
