import React from "react";
import { Container, Col, Row } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import { compose } from "recompose";
import { withFirebase } from "../../components/Firebase";
import { handleGetTermsCondition } from "../../constants/termsPrivacy";
import "./index.css";

const TermsCondition = () => {
  return (
    <div className="privacy-main-container privacy-mobile-cotainer terms-main-container">
      <div className="privacy-second-section">
        <Container>
          <div className="meeting-section second-sec-desc"></div>
        </Container>
      </div>

      <div className="thard-section privacy-container privacy-second-main-container terms-sec-main-container">
        <Container>
          <div className="privacy-description">
            <Row className="privacy-title-row">
              <Col lg={6}>
                <div className="privacy-title">Terms of Service</div>
              </Col>
            </Row>
            <Row>
              <Col dangerouslySetInnerHTML={handleGetTermsCondition()}></Col>
            </Row>
          </div>
        </Container>
      </div>
    </div>
  );
};

export default compose(withRouter, withFirebase)(TermsCondition);
