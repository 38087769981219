export const STAGED_JOB = "/stagedJob";
export const STAGED_JOBS = "/stagedJobs";
export const PUBLISHED_JOB = "/publishedJob";
export const PUBLISHED_JOBS = "/publishedJobs";
export const PROFILE = "/profile";
export const SUPPORT = "/support";
export const JOBS = "/jobs";
export const CLONE = "/clone";
export const CLOSED = "/closed";
export const APPLICATIONS = "/applications";
export const CANDIDATES = "/candidates";
export const STATUS = "/status";
export const USER = "/user";
export const FILES = "/files";
export const APPROVAl_REQUEST = "/approvalRequest";
export const RESUME_URLS = "/resumeUrls";
export const LATEST_RESUME = "/resume/latest";
export const RESUME = "/resume";
export const OPENING = "/opening";
export const APPLICATION = "/application";
export const APPLY = "/apply";
export const RESUMES = "/resumes";
export const COVERLETTERS = "/coverLetters";
export const VIDEOS = "/videos";
