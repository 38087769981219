import {
  APPLY_JOB_STEP,
  APPLY_JOB_URL,
  SERCH_JOBS_SUCCESS,
  SET_CURRENT_PAGE,
  SET_PREVIOUS_PAGE,
  SELECTED_JOBS_SUCCESS,
  SET_SEARCH_INPUT_DETAIL,
  JOB_LOCATION_DETAILS,
  JOB_DETAILS,
  JOB_QUALIFICATION,
  STAGED_JOB_ID,
  CLEAR_JOB_DETAILS,
  STAGED_JOB_DETAILS,
  APPLY_SEARCHED_JOB,
  JOB_ID,
  IS_UPLOAD_RESUME,
  UPLOAD_APPLICATION_DETAIL,
  IS_UPLOAD_DOC,
  IS_VIEW_UPLOAD_DOC,
  IS_REMOVE_UPLOADED_DOC,
  JOB_STEPS_DETAILS,
  TOTAL_JOB_STEPS_COUNT,
  SET_DOCUMENT_STEP,
  JOB_STEP_RESUME,
  SEARCH_RESULTS,
  SEARCH_EXPAND_RESULT,
  SUCCESS_UPLOAD_RESUME
} from "../constants/jobs.constants";

const INITIAL_STATE = {
  applyJobStep: 0,
  applyJobURL: "",
  searchJobDetails: [],
  selectedJobDetails: {},
  currentPage: 1,
  previousPage: 1,
  searchInputDetail: {},
  jobLocation: {},
  jobDetails: {},
  jobQualification: [],
  stagedJobId: "",
  stagedJobDetail: {},
  searchedJobId: "",
  isAppliedSearchJob: false,
  showResumeUpload: false,
  updApplicationDetail: {},
  isUploadDoc: false,
  isViewUploadedDoc: false,
  isRemoveUploadedDoc: false,
  jobStepsDetails : [],
  totalJobStepCount : 0,
  docSteps : "",
  resumeStep: '',
  searchResult: false,
  searchExpand: false,
  uploadResume: false,
};

const jobReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case APPLY_JOB_STEP:
      return {
        ...state,
        applyJobStep: action.payload,
      };
      case SEARCH_RESULTS:
      return {
        ...state,
        searchResult: action.payload,
      };
      case SEARCH_EXPAND_RESULT:
        return {
          ...state,
          searchExpand: action.payload,
        };
    case APPLY_JOB_URL:
      return {
        ...state,
        applyJobURL: action.payload,
      };
    case SERCH_JOBS_SUCCESS:
      return {
        ...state,
        searchJobDetails: action.payload,
      };
    case SET_CURRENT_PAGE:
      return {
        ...state,
        currentPage: action.payload,
      };
    case SET_PREVIOUS_PAGE:
      return {
        ...state,
        previousPage: action.payload,
      };
    case SELECTED_JOBS_SUCCESS:
      return {
        ...state,
        selectedJobDetails: action.payload,
      };
    case SET_SEARCH_INPUT_DETAIL:
      return {
        ...state,
        searchInputDetail: action.payload,
      };
    case JOB_LOCATION_DETAILS:
      return {
        ...state,
        jobLocation: action.payload,
      };
    case JOB_DETAILS:
      return {
        ...state,
        jobDetails: action.payload,
      };
    case JOB_QUALIFICATION:
      return {
        ...state,
        jobQualification: action.payload,
      };
    case STAGED_JOB_ID:
      return {
        ...state,
        stagedJobId: action.payload,
      };
    case STAGED_JOB_DETAILS:
      return {
        ...state,
        stagedJobDetail: action.payload,
      };
    case APPLY_SEARCHED_JOB:
      return {
        ...state,
        isAppliedSearchJob: action.payload,
      };
    case JOB_ID:
      return {
        ...state,
        searchedJobId: action.payload,
      };
    case CLEAR_JOB_DETAILS:
      return {
        ...state,
        jobLocation: {},
        jobDetails: {},
        jobQualification: [],
        stagedJobId: "",
        stagedJobDetail: {},
      };
    case IS_UPLOAD_RESUME:
      return {
        ...state,
        showResumeUpload: action.payload,
      };
    case UPLOAD_APPLICATION_DETAIL:
      return {
        ...state,
        updApplicationDetail: action.payload,
      };
    case IS_UPLOAD_DOC:
      return {
        ...state,
        isUploadDoc: action.payload,
      };
    case IS_VIEW_UPLOAD_DOC:
      return {
        ...state,
        isViewUploadedDoc: action.payload,
      };
    case IS_REMOVE_UPLOADED_DOC:
      return {
        ...state,
        isRemoveUploadedDoc: action.payload,
      };
    case JOB_STEPS_DETAILS:
      return {
        ...state,
        jobStepsDetails: action.payload,
      };
    case TOTAL_JOB_STEPS_COUNT:
      return {
        ...state,
        totalJobStepCount: action.payload,
      };
    case SET_DOCUMENT_STEP:
      return {
        ...state,
        docSteps: action.payload,
      };
      case JOB_STEP_RESUME:
      return {
        ...state,
        resumeStep: action.payload,
      };
      case SUCCESS_UPLOAD_RESUME:
        return {
          ...state,
          uploadResume: action.payload,
        }
    default:
      return state;
  }
};

export default jobReducer;
