export const searchByFirstCharacter = (list, value) => {
  return list.filter((el) => el?.name?.toLowerCase().startsWith(value.toLowerCase()));
};

export const findUniqueValue = (array, index, self) => {
  return array.filter((v, i, a) => a.indexOf(v) === i);
};
export const searchElementsByFirstCharacter = (list, value) => {
  return list.filter((el) => el?.toLowerCase().startsWith(value.toLowerCase()));
};
