import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

const InterceptBrowserButton = ({ isHandleBack, isHandleForward, handleBack, handleForward }) => {
  const [locationKeys, setLocationKeys] = useState([]);
  const history = useHistory();
  useEffect(() => {
    return history.listen((location) => {
      if (history.action === "PUSH") {
        setLocationKeys([location.key]);
      }

      if (history.action === "POP") {
        if (locationKeys[1] === location.key) {
          setLocationKeys(([_, ...keys]) => keys);

          if (isHandleForward) {
            handleForward();
          }
          // Handle forward event
        } else {
          setLocationKeys((keys) => [location.key, ...keys]);

          if (isHandleBack) {
            handleBack();
          }
          // Handle back event
        }
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locationKeys]);
  return <></>;
};

export default InterceptBrowserButton;
