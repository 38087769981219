import React, { useState, useEffect, useRef, lazy } from "react";
import { Modal, Col, Row } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import { compose } from "recompose";
import Validator from "validatorjs";
import { useSelector, useDispatch } from "react-redux";
import * as ROUTES from "../../constants/routes";
import { withFirebase } from "../../components/Firebase";
import { LOGIN_METHOD_EMAIL, APP_NAME } from "../../constants/keys";
import { SaveSessionExpiry } from "../../util";
import { signupValidation, signupValidationMessage, errorMessage, errorCode } from "../../util/Validatios";
import { setAuthHeader } from "../../ApiConfig";
import { IS_AUTH } from "../../redux/constants/auth.constants";
import { PROFILE_DETAILS } from "../../redux/constants/profile.constants";
import { resetLocalAuthToken } from "../../Action/authentication";
import { fbSetScreenName, fbSetEvent, fbSetParams } from "../../Action/firebaseAnalytics";
import {
  EMAIL_REGISTER,
  REGISTRATION_EMAIL_CHOICE,
  OPEN_SCREEN,
  APP_BUTTON,
  API_ERROR,
  SET_PROFILE,
  FIREBASEAUTH_ERROR,
  USER_ERROR,
  CAPTCHA_FAILURE,
} from "../../constants/firebaseAnalytics";
import { appFirebaseAuthStateRefresh, appFirebaseAuthStateChanged } from "../../components/Firebase/fbAuthentication";
import { IS_UPLOAD_DOC, APPLY_JOB_STEP } from "../../redux/constants/jobs.constants";
import { OK, BAD_REQUEST } from "../../constants/apiStatusCodes";
import {
  INTRODUCTION,
  SKILLS,
  SKILLS_INTRODUCTION,
  RESUME,
  VIDEO,
  COVER_LETTER,
  VIDEO_INTRODUCTION,
  ADD_PERSONAL_INFO,
} from "../../constants/keys";
import { setDocSteps } from "../../redux/actions/jobs.action";
import Loadable from "../../components/Loadable";
import "./index.css";

const PrivacyPolicyModal = Loadable(lazy(() => import("../PrivacyPolicy/privacyPolicyModal")));
const LoadingIndicator = Loadable(lazy(() => import("../../components/LoadingIndicator")));
const FloatingInputBox = Loadable(lazy(() => import("../../components/FloatingInputBox")));
const InterceptBrowserButton = Loadable(lazy(() => import("../../components/InterceptBrowserButton")));

const SignUpModal = ({
  showSignUp,
  handleSignUpModal,
  handleShow,
  updateUserInformation,
  firebase,
  history,
  loaderHandler,
  loading,
  handleModalCloseState,
  modalCloseStatus,
  handleModalOpenState,
  modalOpenStatus,
  submissionId,
  handleSubmissionId,
}) => {
  const [error, setError] = useState("");
  const [inputVal, setInputVal] = useState({});
  const [isAcceptTerms, setIsAcceptTerms] = useState(false);
  const [isPrivacyView, setIsPrivacyView] = useState(false);
  const [isPrivacyModal, setIsPrivacyModal] = useState(false);
  const [ischecked, setIschecked] = useState(false);
  const isMobile = window.innerWidth <= 425;
  const singupCaptchaRef = useRef(null);
  const dispatch = useDispatch();
  const isJobAuth = useSelector((state) => state.auth.isAuth);
  const isUpdResumeAuth = useSelector((state) => state.auth.isUpdResumeAuth);
  const authUploadResume = useSelector((state) => state.auth.authUploadResume);
  const updApplicationDetail = useSelector((state) => state.job.updApplicationDetail);
  const jobStepsDetails = useSelector((state) => state.job.jobStepsDetails);

  const renderNextStep = () => {
    const screenName = jobStepsDetails[0]?.screen;
    dispatch(setDocSteps(screenName));

    switch (screenName) {
      case INTRODUCTION:
        return INTRODUCTION;
      case SKILLS:
      case SKILLS_INTRODUCTION:
      case RESUME:
      case COVER_LETTER:
      case VIDEO_INTRODUCTION:
      case VIDEO:
        return SKILLS;
      default:
        return ADD_PERSONAL_INFO;
    }
  };

  const handleStates = async () => {
    await dispatch({
      type: APPLY_JOB_STEP,
      payload: renderNextStep(),
    });

    await dispatch({
      type: IS_AUTH,
      payload: true,
    });
  };

  const handlePrivacyViewer = (status) => {
    setIsPrivacyView(status);
  };

  const validate = () => {
    let validation = new Validator(
      {
        email: inputVal.email,
        password: inputVal.password,
        password_confirmation: inputVal.retypePassword,
        firstname: inputVal.firstName,
        lastname: inputVal.lastName,
      },
      signupValidation,
      signupValidationMessage,
    );

    if (validation.fails()) {
      let errorMessages = "";

      if (validation.errors.get("email").length) {
        errorMessages = validation.errors.get("email");
      } else if (validation.errors.get("password").length) {
        errorMessages = validation.errors.first("password");
      } else if (validation.errors.get("password_confirmation").length) {
        errorMessages = validation.errors.get("password_confirmation");
      } else if (validation.errors.get("firstname").length) {
        errorMessages = validation.errors.get("firstname");
      } else if (validation.errors.get("lastname").length) {
        errorMessages = validation.errors.get("lastname");
      }

      setError(errorMessages);
      return false;
    }
    return true;
  };

  const onChangeHandler = (evt) => {
    const value = evt.target.value;
    setInputVal({
      ...inputVal,
      [evt.target.name]: value,
    });
  };

  const handleSetUserInfo = async () => {
    await dispatch({
      type: PROFILE_DETAILS,
      payload: {
        email: inputVal.email,
        firstName: inputVal.firstName,
        lastName: inputVal.lastName,
      },
    });
    window.handleAuthSuceess(inputVal);
  };

  const handleRefreshAuthToken = async (refreshToken) => {
    await resetLocalAuthToken(refreshToken);
    await setAuthHeader();
  };

  const handleResetCaptchaContainer = () => {
    if (singupCaptchaRef?.current?.innerHTML) {
      singupCaptchaRef.current.innerHTML = `<div id="signUpRecaptcha" className="recaptcha-container"></div>`;
    }
  };

  const onSignFormSubmit = () => {
    handleFBEvents(APP_BUTTON, {
      cta: EMAIL_REGISTER,
    });
    if (!validate()) {
      return;
    }

    if (!isAcceptTerms) {
      setError("Please accept the terms of service and privacy policy");
      return;
    }

    loaderHandler(true);
    const { email, password } = inputVal;

    firebase
      .recaptchaVerifier("signUpRecaptcha")
      .then(() => {
        firebase
          .doCreateUserWithEmailAndPassword(email, password)
          .then(async (authUser) => {
            SaveSessionExpiry(false, LOGIN_METHOD_EMAIL);
            handleSetUserInfo();

            const refreshToken = await appFirebaseAuthStateChanged(firebase);
            if (refreshToken) {
              await handleRefreshAuthToken();
              updateUserInformation(inputVal.firstName, inputVal.lastName, email)
                .then(async (res) => {
                  if (res.status !== OK) {
                    handleFBEvents(API_ERROR, {
                      status: res.status,
                      apiCall: SET_PROFILE,
                    });
                  }
                  const forcedRefreshToken = await appFirebaseAuthStateRefresh(firebase);
                  if (forcedRefreshToken) {
                    await handleRefreshAuthToken();
                  }
                  handleResetCaptchaContainer();
                })
                .catch((e) => {
                  handleResetCaptchaContainer();
                  handleFBEvents(API_ERROR, {
                    status: e?.response?.status || BAD_REQUEST,
                    apiCall: SET_PROFILE,
                  });
                });
              return firebase.sendVarificationEmail();
            }
          })
          .then(async (authUser) => {
            if (isUpdResumeAuth && authUploadResume && updApplicationDetail?.id && updApplicationDetail?.mediaType) {
              await dispatch({ type: IS_UPLOAD_DOC, payload: true });
            }
            if (isJobAuth) {
              await handleStates();
            } else {
              history.push(ROUTES.EMAIL_CONFIRMATION);
            }

            loaderHandler(false);
            handleSignUpModal(false);
            handleModalCloseState("close-left");
          })
          .catch((error) => {
            handleResetCaptchaContainer();

            handleFBEvents(FIREBASEAUTH_ERROR, {
              method: LOGIN_METHOD_EMAIL,
              message: error?.message || errorMessage.others,
            });
            if (error.code === errorCode.alreadyMailexist) {
              setError(errorMessage.alreadyEmailExist);
            } else {
              setError(errorMessage.others);
            }
            loaderHandler(false);
          });
      })
      .catch((error) => {
        handleResetCaptchaContainer();
        if (!error.code && error.indexOf(CAPTCHA_FAILURE) !== -1) {
          handleFBEvents(CAPTCHA_FAILURE, {});
        }
        loaderHandler(false);
      });
  };

  const handleFBEvents = (eventName, params) => {
    dispatch(fbSetEvent(eventName));
    dispatch(fbSetParams(params));
  };

  const handlefbSetScreen = () => {
    handleFBEvents(OPEN_SCREEN, {});
    dispatch(fbSetScreenName(REGISTRATION_EMAIL_CHOICE));
    handleSubmissionId(REGISTRATION_EMAIL_CHOICE);
  };

  const handleBackPress = () => {
    handleModalOpenState("left");
    handleSignUpModal(false, "close-right");
    handleShow();
  };

  useEffect(() => {
    if (error) {
      handleFBEvents(USER_ERROR, {
        message: error[0],
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  useEffect(() => {
    if (showSignUp) {
      handlefbSetScreen();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showSignUp]);

  return (
    <>
      {showSignUp && <InterceptBrowserButton isHandleBack={true} handleBack={handleBackPress} />}
      <Modal
        centered
        show={showSignUp}
        onHide={() => {
          handleSignUpModal(false);
        }}
        id="app-modal"
        className={`auth-modal create-account-modal ${showSignUp ? "right" : modalCloseStatus}`}
      >
        {!isMobile && (
          <Modal.Header>
            <div>
              <div data-dismiss="modal" onClick={() => handleBackPress()} className="auth-modal-back">
                <img src="/icon/back_arrow.svg" className="back-arrow" alt="back" />
                <span className="label-back"> Back</span>
              </div>
            </div>
          </Modal.Header>
        )}
        <Modal.Body className="auth-modal-body signup-modal-body">
          <div ref={singupCaptchaRef} className="recaptcha-container">
            <div id="signUpRecaptcha" className="recaptcha-container"></div>
          </div>
          {loading && <LoadingIndicator loaderStyle="homePageLoader" />}
          <div>
            <div className="credentials-details signup-modal-title">
              {isMobile && (
                <span onClick={() => handleBackPress()} className="signup-back">
                  <img src="/icon/back_arrow.svg" className="back-arrow" alt="back" />
                </span>
              )}
              <span>Let’s create your account</span>
            </div>
            <div>
              <div className="form-floating">
                <FloatingInputBox
                  id="email"
                  name="email"
                  placeholder="Email"
                  type="email"
                  className="form-floating-input auth-floating-input"
                  value={inputVal.email}
                  onChangeHandler={onChangeHandler}
                />
              </div>
            </div>
            <div>
              <div className="form-floating">
                <FloatingInputBox
                  id="password"
                  name="password"
                  placeholder={`${APP_NAME}'s Password`}
                  type="password"
                  className="form-floating-input auth-floating-input"
                  value={inputVal.password}
                  onChangeHandler={onChangeHandler}
                />
              </div>
            </div>
            <div className="password-detalis">
              <p>Passwords: min 8 char, 1 lowercase, 1 uppercase, 1 number, 1 special char</p>
            </div>
            <div>
              <div className="form-floating">
                <FloatingInputBox
                  id="retypePassword"
                  name="retypePassword"
                  placeholder="Re-enter password"
                  type="password"
                  className="form-floating-input auth-floating-input"
                  value={inputVal.retypePassword}
                  onChangeHandler={onChangeHandler}
                />
              </div>
            </div>
            <div>
              <div className="form-floating">
                <FloatingInputBox
                  id="firstName"
                  name="firstName"
                  placeholder="First Name"
                  type="text"
                  className="form-floating-input auth-floating-input"
                  value={inputVal.firstName}
                  onChangeHandler={onChangeHandler}
                />
              </div>
            </div>
            <div>
              <div className="form-floating">
                <FloatingInputBox
                  id="lastName"
                  name="lastName"
                  placeholder="Last Name"
                  type="text"
                  className="form-floating-input auth-floating-input"
                  value={inputVal.lastName}
                  onChangeHandler={onChangeHandler}
                />
              </div>
            </div>
            <div className="check-box accept-terms-container">
              <label>
                <Row className="term-chkbox-row">
                  <Col xl={1} md={1} sm={1} xs={1} className="terms-checkbox-col">
                    <input
                      type="checkbox"
                      name="checkbox-01"
                      className="blue"
                      checked={ischecked}
                      onChange={(e) => {
                        setIsAcceptTerms(e.target.checked);
                        setError("");
                      }}
                      onClick={() => {
                        setIschecked(!ischecked);
                      }}
                    />
                    <span className="accept-terms-condition"></span>
                  </Col>
                  <Col xl={11} md={11} sm={11} xs={11} className="txt-accept-terms-col">
                    <span className="accept-terms-condition">
                      <span className="accept-terms">I accept {APP_NAME}’s</span>
                      <span
                        className="term-condition"
                        onClick={() => {
                          setIsPrivacyModal(false);
                          handlePrivacyViewer(true);
                          setIschecked(!ischecked);
                        }}
                      >
                        {" "}
                        terms of service{" "}
                      </span>
                      <span> and </span>
                      <span
                        className="term-condition"
                        onClick={() => {
                          setIsPrivacyModal(true);
                          handlePrivacyViewer(true);
                          setIschecked(!ischecked);
                        }}
                      >
                        privacy policy
                      </span>
                    </span>
                  </Col>
                </Row>
              </label>
            </div>

            {error ? (
              <div className="error-msg">
                <p>{error}</p>
              </div>
            ) : (
              <></>
            )}

            <div className="login-btn signup-btn">
              <button
                type="submit"
                className="btn-pwa-signUp"
                id={REGISTRATION_EMAIL_CHOICE}
                onClick={() => onSignFormSubmit()}
              >
                sign up
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <>
        <PrivacyPolicyModal
          isPrivacyView={isPrivacyView}
          handlePrivacyViewer={handlePrivacyViewer}
          isPrivacyModal={isPrivacyModal}
        />
      </>
    </>
  );
};

export default compose(withRouter, withFirebase)(SignUpModal);
