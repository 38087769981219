import {
  PROFILE_DETAILS,
  ACCOUNT_CONFIRMATION,
} from "../constants/profile.constants";

const INITIAL_STATE = {
  profileDetail: {},
  accountConfirm: false,
};

const profileReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case PROFILE_DETAILS:
      return {
        ...state,
        profileDetail: action.payload,
      };
    case ACCOUNT_CONFIRMATION:
      return {
        ...state,
        accountConfirm: action.payload,
      };
    default:
      return state;
  }
};

export default profileReducer;
