import React from "react";
import ReactDOM from 'react-dom';
import "./index.css";
const Loader = require("react-loader");

const LoadingIndicator = ({ color = "white" }) => {
  const defaultLoaderOptions = {
    scale: 3,
    color: 'white',
    zIndex: 10000, // Ensure this is high enough
  };

  return ReactDOM.createPortal(
    <div className="loadingparent">
      <Loader loaded={false} options={defaultLoaderOptions} color={color} />
    </div>,
    document.body // Appends to the body element, covering the full page
  );
};

export default LoadingIndicator;
