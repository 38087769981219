import React, { useState, useEffect, lazy } from "react";
import { Col, Row, Card, Button } from "react-bootstrap";
import { withRouter, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { compose } from "recompose";
import { withFirebase } from "../../components/Firebase";
import * as ROUTES from "../../constants/routes";
import { createMarkup } from "../../helpers/createMarkup";
import { storeSelectedSearchJobDetails, setPreviousPage, setCurrentPage } from "../../redux/actions/jobs.action";
import { fetchOpeningApplication } from "../../Action/applications";
import { appliedSearchJob } from "../../redux/actions/jobs.action";
import { fbSetScreenName, fbSetEvent, fbSetParams } from "../../Action/firebaseAnalytics";
import { OPEN_SCREEN, JOB_DETAIL_SCREEN, APPLY_JOB, APP_BUTTON } from "../../constants/firebaseAnalytics";
import Loadable from "../../components/Loadable";
import "./index.css";

const SearchJobNotFound = Loadable(lazy(() => import("../../pages/SearchJobNotFound")));
const ApplyForJob = Loadable(lazy(() => import("../ApplyForJob")));
const Loader = Loadable(lazy(() => import("../../components/Loader")));

export const SearchJobDescription = ({ history }) => {
  const [jobDesc, setJobDesc] = useState({});
  const [showApplyJob, setShowApplyJob] = useState(false);
  const [isFromSearch, setIsFromSearch] = useState(true);
  const [loading, setLoading] = useState(false);
  const [showSearchAlert, setShowSearchAlert] = useState(false);
  const isMobile = window.innerWidth <= 767;
  const isAppliedSearchJob = useSelector((state) => state.job.isAppliedSearchJob);
  const dispatch = useDispatch();
  const { jobId } = useParams();

  const handleFBEvents = (eventName, params) => {
    dispatch(fbSetEvent(eventName));
    dispatch(fbSetParams(params));
  };

  const handlefbSetScreen = () => {
    handleFBEvents(OPEN_SCREEN, {});
    dispatch(fbSetScreenName(JOB_DETAIL_SCREEN));
  };

  const handleGetJobDetail = () => {
    setLoading(true);
    fetchOpeningApplication(jobId)
      .then((jobResponse) => {
        setJobDesc(jobResponse?.data?.opening);
        setLoading(false);
      })
      .catch((e) => {
        handleShowSearchAlert(true);
        setLoading(false);
      });
  };

  const handleShowSearchAlert = (status) => {
    setShowSearchAlert(status);

    if (!status && isMobile) {
      history.push(ROUTES.HOME);
    }
  };

  const handleBackPress = () => {
    if (isFromSearch) {
      history.push(ROUTES.SEARCH);
    } else {
      const hisParams = {
        previousPage: history?.location?.state?.previousPage,
        currentPage: history?.location?.state?.currentPage,
        hasMore: history?.location?.state?.hasMore,
        searchInputDetails: history?.location?.state?.searchInputDetail,
        isSearch: false,
        searchInputDetail: history?.location?.state?.searchInputDetail,
      };
      history.push(ROUTES.SEARCH_RESULTS, hisParams);
    }
  };

  useEffect(() => {
    if (history?.location?.state?.searchJobDesc) {
      setJobDesc(history?.location?.state?.searchJobDesc);
      dispatch(storeSelectedSearchJobDetails(history?.location?.state?.searchJobDesc));
      dispatch(setPreviousPage(history?.location?.state?.previousPage));
      dispatch(setCurrentPage(history?.location?.state?.currentPage));
    }
  }, [history?.location?.state?.searchJobDesc]);

  const handleApplyJob = (status, jobId) => {
    handleFBEvents(APP_BUTTON, {
      cta: APPLY_JOB,
    });
    setShowApplyJob(status);
    dispatch(appliedSearchJob(false, ""));

    setTimeout(() => {
      dispatch(appliedSearchJob(true, jobId));
    }, 100);
  };

  useEffect(() => {
    if (!history?.location?.state?.searchJobDesc && jobId) {
      setIsFromSearch(true);
      handleGetJobDetail();
    } else {
      setIsFromSearch(false);
    }
  }, [jobId, history?.location?.state?.selectedJob]);

  useEffect(() => {
    handlefbSetScreen();
  }, []);

  return (
    <div>
      <div className="container-fluid edit-filter-block px-0">
        {!loading && !showSearchAlert && (
          <Row className="restaurant-manager">
            <Col className="job-description">
              <div onClick={() => handleBackPress()} className="d-flex pb-2 pt-4">
                <img className="float-right fullscreen-icon" src="/icon/back_arrow_angle.svg" alt="back" />
              </div>

              {Object.keys(jobDesc).length > 0 && (
                <div className="border-0 pb-3 pt-4 search-res-title">
                  <div className="job-type-title">
                    <Card.Title className="job-type text-brown mb-4">{jobDesc?.title || ""}</Card.Title>
                    <Card.Subtitle className="mb-2 text-brown job-type-subtitle pb-2">
                      {jobDesc?.companyName || ""} -{" "}
                      {jobDesc?.location ||
                        (jobDesc?.city || "") + ", " + (jobDesc?.state || "") + " " + (jobDesc?.zipCode || "") ||
                        ""}
                    </Card.Subtitle>
                    {jobDesc?.title && (
                      <div className="mb-3">
                        <Button
                          type="button"
                          className="apply-btn-data search-button-css fs-apply-btn"
                          onClick={() => handleApplyJob(true, jobId || jobDesc?.jobId)}
                        >
                          Apply
                        </Button>
                      </div>
                    )}
                  </div>
                </div>
              )}
            </Col>
          </Row>
        )}
        {loading && <Loader loaderStyle="search-expand-loader" />}

        <div className="">
          <Card.Body>
            <div className="job-type-title">
              <Card.Subtitle className="job-summary-inner">
                <div
                  dangerouslySetInnerHTML={createMarkup(jobDesc?.description || "")}
                  className="job-desc-block qualification-section-block"
                ></div>
              </Card.Subtitle>
            </div>
          </Card.Body>
        </div>
      </div>
      {isAppliedSearchJob && showApplyJob && (
        <ApplyForJob jobId={jobId || jobDesc?.jobId} />
      )}

      {showSearchAlert && (
        <SearchJobNotFound
          title={"Job not found"}
          message={"The job opening you are looking for does not exists or it has been closed"}
          handleShowSearchAlert={handleShowSearchAlert}
        />
      )}
    </div>
  );
};
export default compose(withRouter, withFirebase)(SearchJobDescription);
