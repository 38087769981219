import { _ApiPost } from "../util/Networking";
import { SUPPORT } from "../constants/api";

export const postSupportRequest = (requestDetail) => {
  return new Promise((resolve, reject) => {
    _ApiPost(`${SUPPORT}`, { ...requestDetail })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error);
      });
  });
};
