import {
  SERCH_JOBS_SUCCESS,
  SET_CURRENT_PAGE,
  SET_PREVIOUS_PAGE,
  SELECTED_JOBS_SUCCESS,
  SET_SEARCH_INPUT_DETAIL,
  JOB_LOCATION_DETAILS,
  JOB_DETAILS,
  JOB_QUALIFICATION,
  CLEAR_JOB_DETAILS,
  STAGED_JOB_ID,
  STAGED_JOB_DETAILS,
  APPLY_SEARCHED_JOB,
  JOB_ID,
  IS_VIEW_UPLOAD_DOC,
  IS_REMOVE_UPLOADED_DOC,
  JOB_STEPS_DETAILS,
  TOTAL_JOB_STEPS_COUNT,
  SET_DOCUMENT_STEP,
  JOB_STEP_RESUME,
  SEARCH_RESULTS,
  SEARCH_EXPAND_RESULT,
  SUCCESS_UPLOAD_RESUME
} from "../constants/jobs.constants";

export const storeSearchJobDetails = (searchDetail) => (dispatch) => {
  return dispatch({
    type: SERCH_JOBS_SUCCESS,
    payload: searchDetail,
  });
};

export const storeSelectedSearchJobDetails = (searchDetail) => (dispatch) => {
  return dispatch({
    type: SELECTED_JOBS_SUCCESS,
    payload: searchDetail,
  });
};

export const setCurrentPage = (currentPage) => (dispatch) => {
  return dispatch({
    type: SET_CURRENT_PAGE,
    payload: currentPage,
  });
};

export const setPreviousPage = (previousPage) => (dispatch) => {
  return dispatch({
    type: SET_PREVIOUS_PAGE,
    payload: previousPage,
  });
};

export const setJobStepsDetail = (stepsDetail) => (dispatch) => {
  return dispatch({
    type: JOB_STEPS_DETAILS,
    payload: stepsDetail,
  });
};

export const setTotalJobStepsCount = (totalStepsCount) => (dispatch) => {
  return dispatch({
    type: TOTAL_JOB_STEPS_COUNT,
    payload: totalStepsCount,
  });
};


export const setDocSteps = (docStep) => (dispatch) => {
  return dispatch({
    type: SET_DOCUMENT_STEP,
    payload: docStep,
  });
};

export const applyJobstepResume = (resumeStep) => (dispatch) => {
  return dispatch({
    type: JOB_STEP_RESUME,
    payload: resumeStep,
  });
};

export const checkResumeUpload = (uploadResume) => (dispatch) => {
  return dispatch({
    type: SUCCESS_UPLOAD_RESUME,
    payload: uploadResume,
  });
};

export const setSearchInputDetail = (searchInputDetail) => (dispatch) => {
  return dispatch({
    type: SET_SEARCH_INPUT_DETAIL,
    payload: searchInputDetail,
  });
};

export const jobLocations = (jobLocations) => (dispatch) => {
  return dispatch({
    type: JOB_LOCATION_DETAILS,
    payload: jobLocations,
  });
};

export const jobDetails = (jobDetail) => (dispatch) => {
  return dispatch({
    type: JOB_DETAILS,
    payload: jobDetail,
  });
};

export const jobQualifications = (jobQualification) => (dispatch) => {
  return dispatch({
    type: JOB_QUALIFICATION,
    payload: jobQualification,
  });
};

export const storeStagedJobId = (stagedJobId) => (dispatch) => {
  return dispatch({
    type: STAGED_JOB_ID,
    payload: stagedJobId,
  });
};

export const storeStagedJobDetails = (stagedJobDetail) => (dispatch) => {
  return dispatch({
    type: STAGED_JOB_DETAILS,
    payload: stagedJobDetail,
  });
};

export const appliedSearchJob = (isApply, jobId) => (dispatch) => {
  dispatch({
    type: APPLY_SEARCHED_JOB,
    payload: isApply,
  });

  dispatch({
    type: JOB_ID,
    payload: jobId,
  });
};

export const getSearchResults = (searchResult) => (dispatch) => {
  dispatch({
    type: SEARCH_RESULTS,
    payload: searchResult,
  });
};

export const getSearchExpand = (searchExpand, jobId) => (dispatch) => {
  dispatch({
    type: SEARCH_EXPAND_RESULT,
    payload: searchExpand,
  });
};

export const setViewUploadedDocs = (status) => (dispatch) => {
  dispatch({
    type: IS_VIEW_UPLOAD_DOC,
    payload: status,
  });
};

export const setRemoveUploadedDocs = (status) => (dispatch) => {
  dispatch({
    type: IS_REMOVE_UPLOADED_DOC,
    payload: status,
  });
};

export const clearJobDetails = () => (dispatch) => {
  return dispatch({
    type: CLEAR_JOB_DETAILS,
    payload: [],
  });
};
