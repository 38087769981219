import React, { useState, useEffect, useRef, lazy } from "react";
import { Modal } from "react-bootstrap";
import { withRouter, useLocation } from "react-router-dom";
import { compose } from "recompose";
import { useSelector, useDispatch } from "react-redux";
import * as ROUTES from "../../constants/routes";
import { withFirebase } from "../../components/Firebase";
import { errorCode, errorMessage } from "../../util/Validatios";
import { setAuthHeader } from "../../ApiConfig";
import { SaveSessionExpiry } from "../../util/index";
import { LOGIN_METHOD_EMAIL } from "../../constants/keys";
import { APPLY_JOB_STEP, IS_UPLOAD_RESUME } from "../../redux/constants/jobs.constants";
import {
  IS_EMAIL_VERIFY,
  IS_AUTH,
  UPLOAD_RESUME_AUTH,
  IS_UPLOAD_EMAIL_VERIFY,
} from "../../redux/constants/auth.constants";
import { PROFILE_DETAILS } from "../../redux/constants/profile.constants";
import { loginWithPwd } from "../../components/Firebase/fbAuthentication";
import {
  INTRODUCTION,
  SKILLS,
  SKILLS_INTRODUCTION,
  RESUME,
  VIDEO,
  COVER_LETTER,
  VIDEO_INTRODUCTION,
  ADD_PERSONAL_INFO,
} from "../../constants/keys";
import { setDocSteps } from "../../redux/actions/jobs.action";
import { fbSetScreenName, fbSetEvent, fbSetParams } from "../../Action/firebaseAnalytics";
import {
  LOGIN_EMAIL_CHOICE,
  OPEN_SCREEN,
  APP_BUTTON,
  EMAIL_LOGIN,
  SESSION_EXPIRE,
  SESSION_PERSIST,
  API_ERROR,
  GET_PROFILE,
  FIREBASEAUTH_ERROR,
  USER_ERROR,
  CAPTCHA_FAILURE,
} from "../../constants/firebaseAnalytics";
import { IS_UPLOAD_DOC } from "../../redux/constants/jobs.constants";
import { appFirebaseAuthStateChanged } from "../../components/Firebase/fbAuthentication";
import { OK, NOT_FOUND, FORBIDDEN } from "../../constants/apiStatusCodes";
import { resetLocalAuthToken } from "../../Action/authentication";
import Loadable from "../../components/Loadable";
import "./index.css";

const FloatingInputBox = Loadable(lazy(() => import("../../components/FloatingInputBox")));
const LoadingIndicator = Loadable(lazy(() => import("../../components/LoadingIndicator")));
const InterceptBrowserButton = Loadable(lazy(() => import("../../components/InterceptBrowserButton")));

const SignInModal = ({
  showSignIn,
  handleSignInModal,
  handleShow,
  loading,
  loaderHandler,
  fetchUserInformation,
  firebase,
  history,
  handleModalCloseState,
  modalCloseStatus,
  handleModalOpenState,
  modalOpenStatus,
  handleForgotPassModal,
  submissionId,
  handleSubmissionId,
}) => {
  const [error, setError] = useState("");
  const [inputVal, setInputVal] = useState({ email: "", password: "" });
  const [keepMe, setKeepMe] = useState(false);
  const singinCaptchaRef = useRef(null);
  const dispatch = useDispatch();
  const isJobAuth = useSelector((state) => state.auth.isAuth);
  const isUpdResumeAuth = useSelector((state) => state.auth.isUpdResumeAuth);
  const authUploadResume = useSelector((state) => state.auth.authUploadResume);
  const updApplicationDetail = useSelector((state) => state.job.updApplicationDetail);
  const jobStepsDetails = useSelector((state) => state.job.jobStepsDetails);
  const location = useLocation();

  const onChangeHandler = (evt) => {
    setError("");
    const value = evt.target.value;
    setInputVal({
      ...inputVal,
      [evt.target.name]: value,
    });
  };

  const getKeepMeText = () => {
    if (window.navigator.userAgent.indexOf("like Mac") !== -1) {
      return "Keep me logged in for the next 7 days";
    } else {
      return "Keep me logged in";
    }
  };

  const renderNextStep = () => {
    const screenName = jobStepsDetails[0]?.screen;
    dispatch(setDocSteps(screenName));

    switch (screenName) {
      case INTRODUCTION:
        return INTRODUCTION;
      case SKILLS:
      case SKILLS_INTRODUCTION:
      case RESUME:
      case COVER_LETTER:
      case VIDEO_INTRODUCTION:
      case VIDEO:
        return SKILLS;
      default:
        return ADD_PERSONAL_INFO;
    }
  };

  const handleStates = async () => {
    if (isUpdResumeAuth) {
      await dispatch({
        type: IS_UPLOAD_RESUME,
        payload: true,
      });
    } else {
      await dispatch({
        type: APPLY_JOB_STEP,
        payload: renderNextStep(),
      });
    }

    await dispatch({
      type: IS_AUTH,
      payload: false,
    });

    await dispatch({
      type: UPLOAD_RESUME_AUTH,
      payload: false,
    });
  };

  const handleSubmitResume = async () => {
    await dispatch({ type: IS_UPLOAD_DOC, payload: true });
  };

  const handleSetUserInfo = async (user) => {
    await dispatch({
      type: PROFILE_DETAILS,
      payload: {
        email: user.data.email,
        firstName: user.data.firstName,
        lastName: user.data.lastName,
      },
    });

    window.handleAuthSuceess(user.data);
  };

  const handleResetCaptchaContainer = () => {
    if (singinCaptchaRef?.current?.innerHTML) {
      singinCaptchaRef.current.innerHTML = `<div id="signInRecaptcha" className="recaptcha-container"></div>`;
    }
  };

  const handleRefreshAuthToken = async (refreshToken) => {
    await resetLocalAuthToken(refreshToken);
    await setAuthHeader();
  };

  const onSignInFormSubmit = async () => {
    handleResetCaptchaContainer();
    const { email, password } = inputVal;
    handleFBEvents(APP_BUTTON, {
      cta: EMAIL_LOGIN,
      sessionType: keepMe ? SESSION_PERSIST : SESSION_EXPIRE,
    });
    if (Object.keys(inputVal).length > 0 && email !== "" && password !== "") {
      loaderHandler(true);
      loginWithPwd(firebase, email, password, "signInRecaptcha")
        .then(async (result) => {
          handleResetCaptchaContainer();
          SaveSessionExpiry(keepMe, LOGIN_METHOD_EMAIL);
          const refreshToken = await appFirebaseAuthStateChanged(firebase);
          if (refreshToken) {
            await handleRefreshAuthToken();
            fetchUserInformation()
              .then(async (user) => {
                handleSetUserInfo(user);
                loaderHandler(false);
                if (user.status !== OK) {
                  handleFBEvents(API_ERROR, {
                    status: user.status,
                    apiCall: GET_PROFILE,
                  });
                  return false;
                }
                if (!result?.user?.emailVerified) {
                  if ((isJobAuth || isUpdResumeAuth) && !authUploadResume) {
                    await dispatch({
                      type: isUpdResumeAuth ? IS_UPLOAD_EMAIL_VERIFY : IS_EMAIL_VERIFY,
                      payload: true,
                    });

                    handleStates();
                  } else if (
                    isUpdResumeAuth &&
                    authUploadResume &&
                    updApplicationDetail?.id &&
                    updApplicationDetail?.mediaType
                  ) {
                    await handleSubmitResume();
                  } else {
                    history.push(ROUTES.EMAIL_CONFIRMATION, {
                      messageTitle: "Your email address has not been verified yet",
                    });
                  }
                } else {
                  if ((isJobAuth || isUpdResumeAuth) && !authUploadResume) {
                    await handleStates();
                  } else if (
                    isUpdResumeAuth &&
                    authUploadResume &&
                    updApplicationDetail?.id &&
                    updApplicationDetail?.mediaType
                  ) {
                    await handleSubmitResume();
                  } else {
                    history.push(location?.pathname);
                  }
                }

                handleSignInModal(false);
                handleModalCloseState("close-left");
                handleModalOpenState("right");
                setInputVal({ email: "", password: "" });
              })
              .catch((error) => {
                handleResetCaptchaContainer();
                handleResetCaptchaContainer();
                if (
                  error?.response?.data?.code === errorCode.profileNotFound ||
                  error?.response?.status === NOT_FOUND
                ) {
                  setTimeout(() => {
                    history.push({
                      pathname: ROUTES.ACCOUNT_CONFIRM,
                      state: {
                        userDetails: { email },
                        fName: "",
                        lName: "",
                      },
                    });
                  }, 100);
                }

                handleFBEvents(API_ERROR, {
                  status: error?.response?.status || FORBIDDEN,
                  apiCall: GET_PROFILE,
                });

                setError(errorMessage.others);
                loaderHandler(false);
              });
          }
        })
        .catch((error) => {
          handleResetCaptchaContainer();
          handleFBEvents(FIREBASEAUTH_ERROR, {
            method: LOGIN_METHOD_EMAIL,
            message: error?.message || errorMessage.others,
          });
          if (
            error.code === errorCode.wrongPassword ||
            error.code === errorCode.userNotFound ||
            error.code === errorCode.invalidEmai
          ) {
            setError(errorMessage.invalidCred);
          } else if (!error.code && error.indexOf(CAPTCHA_FAILURE) !== -1) {
            handleFBEvents(CAPTCHA_FAILURE, {});
          } else {
            setError(errorMessage.others);
          }
          loaderHandler(false);
        });
    } else {
      handleResetCaptchaContainer();
      loaderHandler(false);
      handleFBEvents(USER_ERROR, {
        message: errorMessage.invalidCred,
      });
      setError(errorMessage.invalidCred);
    }
  };

  const handleFBEvents = (eventName, params) => {
    dispatch(fbSetEvent(eventName));
    dispatch(fbSetParams(params));
  };

  const handlefbSetScreen = () => {
    handleFBEvents(OPEN_SCREEN, {});
    dispatch(fbSetScreenName(LOGIN_EMAIL_CHOICE));
    handleSubmissionId(LOGIN_EMAIL_CHOICE);
  };

  const handleBackPress = () => {
    handleModalOpenState("left");
    handleSignInModal(false, "close-right");
    handleShow();
  };

  const handlePressForgotPass = (status) => {
    handleForgotPassModal(status);
    handleSignInModal(false, "close-left");
  };

  useEffect(() => {
    if (showSignIn) {
      handlefbSetScreen();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showSignIn]);

  return (
    <>
      {showSignIn && <InterceptBrowserButton isHandleBack={true} handleBack={handleBackPress} />}

      <Modal
        centered
        show={showSignIn}
        onHide={() => {
          handleSignInModal(false);
        }}
        className={`auth-modal login-modal ${showSignIn ? modalOpenStatus : modalCloseStatus}`}
      >
        <Modal.Header>
          <div>
            <p data-dismiss="modal" onClick={() => handleBackPress()} className="auth-modal-back">
              <img src="/icon/back_arrow.svg" className="back-arrow" alt="back" />
              <span className="label-back"> Back</span>
            </p>
          </div>
        </Modal.Header>
        <Modal.Body className="auth-modal-body signin-modal-body">
          {loading && <LoadingIndicator loaderStyle="homePageLoader" />}

          <div ref={singinCaptchaRef} className="recaptcha-container">
            <div id="signInRecaptcha" className="recaptcha-container"></div>
          </div>
          <div>
            <div className="credentials-details">Please enter your credentials to log in</div>
            <div className="top-forgot-container">
              <span className="password-change" onClick={() => handlePressForgotPass(true)}>
                forgot password?
              </span>
            </div>

            <div>
              <div className="form-floating">
                <FloatingInputBox
                  id="email"
                  name="email"
                  placeholder="Email"
                  type="email"
                  className="form-floating-input auth-floating-input"
                  value={inputVal.email || ""}
                  onChangeHandler={onChangeHandler}
                />
              </div>
            </div>
            <div>
              <div className="form-floating">
                <FloatingInputBox
                  id="password"
                  name="password"
                  placeholder="Password"
                  type="password"
                  className="form-floating-input auth-floating-input"
                  value={inputVal.password || ""}
                  onChangeHandler={onChangeHandler}
                />
              </div>
            </div>
            <div className="sigin-third-container">
              <label className="check-box keep-login">
                <input type="checkbox" name="checkbox-01" className="blue" onChange={() => setKeepMe(!keepMe)} />
                <span className="lbl-keep-login">{getKeepMeText()}</span>
              </label>

              <span className="password-change bottom-forgot" onClick={() => handlePressForgotPass(true)}>
                Forgot password?
              </span>
            </div>

            {error && (
              <div className="error-msg">
                <p>{error}</p>
              </div>
            )}
            <div className="login-btn">
              <button
                type="submit"
                id={LOGIN_EMAIL_CHOICE}
                className="btn-pwa-signUp"
                onClick={() => onSignInFormSubmit()}
              >
                Log in
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default compose(withRouter, withFirebase)(SignInModal);
