import { combineReducers } from "redux";

import profileReducer from "./reducer/profile.reducer";
import authReducer from "./reducer/auth.reducer";
import jobReducer from "./reducer/jobs.reducer";
import firebaseAnalytics from "./reducer/firebaseAnalytics.reducer";

const rootReducer = combineReducers({
  profile: profileReducer,
  auth: authReducer,
  job: jobReducer,
  fbAnalytics: firebaseAnalytics,
});

export default rootReducer;
