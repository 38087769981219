import React from "react";
import { withRouter } from "react-router-dom";
import { compose } from "recompose";
import { withFirebase } from "../../components/Firebase";
import SearchJobDescription from "../Search/searchJobDescription";
import SearchExpand from "../SearchExpand";
import "./index.css";

export const SearchJobDetail = (props) => {
  const isMobile = window.innerWidth <= 768;

  return <>{isMobile ? <SearchJobDescription {...props} /> : <SearchExpand {...props} />}</>;
};
export default compose(withRouter, withFirebase)(SearchJobDetail);
