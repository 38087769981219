import React, { Component } from "react";
import ResetPasswordModal from "../ResetPasswordModal";
import EmailVerifiedModal from "../EmailVerifiedModal";

const queryString = require("query-string");
class ExternalLinks extends Component {
  constructor(props) {
    super(props);
    this.parsed = queryString.parse(props.location.search);
  }

  render() {
    if (this.parsed.mode === "verifyEmail") {
      return <EmailVerifiedModal oobCode={this.parsed.oobCode} />;
    } else if (this.parsed.mode === "resetPassword") {
      return <ResetPasswordModal oobCode={this.parsed.oobCode} />;
    }
  }
}
export default ExternalLinks;
