import React from "react";
import "./index.css";

const Loader = ({ loaderStyle }) => {
  return (
    <div className={`${loaderStyle || "loaderparent"}`}>
      <img src="/image/loader.gif" alt="loading..." />
    </div>
  );
};

export default Loader;
