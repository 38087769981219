import Axios from "axios";
import { ApiGet, ApiPost, ApiDelete, ApiPut, _ApiGet } from "../util/Networking";
import {
  RESUME_URLS,
  LATEST_RESUME,
  RESUME,
  OPENING,
  APPLICATION,
  APPLY,
  COVERLETTERS,
  RESUMES,
  VIDEOS,
} from "../constants/api";

export const fetchOpeningApplication = (id) => {
  return new Promise((resolve, reject) => {
    _ApiGet(`${OPENING}/${id}`)
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error);
      });
  });
};

export const getApplicationSteps = (id) => {
  return new Promise((resolve, reject) => {
    ApiGet(`/opening/${id}${APPLICATION}?resume=true&coverLetter=true&qualifications=true&video=true`)
      .then(function (response) {
        resolve(response)
      })
      .catch(function (error) {
        reject(error);
      });
  });
};

export const uploadFiles = (uploadUrl, file) => {
  return new Promise((resolve, reject) => {
    Axios.put(`${uploadUrl}`, file, {
      headers: {
        "Content-Type": file.type,
      },
    })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error);
      });
  });
};

export const postJobApplication = (id, jobDetail) => {
  return new Promise((resolve, reject) => {
    ApiPost(`/opening/${id}${APPLY}`, { ...jobDetail })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error);
      });
  });
};

export const fetchFileUrl = (fileId, fileType) => {
  return new Promise((resolve, reject) => {
    ApiGet(`/file?fileId=${fileId}&fileType=${fileType}`)
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error);
      });
  });
};

export const downloadFileUrl = (fileUrl) => {
  return new Promise((resolve, reject) => {
    Axios.get(`${fileUrl}`)
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error);
      });
  });
};

export const fetchUploadedResumes = () => {
  return new Promise((resolve, reject) => {
    ApiGet(`${RESUMES}`)
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error);
      });
  });
};

export const fetchUploadedCoverLetters = () => {
  return new Promise((resolve, reject) => {
    ApiGet(`${COVERLETTERS}`)
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error);
      });
  });
};

export const deleteUploadedDocs = (documentId) => {
  return new Promise((resolve, reject) => {
    ApiDelete(`/${documentId}`)
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error);
      });
  });
};

export const editUploadedDocsName = (documentUrl) => {
  return new Promise((resolve, reject) => {
    ApiPut(`/${documentUrl}`)
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error);
      });
  });
};

export const getSuggestedSkills = (id) => {
  return new Promise((resolve, reject) => {
    ApiGet(`/opening/${id}/suggestedSkills`)
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error);
      });
  });
};

export const getUploadUrl = (fileType, mediaType) => {
  return new Promise((resolve, reject) => {
    ApiGet(`/uploadUrl?fileType=${fileType}&mediaType=${mediaType}`)
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error);
      });
  });
};

export const fetchUploadedVideos = () => {
  return new Promise((resolve, reject) => {
    ApiGet(`${VIDEOS}`)
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error);
      });
  });
};

export const deleteUploadedVideo = (documentId) => {
  return new Promise((resolve, reject) => {
    ApiDelete(`/video/${documentId}`)
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error);
      });
  });
};

export const editUploadedVideoName = (documentUrl) => {
  return new Promise((resolve, reject) => {
    ApiPut(`/video/${documentUrl}`)
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error);
      });
  });
};

export const getResumeUrls = () => {
  return new Promise((resolve, reject) => {
    ApiGet(`${RESUME_URLS}`)
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error);
      });
  });
};

export const getLatestResume = () => {
  return new Promise((resolve, reject) => {
    ApiGet(`${LATEST_RESUME}`)
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error);
      });
  });
};

export const saveResume = (id, timezoneOffset, mediaType) => {
  return new Promise((resolve, reject) => {
    ApiPut(`${RESUME}/${id}?timezoneOffset=${timezoneOffset}&mediaType=${mediaType}`)
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error);
      });
  });
};

export const getQrApplication = (id) => {
  return new Promise((resolve, reject) => {
    ApiGet(`/qrApplication/${id}`)
      .then(function (response) {
        resolve(response)
      })
      .catch(function (error) {
        reject(error);
      });
  });
};
