import { AUTH_TOKEN, KEEP_ME_LOGGED_IN, SEARCH_VALUES } from "../../constants/keys";

export const GetValue = (key) => {
  // console.log('getting value:' + key, localStorage.getItem(key));
  return localStorage.getItem(key);
};

export const SetValue = async (key, value) => {
  if (value != null) {
    return localStorage.setItem(key, value);
  }
};

export const ClearAllStoredData = () => {
  for (var key in localStorage) {
    if (key !== SEARCH_VALUES) {  //remove all values except the search values
        localStorage.removeItem(key);
    }
 }
};

const removeSpecificKey = (key) => {
  return localStorage.removeItem(key);
};

export const ClearLocalStorageSessionData = () => {
  const keyArr = [AUTH_TOKEN, KEEP_ME_LOGGED_IN];

  keyArr.map((itemKey, index) => {
    removeSpecificKey(itemKey);
  });
  return;
};
