import React, { useState, useEffect,lazy } from "react";
import { Modal } from "react-bootstrap";
import { withRouter, useLocation } from "react-router-dom";
import { compose } from "recompose";
import { useSelector, useDispatch } from "react-redux";
import { withFirebase } from "../../components/Firebase";
import * as ROUTES from "../../constants/routes";
import { LOGIN_METHOD_GOOGLE, LOGIN_METHOD_APPLE } from "../../constants/keys";
import { errorCode, errorMessage } from "../../util/Validatios";
import { setAuthHeader } from "../../ApiConfig";
import { fetchUserInformation } from "../../Action/authentication";
import { APPLY_JOB_STEP, IS_UPLOAD_RESUME } from "../../redux/constants/jobs.constants";
import {
  INTRODUCTION,
  JOB_DESCRIPTION,
  SKILLS,
  SKILLS_INTRODUCTION,
  RESUME,
  VIDEO,
  COVER_LETTER,
  VIDEO_INTRODUCTION,
  ADD_PERSONAL_INFO,
} from "../../constants/keys";
import {
  appLoginWithGoogle,
  appFirebaseAuthStateChanged,
  appLoginWithApple,
} from "../../components/Firebase/fbAuthentication";
import { PROFILE_DETAILS, ACCOUNT_CONFIRMATION } from "../../redux/constants/profile.constants";
import { IS_AUTH, UPLOAD_RESUME_AUTH, SWITCH_ACCOUNT } from "../../redux/constants/auth.constants";
import { fbSetScreenName, fbSetEvent, fbSetParams } from "../../Action/firebaseAnalytics";
import {
  REGISTRATION_CHOICE,
  LOGIN_CHOICE,
  OPEN_SCREEN,
  LOGIN_WITH_EMAIL,
  SIGN_UP,
  LOGIN_WITH_GOOGLE,
  LOGIN_WITH_APPLE,
  APP_BUTTON,
  API_ERROR,
  FIREBASEAUTH_ERROR,
  GET_PROFILE,
} from "../../constants/firebaseAnalytics";
import { IS_UPLOAD_DOC } from "../../redux/constants/jobs.constants";
import { OK, NOT_FOUND, FORBIDDEN } from "../../constants/apiStatusCodes";
import InterceptBrowserButton from "../../components/InterceptBrowserButton";
import { setDocSteps } from "../../redux/actions/jobs.action";
import Loadable from "../../components/Loadable";
import "./index.css";
import "../../components/Slider/styles.css";

const FederatedPlaceholder = Loadable(lazy(() => import('./FederatedPlaceholder')));

const AuthModal = ({
  show,
  handleClose,
  handleSignInModal,
  handleSignUpModal,
  handleAuthModal,
  authStatus,
  firebase,
  updateUserInformation,
  handleModalCloseState,
  modalCloseStatus,
  handleModalOpenState,
  modalOpenStatus,
  handleShow,
  history,
}) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [showFedPlaceholder, setShowFedPlaceholder] = useState(false);
  const [fedType, setFedType] = useState("");
  const dispatch = useDispatch();
  const location = useLocation();
  const isJobAuth = useSelector((state) => state.auth.isAuth);
  const isUpdResumeAuth = useSelector((state) => state.auth.isUpdResumeAuth);
  const authUploadResume = useSelector((state) => state.auth.authUploadResume);
  const updApplicationDetail = useSelector((state) => state.job.updApplicationDetail);
  const screenName = authStatus === "signin" ? LOGIN_CHOICE : REGISTRATION_CHOICE;
  const jobStepsDetails = useSelector((state) => state.job.jobStepsDetails);

  const renderNextStep = () => {
    const screenName = jobStepsDetails[0]?.screen;
    dispatch(setDocSteps(screenName));

    switch (screenName) {
      case INTRODUCTION:
        return INTRODUCTION;
      case SKILLS:
      case SKILLS_INTRODUCTION:
      case RESUME:
      case COVER_LETTER:
      case VIDEO_INTRODUCTION:
      case VIDEO:
        return SKILLS;
      default:
        return ADD_PERSONAL_INFO;
    }
  };

  const handleStates = async () => {
    await dispatch({
      type: APPLY_JOB_STEP,
      payload: renderNextStep(),
    });

    await dispatch({
      type: IS_AUTH,
      payload: false,
    });
  };

  const handleBackPressStates = async () => {
    await dispatch({
      type: APPLY_JOB_STEP,
      payload: JOB_DESCRIPTION,
    });

    await dispatch({
      type: IS_AUTH,
      payload: false,
    });
  };

  const handleUpdResumeState = async () => {
    await dispatch({
      type: IS_UPLOAD_RESUME,
      payload: true,
    });
  };

  const handleJobAuthState = async () => {
    await dispatch({
      type: IS_AUTH,
      payload: false,
    });
  };

  const handleApplyAccConf = async () => {
    await dispatch({
      type: ACCOUNT_CONFIRMATION,
      payload: true,
    });
  };

  const handleSetUserInfo = async (user) => {
    await dispatch({
      type: PROFILE_DETAILS,
      payload: {
        email: user.data.email,
        firstName: user.data.firstName,
        lastName: user.data.lastName,
      },
    });

    window.handleAuthSuceess(user.data);
  };

  const handleShowFedPlaceholder = (status) => {
    setTimeout(() => {
      setShowFedPlaceholder(status);
    }, 100);
  };

  const handleCloseAuthModal = () => {
    handleSignInModal(false);
    handleAuthModal(false);
    handleModalCloseState("close-left");
    handleModalOpenState("right");
  };

  const handleBackPress = async () => {
    handleModalCloseState("close-right");
    handleModalOpenState("right");
    handleClose();
    setTimeout(async () => {
      if (isJobAuth && !isUpdResumeAuth && !authUploadResume) {
        await handleBackPressStates();
      } else if (isUpdResumeAuth && !authUploadResume) {
        // await handleUpdResumeState();
      }
    }, 100);
  };

  const handleSubmitResume = async () => {
    await dispatch({ type: IS_UPLOAD_DOC, payload: true });
  };

  const handleFirebaseAuthChanged = (authUser) => {
    appFirebaseAuthStateChanged(firebase).then(async (idToken) => {
      await setAuthHeader();
      const arrName = authUser?.user?.displayName ? authUser.user.displayName.split(" ") : ["", ""];
      const firstName = arrName[0];
      const lastName = arrName.length > 1 ? arrName.pop() : "";

      fetchUserInformation()
        .then(async (user) => {
          await handleSetUserInfo(user);
          setLoading(false);
          if (user.status !== OK) {
            handleFBEvents(API_ERROR, {
              status: user.status,
              apiCall: GET_PROFILE,
            });
            return false;
          }

          if (isJobAuth) {
            await handleStates();
          } else if (isUpdResumeAuth && !authUploadResume) {
            await handleUpdResumeState();
          } else if (
            isUpdResumeAuth &&
            authUploadResume &&
            updApplicationDetail?.id &&
            updApplicationDetail?.mediaType
          ) {
            await handleSubmitResume();
          }
          handleShowFedPlaceholder(false);
          handleCloseAuthModal();
        })
        .catch(async (error) => {
          if (error?.response?.data?.code === errorCode.profileNotFound || error?.response?.status === NOT_FOUND) {
            if (isJobAuth) {
              handleApplyAccConf();
            }

            handleCloseAuthModal();
            handleShowFedPlaceholder(false);
            setTimeout(() => {
              history.push({
                pathname: ROUTES.ACCOUNT_CONFIRM,
                state: {
                  userDetails: authUser.additionalUserInfo.profile,
                  fName: firstName,
                  lName: lastName,
                },
              });
            }, 100);
          } else {
            setError(errorMessage.others);
          }

          handleFBEvents(API_ERROR, {
            status: error?.response?.status || FORBIDDEN,
            apiCall: GET_PROFILE,
          });
          handleJobAuthState();
          setLoading(false);
        });
    });
  };

  const loginWithGoogle = () => {
    setFedType("Google");
    setLoading(true);

    handleCloseAuthModal();
    handleShowFedPlaceholder(true);
    handleFBEvents(APP_BUTTON, { cta: LOGIN_WITH_GOOGLE });
    appLoginWithGoogle(firebase)
      .then((authUser) => {
        handleFirebaseAuthChanged(authUser);
      })
      .catch((error) => {
        if (error.code !== errorCode.dismissPopup && error.code !== errorCode.cancelledPopup) {
          setError(error.message);
        }
        handleFBEvents(FIREBASEAUTH_ERROR, {
          method: LOGIN_METHOD_GOOGLE,
          message: error?.message || errorMessage.others,
        });
        handleShowFedPlaceholder(false);
        handleShow(true);
        setLoading(false);
      });
  };

  const loginWithApple = () => {
    setFedType("Apple");
    setLoading(true);

    handleCloseAuthModal();
    handleShowFedPlaceholder(true);
    handleFBEvents(APP_BUTTON, { cta: LOGIN_WITH_APPLE });
    appLoginWithApple(firebase)
      .then(async (authUser) => {
        handleFirebaseAuthChanged(authUser);
      })
      .catch((error) => {
        if (error.code !== errorCode.dismissPopup && error.code !== errorCode.cancelledPopup) {
          setError(error.message);
        }
        setLoading(false);
        handleShowFedPlaceholder(false);
        handleShow(true);
        handleFBEvents(FIREBASEAUTH_ERROR, {
          method: LOGIN_METHOD_APPLE,
          message: error?.message || errorMessage.others,
        });
      });
  };

  const handleBackDrops = () => {
    handleModalCloseState("close-drop");
    handleModalOpenState("right");
    handleClose();
    dispatch({ type: IS_UPLOAD_RESUME, payload: false });
    dispatch({
      type: SWITCH_ACCOUNT,
      payload: false,
    });
    dispatch({ type: UPLOAD_RESUME_AUTH, payload: false });
  };

  const handleFBEvents = (eventName, params) => {
    dispatch(fbSetParams(params));
    dispatch(fbSetEvent(eventName));
  };

  const handlefbSetScreen = () => {
    dispatch(fbSetScreenName(screenName));
    handleFBEvents(OPEN_SCREEN, {});
  };

  const handleOnClickSignup = async () => {
    if (isUpdResumeAuth && !authUploadResume) {
      handleCloseAuthModal(); // this condition use for upload resume without user authenticate
      handleUpdResumeState();
    } else {
      handleSignUpModal(true);
      handleFBEvents(APP_BUTTON, { cta: SIGN_UP });
    }
  };

  useEffect(() => {
    if (show) {
      handlefbSetScreen();
      setError("");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show]);

  useEffect(() => {
    if (location.pathname && show) {
      history.push(location.pathname);
    }
  }, [location.pathname, show]);

  return (
    <>
      {show && <InterceptBrowserButton isHandleBack={true} handleBack={handleBackPress} />}
      <Modal
        centered
        show={show}
        onHide={() => handleBackDrops()}
        className={`auth-modal ${show ? modalOpenStatus : modalCloseStatus}`}
      >
        <Modal.Header>
          <div>
            <div data-dismiss="modal" className="auth-modal-back auth-modal-cancel">
              <span onClick={() => handleBackPress()} className="btn-auth-back">
                <>
                  <img src="/icon/back_arrow.svg" className="back-arrow" alt="back" />
                  <span className="label-back"> Back</span>
                </>
              </span>

              <span></span>
            </div>
          </div>
        </Modal.Header>
        <Modal.Body className="auth-modal-body auth-action-body">
          <div>
            <div>
              {authStatus === "signin" ? (
                <button
                  type="button"
                  className="btn btn-signin btn-signup bth-auth btn-pwd-shadow btn-auth-flex"
                  onClick={() => {
                    handleModalOpenState("right");
                    handleSignInModal(true);
                    handleFBEvents(APP_BUTTON, { cta: LOGIN_WITH_EMAIL });
                  }}
                >
                  <img src="/icon/sky_logo_icon.svg" alt="logo" />
                  Log in with email
                </button>
              ) : (
                <button
                  type="button"
                  className="btn-signup btn-pwa-singin bth-auth btn-auth-flex"
                  onClick={() => handleOnClickSignup()}
                >
                  <img src="/icon/light_logo_icon.svg" alt="logo" />
                  {!authUploadResume && isUpdResumeAuth ? "I don’t have an account" : "Sign up with email"}
                </button>
              )}
            </div>
            <div className="lbl-auth-or">
              <span>or</span>
            </div>
            <div>
              {authStatus === "signin" ? (
                <button
                  type="button"
                  className="btn-signup btn-pwa-singin bth-auth btn-auth-flex"
                  onClick={() => {
                    handleSignUpModal(true);
                    handleFBEvents(APP_BUTTON, { cta: SIGN_UP });
                  }}
                >
                  <img src="/icon/light_logo_icon.svg" alt="signup" />
                  Sign up with email
                </button>
              ) : (
                <button
                  type="button"
                  className="btn btn-signin btn-signup bth-auth btn-pwd-shadow btn-auth-flex"
                  onClick={() => {
                    handleModalOpenState("right");
                    handleSignInModal(true);
                    handleFBEvents(APP_BUTTON, { cta: LOGIN_WITH_EMAIL });
                  }}
                >
                  <img src="/icon/sky_logo_icon.svg" alt="singin" />
                  Log in with email
                </button>
              )}
            </div>

            <div>
              <button
                type="button"
                className="btn btn-auth-google btn-auth-social bth-auth btn-signin-shadow btn-auth-flex"
                onClick={() => loginWithGoogle()}
              >
                <img src="/icon/logo_google.svg" alt="google" />
                Continue with Google
              </button>
            </div>
            <div>
              <button
                type="button"
                className="btn btn-dark btn-auth-social bth-auth btn-auth-flex"
                onClick={() => loginWithApple()}
              >
                <img src="/icon/logo_apple.svg" alt="apple" />
                Continue with Apple
              </button>
            </div>

            {error && (
              <div className="error-msg">
                <p>{error}</p>
              </div>
            )}
          </div>
        </Modal.Body>
      </Modal>

      {showFedPlaceholder && (
        <>
          <FederatedPlaceholder
            loading={loading}
            error={error}
            handleShowFedPlaceholder={handleShowFedPlaceholder}
            message={fedType}
          />
        </>
      )}
    </>
  );
};

export default compose(withRouter, withFirebase)(AuthModal);
