import React, { useEffect, useState, useRef, lazy } from "react";
import { withRouter, useParams, useLocation } from "react-router-dom";
import { compose } from "recompose";
import { useSelector, useDispatch } from "react-redux";
import * as ROUTES from "../../constants/routes";
import { withFirebase } from "../../components/Firebase";
import Home from "../Home";
import { fetchOpeningApplication } from "../../Action/applications";
import { GetValue, ClearLocalStorageSessionData } from "../../util/DataRepositry";
import { IS_AUTH, AUTH_STATUS } from "../../redux/constants/auth.constants";
import { getApplicationSteps } from "../../Action/applications";
import { APPLY_JOB_STEP, APPLY_JOB_URL } from "../../redux/constants/jobs.constants";
import { setJobStepsDetail, setTotalJobStepsCount } from "../../redux/actions/jobs.action";
import {
  AUTH_TOKEN,
  FIRST_NAME,
  LAST_NAME,
  EMAIL,
  LOGIN_METHOD,
  SESSION_EXPIRATION,
  SESSION_LIMIT,
  JOB_DESCRIPTION,
  SKILLS,
  INTRODUCTION,
  ADD_PERSONAL_INFO,
  FETCH_DATA,
  KEEP_ME_LOGGED_IN,
  SKILLS_INTRODUCTION,
  COVER_LETTER,
  RESUME,
  VIDEO_INTRODUCTION,
  VIDEO,
} from "../../constants/keys";
import { GET_OPENING, API_ERROR, CAPTCHA_FAILURE, GET_APPLICATION } from "../../constants/firebaseAnalytics";
import { fbSetParams, fbSetEvent } from "../../Action/firebaseAnalytics";
import { errorMessage } from "../../util/Validatios";
import Loadable from "../../components/Loadable";
import LoadingIndicator from "../../components/LoadingIndicator";
import ExternalJob from "./ExternalJob/ExternalJob";

const AddPersonalDetails = Loadable(lazy(() => import("./AddPersonalDetails")));
const JobDescription = Loadable(lazy(() => import("./JobDescription")));
const JobOptionSelection = Loadable(lazy(() => import("./JobOptionSelection")));
const EmailConfirmationModal = Loadable(lazy(() => import("../EmailConfirmationModal")));
const DocumentSubmit = Loadable(lazy(() => import("./DocumentSubmit")));
const JobDetailPlaceHolder = Loadable(lazy(() => import("./JobDetailPlaceHolder")));

const ApplyForJob = (props) => {
  const openingId = props.jobId;
  const setShowApplyForJob = props.setShowApplyForJob;
  const [isFirstUpdate, setIsFirstUpdate] = useState(true);
  const [steps, setSteps] = useState(0);
  const [isShowDesc, setIsShowDesc] = useState(false);
  const [isShowOptSel, setIsShowOptSel] = useState(false);
  const [isShowDocSub, setIsShowDocSub] = useState(false);
  const [isShowPesonal, setIsShowPesonal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingExternalModel, setLoadingExternalModel] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [modalCloseStatus, setModalCloseStatus] = useState("close-right");
  const [modalOpenStatus, setModalOpenStatus] = useState("right");
  const [initApplicationData, setInitApplicationData] = useState({});
  const [userName, setUserName] = useState();
  const [aplTypeVal, setAplTypeVal] = useState("");
  const [applicationData, setApplicationData] = useState({});
  const [savedAppData] = useState({});
  const [isOpeningErr, setIsOpeningErr] = useState(false);
  const [authTokenId, setAuthTokenId] = useState("");
  const [uploadedCoverLtr] = useState([]);
  const [uploadedResume] = useState([]);
  const [checkedItems, setCheckedItems] = useState(["experience", "resume", "coverLetter"]);
  const [activeOption, setActiveOption] = useState(0);
  const [stepsTitles, setStepTitles] = useState([]);
  const [isAuthSesExp, setIsAuthSesExp] = useState(false);
  const [loginMethod, setLoginMethod] = useState("");
  const [pathName, setPathName] = useState("");
  const [selStepDetail, setSelStepDetail] = useState({
    coverLetter: true,
    experience: true,
    resume: true,
  });
  const [jobOpnErrCode, setJobOpnErrCode] = useState(500);
  const [showJobDetailPlaceholder, setJobDetailPlaceholder] = useState(false);
  const [stepsDetails, setStepsDetail] = useState([]);
  const [docsSteps, setDocsSteps] = useState("");
  const [totalStepCount, setTotalStepCount] = useState();
  const [jobDetailPlaceMsg, setJobDetailPlaceMsg] = useState("");
  const [error, setError] = useState("");
  const [externalUrl, setExternalUrl] = useState('');
  const [show, setShow] = useState(false);
  const email = GetValue(EMAIL);
  const applySinginCaptchaRef = useRef(null);
  const dispatch = useDispatch();
  const location = useLocation();

  const reApplyJobStep = useSelector((state) => state.job.applyJobStep);
  const isAppliedSearchJob = useSelector((state) => state.job.isAppliedSearchJob);
  const docSteps = useSelector((state) => state.job.docSteps);
  const resumeStep = useSelector((state) => state.job.resumeStep);
  const isEmailVerify = useSelector((state) => state.auth.isEmailVerify);
  const isAuth = useSelector((state) => state.auth.isAuth);
  const jobStepsDetails = useSelector((state) => state.job.jobStepsDetails);

  const jobEmailVerify = useSelector((state) => state.auth.isEmailVerify);
  const isMobile = window.innerWidth <= 425;
  const handleModalShowDesc = (status) => {
    setIsShowDesc(status);
  };

  const stepHandler = (stepStatus) => {
    setSteps(stepStatus);
  };

  const handleResetStates = async () => {
    await dispatch({
      type: IS_AUTH,
      payload: false,
    });

    await dispatch({
      type: AUTH_STATUS,
      payload: "",
    });

    await dispatch({
      type: APPLY_JOB_STEP,
      payload: 0,
    });

    await dispatch(setJobStepsDetail({}));
    await dispatch(setTotalJobStepsCount(0));
  };

  const handleClearState = () => {
    stepHandler(0);
    handleShowJobDetailPlaceholder(false);
    setJobDetailPlaceMsg("");
  };

  useEffect(() => {
    setPathName(window.location.pathname);
    const loginMethod = GetValue(LOGIN_METHOD);
    setLoginMethod(loginMethod);
  }, []);

  useEffect(() => {
    if (pathName) {
      dispatch({ type: APPLY_JOB_URL, payload: pathName });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathName]);

  useEffect(() => {
    stepHandler(resumeStep)
    setDocsSteps(RESUME)
  }, [resumeStep]);

  useEffect(() => {
    if ((reApplyJobStep || resumeStep) && isFirstUpdate) {
      setIsFirstUpdate(false)
      handleGetApplicAfterAuth();
    }
  }, [reApplyJobStep, resumeStep]);

  useEffect(() => {
    window.addEventListener("beforeunload", function (event) {
      props.history.push(ROUTES.HOME);
      handleClearState();
    });
    document.addEventListener("visibilitychange", function (event) {
      if (document.visibilityState === "prerender" && isMobile) {
        window.history.replaceState({}, "", ROUTES.HOME);
        handleClearState();
      }
    });
    isMobile && window.history.replaceState({}, "", ROUTES.HOME);
    return () => {
      handleResetCaptchaContainer();
    };
  }, []);

  const handleGetApplicAfterAuth = async () => {
    if (reApplyJobStep !== 0 && GetValue(AUTH_TOKEN) && GetValue(EMAIL) && !isEmailVerify) {
      await getOpeningJobDetail();
      await handleGetApplications();
      handleModalOpenState("right");
      renderNextStep();
      handleResetStates();
    } else if (reApplyJobStep !== 0) {
      await getOpeningJobDetail();
      handleModalOpenState("left");
      handleModalShowDesc(true);
      stepHandler(reApplyJobStep);
      handleResetStates();
    }
  };

  const handleFBEvents = (eventName, params) => {
    dispatch(fbSetParams(params));
    dispatch(fbSetEvent(eventName));
  };

  const handleJobDetailPlaceHolder = () => {
    handleLoader(false);
    handleShowJobDetailPlaceholder(false);
    if (reApplyJobStep === 0) {
      stepHandler(JOB_DESCRIPTION);
      handleModalShowDesc(true);
    }
  };

  const handleGetApplications = async () => {
    setLoading(false)
    setIsLoading(true)
    setSteps(FETCH_DATA);
    return await getApplicationSteps(openingId)
      .then((result) => {
        setIsLoading(false)
        handleLoader(false);
        if (result.status === 200) {
          const applicationData = { ...initApplicationData, ...result.data };
          setInitApplicationData(applicationData);

          return true;
        } else {
          handleFBEvents(API_ERROR, {
            status: result.status,
            apiCall: GET_APPLICATION,
          });
          setError(errorMessage.others);
          return false;
        }
      })
      .catch((e) => {
        setIsLoading(false)
        handleLoader(false);
        handleFBEvents(API_ERROR, {
          status: e?.response?.status || 404,
          apiCall: GET_APPLICATION,
        });
        setError(errorMessage.others);
        handleLoader(false);

        return false;
      });
  };

  const handleGetOpeningJobs = () => {
    setLoadingExternalModel(true);
    fetchOpeningApplication(openingId)
      .then((response) => {
        handleResetCaptchaContainer();
        if (response.status === 200) {
          if(response?.data?.opening?.isInternal === false) {
            setExternalUrl(response?.data?.opening?.externalUrl)
            handleShowJobDetailPlaceholder(false);
            setShow(true);
          } else {
            setIsOpeningErr(false);
            setInitApplicationData(response.data);
            if (isMobile) {
              setStepsDetail(response.data.mobileSteps.steps);
              setTotalStepCount(response.data.mobileSteps.totalCount);
              dispatch(setJobStepsDetail(response.data.mobileSteps.steps));
              dispatch(setTotalJobStepsCount(response.data.mobileSteps.totalCount));
            } else {
              setStepsDetail(response.data.desktopSteps.steps);
              setTotalStepCount(response.data.desktopSteps.totalCount);
              dispatch(setJobStepsDetail(response.data.desktopSteps.steps));
              dispatch(setTotalJobStepsCount(response.data.desktopSteps.totalCount));
            }
            //handleModalOpenState("right");
            //handleModalCloseState("close-left");

            handleJobDetailPlaceHolder();
          }
        } else {
          handleFBEvents(API_ERROR, {
            status: response.status,
            apiCall: GET_OPENING,
          });

          setIsOpeningErr(true);
          handleJobDetailPlaceHolder();
        }
      })
      .catch((error) => {
        handleResetCaptchaContainer();
        handleFBEvents(API_ERROR, {
          status: error?.response?.status,
          apiCall: GET_OPENING,
        });

        setJobOpnErrCode(error?.response?.status);
        setIsOpeningErr(true);
        handleJobDetailPlaceHolder();
      });
  };

  const getOpeningJobDetail = async () => {
    handleResetCaptchaContainer();
    handleLoader(true);
    const authToken = await GetValue(AUTH_TOKEN);
    const fName = (await GetValue(FIRST_NAME)) || "";
    const lName = (await GetValue(LAST_NAME)) || "";
    const expiration = await GetValue(SESSION_EXPIRATION);
    const currentTime = new Date().getTime();

    if (!authToken && fName && currentTime + SESSION_LIMIT * 1000 >= expiration) {
      setIsAuthSesExp(true);
      handleGetOpeningJobs();
    } else if (authToken) {
      setIsAuthSesExp(false);
      handleGetOpeningJobs();
    } else {
      handleGetOpeningJobs();
    }

    setAuthTokenId(authToken);
    setUserName(fName + " " + lName);
  };

  const handleSetJobPlaceMsg = async () => {
    const expiration = await GetValue(SESSION_EXPIRATION);
    const email = (await GetValue(EMAIL)) || "";
    const authToken = await GetValue(AUTH_TOKEN);
    const currentTime = new Date().getTime();
    if (email && !authToken && currentTime > expiration && GetValue(KEEP_ME_LOGGED_IN) !== "true") {
      setJobDetailPlaceMsg("the previous session has expired, you'll be asked to re-login");
    } else {
      setJobDetailPlaceMsg("we are retrieving the data about this job, please wait ...");
    }
  };

  useEffect(() => {
      handleModalShowDesc(false);
      handleSetJobPlaceMsg();
      (openingId) &&
        !pathName &&
        pathName !== ROUTES.HOME &&
        handleShowJobDetailPlaceholder(true);
      stepHandler(location?.state?.jobSteps || FETCH_DATA);
      if (openingId && !resumeStep) {
        getOpeningJobDetail();
      }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleModalShowOption = (status) => {
    setIsShowOptSel(status);
  };

  const handleJobReauth = (status) => {
    if (!status) {
      setIsAuthSesExp(false);
    }
  };

  const handleModalShowDocSub = (status) => {
    setIsShowDocSub(status);
  };

  const handleModalShowPesonal = (status) => {
    setIsShowPesonal(status);
  };

  const handleLoader = (status) => {
    setLoading(status);
  };

  const handleModalCloseState = (closeStatus) => {
    setModalCloseStatus(closeStatus);
  };

  const handleModalOpenState = (openStatus) => {
    setModalOpenStatus(openStatus);
  };

  const handleShowJobDetailPlaceholder = (status) => {
    setJobDetailPlaceholder(status);
  };

  const handleModalCancel = () => {
    handleModalCloseState("close-right");
    handleModalShowDesc(false);
    setShowApplyForJob && setShowApplyForJob(false);
    setTimeout(async () => {
      stepHandler(10);

      if (!isAppliedSearchJob) {
        props.history.push(ROUTES.HOME, { isStopAnimation: true });
      }

      if (isAuth && !props?.firebase?.auth?.currentUser?.emailVerified) {
        props.firebase.doSignOut();
        await ClearLocalStorageSessionData();
        setTimeout(() => {
          window.location.replace(ROUTES.HOME);
        }, 200);
      }
    }, 200);
  };

  const handleResetCaptchaContainer = () => {
    if (applySinginCaptchaRef?.current?.innerHTML) {
      applySinginCaptchaRef.current.innerHTML = `<div id="applySignInRecaptcha" className="recaptcha-container"></div>`;
    }
  };

  const renderNextStep = () => {
    const stepIndex = stepsDetails?.findIndex((x) => x.screen === steps);
    const screenName = jobStepsDetails[0]?.screen;
    switch (resumeStep || screenName) {
      case INTRODUCTION:
        handleModalShowOption(true);
        stepHandler(INTRODUCTION);
        break;
      case SKILLS:
      case SKILLS_INTRODUCTION:
      case RESUME:
      case COVER_LETTER:
      case VIDEO_INTRODUCTION:
      case VIDEO:
        handleModalShowDocSub(true);
        stepHandler(SKILLS);
        break;
      default:
        stepHandler(ADD_PERSONAL_INFO);
        break;
    }
    setDocsSteps(screenName);
    setActiveOption(stepsDetails[stepIndex + 1]?.stepNumber);
  };

  const rendersModals = () => {
    switch (steps) {
      case JOB_DESCRIPTION:
        return (
          <JobDescription
            modalName="jobDesc"
            isShowDesc={isShowDesc}
            pathName={pathName}
            handleJobReauth={handleJobReauth}
            userName={userName}
            email={email}
            handleModalShowDesc={handleModalShowDesc}
            stepHandler={stepHandler}
            isOpeningErr={isOpeningErr}
            handleModalCloseState={handleModalCloseState}
            handleModalShowOption={handleModalShowOption}
            handleModalOpenState={handleModalOpenState}
            handleModalShowDocSub={handleModalShowDocSub}
            modalOpenStatus={modalOpenStatus}
            modalCloseStatus={modalCloseStatus}
            handleLoader={handleLoader}
            loading={loading}
            initApplicationData={initApplicationData && initApplicationData}
            isAuthSesExp={isAuthSesExp}
            loginMethod={loginMethod}
            jobOpnErrCode={jobOpnErrCode}
            recaptchaId="applySignInRecaptcha"
            handleResetCaptchaContainer={handleResetCaptchaContainer}
            handleGetApplications={handleGetApplications}
            setActiveOption={setActiveOption}
            setDocsSteps={setDocsSteps}
            stepsDetails={stepsDetails}
            steps={steps}
            renderNextStep={renderNextStep}
            setShowApplyForJob={setShowApplyForJob}
          />
        );
      case INTRODUCTION:
        return (
          <JobOptionSelection
            modalName="jobOption"
            isShowOptSel={isShowOptSel}
            stepHandler={stepHandler}
            pathName={pathName}
            loading={loading}
            handleModalShowDesc={handleModalShowDesc}
            handleModalShowOption={handleModalShowOption}
            handleModalCloseState={handleModalCloseState}
            handleModalOpenState={handleModalOpenState}
            handleModalShowDocSub={handleModalShowDocSub}
            modalOpenStatus={modalOpenStatus}
            modalCloseStatus={modalCloseStatus}
            setAplTypeVal={setAplTypeVal}
            checkedItems={checkedItems}
            setStepTitles={setStepTitles}
            selStepDetail={selStepDetail}
            handleModalCancel={handleModalCancel}
            setInitApplicationData={setInitApplicationData}
            stepsDetails={stepsDetails}
            totalStepCount={totalStepCount}
            steps={steps}
            setDocsSteps={setDocsSteps}
            setActiveOption={setActiveOption}
            error={error}
          />
        );
      case SKILLS:
        return (
          <DocumentSubmit
            modalName="jobDocSub"
            isShowDocSub={isShowDocSub}
            pathName={pathName}
            stepHandler={stepHandler}
            handleLoader={handleLoader}
            loading={loading}
            handleModalShowDocSub={handleModalShowDocSub}
            handleModalShowOption={handleModalShowOption}
            handleModalCloseState={handleModalCloseState}
            handleModalOpenState={handleModalOpenState}
            modalOpenStatus={modalOpenStatus}
            modalCloseStatus={modalCloseStatus}
            initApplicationData={initApplicationData}
            aplTypeVal={aplTypeVal}
            setAplTypeVal={setAplTypeVal}
            applicationData={applicationData}
            handleModalShowPesonal={handleModalShowPesonal}
            uploadedCoverLtr={uploadedCoverLtr}
            uploadedResume={uploadedResume}
            checkedItems={checkedItems.filter((el) => el)}
            activeOption={activeOption}
            setActiveOption={setActiveOption}
            stepsTitles={stepsTitles}
            savedAppData={savedAppData}
            handleModalCancel={handleModalCancel}
            jobOpeningId={openingId}
            setInitApplicationData={setInitApplicationData}
            setApplicationData={setApplicationData}
            stepsDetails={stepsDetails}
            totalStepCount={totalStepCount}
            steps={steps}
            setDocsSteps={setDocsSteps}
            docsSteps={docsSteps}
            handleModalShowDesc={handleModalShowDesc}
          />
        );
      case ADD_PERSONAL_INFO:
        return (
          <AddPersonalDetails
            modalName="jobDocSub"
            isShowPesonal={isShowPesonal}
            pathName={pathName}
            stepHandler={stepHandler}
            handleLoader={handleLoader}
            loading={loading}
            handleModalShowDocSub={handleModalShowDocSub}
            handleModalShowOption={handleModalShowOption}
            handleModalCloseState={handleModalCloseState}
            handleModalOpenState={handleModalOpenState}
            modalOpenStatus={modalOpenStatus}
            modalCloseStatus={modalCloseStatus}
            initApplicationData={initApplicationData && initApplicationData}
            aplTypeVal={aplTypeVal}
            setAplTypeVal={setAplTypeVal}
            applicationData={applicationData}
            handleModalShowPesonal={handleModalShowPesonal}
            authTokenId={authTokenId}
            jobOpeningId={openingId}
            handleModalCancel={handleModalCancel}
            savedAppData={savedAppData}
          />
        );
      case FETCH_DATA:
        return (
          <JobDetailPlaceHolder
            handleShowJobDetailPlaceholder={handleShowJobDetailPlaceholder}
            loadingExternalModel={loadingExternalModel}
            message={jobDetailPlaceMsg}
            loading={loading}
            setIsLoading={setIsLoading}
            showJobDetailPlaceholder={showJobDetailPlaceholder}
            error={jobOpnErrCode}
            handleModalCloseState={handleModalCloseState}
            handleModalOpenState={handleModalOpenState}
            modalOpenStatus={modalOpenStatus}
            modalCloseStatus={modalCloseStatus}
          />
        );
      default:
        return <></>;
    }
  };

  return (
    <>
      <div ref={applySinginCaptchaRef} className="recaptcha-container">
        <div id="applySignInRecaptcha" className="recaptcha-container"></div>
      </div>

      {!isAppliedSearchJob && <Home />}

      {steps && rendersModals()}
      {show && <ExternalJob setShow={setShow} show={show} externalUrl={externalUrl} setShowApplyForJob={props.setShowApplyForJob} />}

      {GetValue(AUTH_TOKEN) && GetValue(EMAIL) && jobEmailVerify && <EmailConfirmationModal />}
    </>
  );
};

export default compose(withRouter, withFirebase)(ApplyForJob);
