import { APP_NAME } from "./keys";
const termsConditionDetail = () => {
  return ` <div class="privacy-desc">
  <p>Last Updated: July 22, 2023</p>
  <div class="privacy-title policy-title">1. Introduction</div>
  <p>Welcome to Deftle, a service operated by Alnier LLC ("Alnier", "we", "us", or "our"), a Delaware company
  headquartered in California, United States. Deftle is a digital job board facilitating the
  connection between job seekers and employers across the United States and Canada. This document,
  referred to as the "Terms of Service" or "ToS", governs your use of our website and the services
  we provide.</p>
  <div class="privacy-title policy-sub-title">1.1 Purpose of the ToS</div>
  <p>The purpose of these Terms of Service is to outline the rules and guidelines for using our
  services and website. They cover your rights and responsibilities when you use Deftle, the
  services we provide to you, and what we expect from you. Please read them carefully.</p>
  <p>These ToS also define our legal relationship with you and protect our rights and the rights of
  our users and visitors. They help ensure the fairness, safety, and security of our platform for
  everyone.</p>
  <div class="privacy-title policy-sub-title">1.2 Acceptance of ToS</div>
  <p>By accessing or using the services we provide on Deftle, you confirm that you have read,
  understood, and agreed to be bound by these ToS, whether you are a "Visitor" (which means you're
  just browsing our website) or you are a "User" (which means you have registered with us). If you
  do not agree with these terms, or if you lack the authority to agree to them, then you should
  not use our services or our website. Non-compliance with these terms constitutes a material
  breach of this Agreement and amounts to unauthorized use of the Site.</p>
  <p>In accepting these ToS, you also acknowledge that our Privacy Policy, which informs you about
  our data practices, is incorporated into these ToS by reference. The Privacy Policy, as updated
  from time to time, is available <a
      target="_blank"
      href="/privacyPolicy.html"
      rel="noreferrer"
    >here</a></p>


  <div class="privacy-title policy-title">2. Definitions</div>
  <div class="privacy-title policy-sub-title">2.1 Key terms used throughout the document</div>
  <p>"Deftle" refers to the website available at deftle.com, operated by Alnier LLC including its
  subsidiaries and affiliates.</p>
  <p>"Alnier", "Company", "we", "us", or "our" refer to Alnier LLC, a Delaware company, the owner and
  operator of Deftle.</p>
  <p>"Website", "Service" or "Services" refers to all services provided by Deftle, including but not
    limited to the ability to post jobs, review candidates' applications, search for candidates,
    receive job applications (for employers), search for jobs, apply to jobs, upload resumes,
    contact employers (for job seekers), and all other functionality and features as described on
    the Deftle website.</p>
  <p>"User" refers to anyone who has registered an account with Deftle. This includes both "Job
  Seekers", who are individuals using Deftle to search for and apply to jobs, and "Employers", who
  are individuals or companies using Deftle to post job vacancies and review applications. Also,
  "Users" refers to anyone accessing or browsing Deftle without having registered an account.</p>
  <p>"Content" refers to text, graphics, images, music, software, audio, video, information,
  documents, or other materials that are posted, generated, provided, or otherwise made available
  on or through Deftle.</p>
  <p>"User Content" refers to all content that is posted, uploaded, published, or submitted to Deftle
  by Users. For example, this may include job postings, resumes, messages, and other similar
  materials.</p>
  <p>"You" or "Your" refers to any Visitor or User of Deftle.</p>
  <p>"ToS" refers to these Terms of Service.</p>
  <p>"Privacy Policy" refers to Deflte's policy which describes how we collect, use, and disclose
  information from our Users, as linked on the Deftle website.</p>
  <p>Please note that these definitions are used throughout this ToS for clarity and ease of reading.
  They do not affect the meaning or interpretation of this ToS.</p>
  <div class="privacy-title policy-title">3. Eligibility</div>
  <div class="privacy-title policy-sub-title">3.1 User age and competence requirements</div>
  <p>Users must be at least 18 years of age, or the age of majority in their jurisdiction, whichever
  is higher. By using our Services, you affirm that you are at least 18 years old, or an
  emancipated minor, or possess legal parental or guardian consent, and are fully able and
  competent to enter into the terms, conditions, obligations, affirmations, representations, and
  warranties set forth in these ToS, and to abide by and comply with these ToS.</p>
  <div class="privacy-title policy-sub-title">3.2 Employer legitimacy requirements</div>
  <p>Employers must be legal entities with the authority to form a binding contract. By registering
  as an employer, you affirm that you are acting on behalf of a legitimate business or
  organization and that your business or organization has the legal capacity to enter into
  contracts. We reserve the right to request additional information or documentation verifying the
  legitimacy of your business or organization at any time, and to refuse service or access to the
  Site to any employer for any reason.</p>
  <p>Please note that you may not use our services if doing so would be prohibited by any law or
  regulation applicable to you. If we learn that any user does not meet these requirements, we
  reserve the right to terminate their access to the services.</p>
  <div class="privacy-title policy-title">4 Description of Services</div>
  <div class="privacy-title policy-sub-title">4.1 Description of Services for Job Seekers</div>
  <p>The Deftle platform offers various services to support and empower job seekers in their job
  search and career development efforts. These services include, but are not limited to, the
  following:</p>
  <p>a. Job Search: Deftle allows job seekers to search for jobs using various parameters such as job
  title, location, job type, industry, salary, and more. The job search feature is designed to
  help job seekers find the opportunities that best match their qualifications and interests.</p>
  <p>b. Job Application: Deftle provides an application submission feature, where job seekers can
  apply directly to job postings. This feature simplifies the application process, allowing job
  seekers to submit their applications quickly and efficiently.</p>
  <p>c. Resume Upload: Job seekers are allowed to upload their resumes to the platform. This feature
  enables employers to review the job seeker's skills, qualifications, and experience,
  facilitating their recruitment process.</p>
  <p>d. Contact Employers: The platform provides a feature allowing job seekers to directly contact
  employers. This feature is designed to encourage communication and engagement between job
  seekers and employers.</p>
  <p>e. Employment and Career Services: From time to time, Deftle may reach out to job seekers with
  various services and information to assist them in their employment and career journey. These
  services may include job recommendations, career advice, industry trends, networking
  opportunities, and other employment-related information.</p>
  <p>Please note that these services are provided for informational purposes only and Deftle does not
  guarantee job placement or employment as a result of using these services. Additionally, while
  we strive to provide accurate and up-to-date job listings, we do not guarantee the validity or
  accuracy of job postings, nor do we endorse any of the jobs or employers listed on our platform.
  Job seekers are responsible for verifying the legitimacy and suitability of job postings and
  employers.</p>
  <div class="privacy-title policy-sub-title">4.2 Description of Services for Employers</div>
  Deftle provides a wide range of services designed to assist employers in finding suitable
  candidates and managing their hiring process. These services include, but are not limited to:
  <p>a. Job Postings: Employers can post job vacancies on Deftle, providing key information such as
  the job title, description, location, salary, and other pertinent details to attract suitable
  candidates.</p>
  <p>b. Promotion of Job Posts: Deftle offers promotional services to help employers enhance the
  visibility of their job postings.</p>
  <p>c. Receiving Applications: Employers can receive job applications directly through the platform,
  allowing for a streamlined, efficient process in managing responses to their job postings.</p>
  <p>d. Candidate Review: Employers have the opportunity to review applications and resumes submitted
  by job seekers, enabling them to identify potential matches for their job postings.</p>
  <p>e. Candidate Search: Deftle provides a feature that allows employers to proactively search for
  candidates based on specified criteria such as skills, experience, location, and more.</p>
  <p>f. Contacting Candidates: Employers can use Deftle to directly contact job seekers who have
  applied to their postings or otherwise expressed interest.</p>
  <p>These services are intended to support the recruitment and hiring process, but do not guarantee
  the placement of suitable candidates or successful hires. Employers are responsible for the
  content of their job postings and for their own hiring decisions.</p>
  <p>Employers are also reminded to respect the privacy of job seekers by using contact and personal
  information obtained through Deftle only for legitimate employment-related purposes in
  accordance with our Privacy Policy and applicable laws.</p>
  <div class="privacy-title policy-title">5 User Accounts and Account Security</div>
  <div class="privacy-title policy-sub-title">5.1 Requirement of User Accounts for Certain Services</div>
  <p>For users to access certain features and services offered by Deftle, including but not limited
  to job posting, application submission, resume uploads, and direct communication with other
  users, it is required to create a user account. An account serves as your identity on our
  platform and allows us to tailor the services to your needs and preferences. Some features may
  still be accessible without an account.</p>
  <p>The requirement of an account is primarily intended to protect your identity and the platform
  from misuse, maintain service integrity, and ensure a personalized and seamless user experience.
  Further details about the account creation process and requirements are outlined in section 5.2
  of this Terms of Service.</p>
  <div class="privacy-title policy-sub-title">5.2 Account Creation Process and Requirements</div>
  <p>Creating an account on Deftle entails the provision of accurate, current, and complete
  information as prompted by our registration forms. For a more personalized user experience, you
  may be required to furnish details including but not limited to your full name, email address,
  professional details, and a unique password. Employers may be additionally asked for
  company-specific details to establish authenticity and credibility.</p>
  <p>Users should ensure that the information provided is not misleading, defamatory, harmful, or
  offensive in any way. The process also includes your agreement to maintain and promptly update
  such information to keep it accurate, current, and complete at all times. Deftle reserves the
  right to suspend or terminate accounts with information that is outdated, incorrect, or believed
  to violate our Terms of Service.</p>
  <p>It is crucial that users maintain the confidentiality of their account credentials. The sharing
  of these credentials with others is not advised, as it could lead to unauthorized access. Users
  will be held responsible for all activities that occur under their account, as detailed in
  section 5.3 of this Terms of Service.</p>
  <div class="privacy-title policy-sub-title">5.3 User Responsibility for Account Security</div>
  <p>As a user of Deftle, you are exclusively responsible for maintaining the confidentiality of your
  account information, including your username and password. You are also accountable for all
  activities that occur under your account and are expected to immediately notify Deftle of any
  unauthorized access or use of your account or any other breach of security.</p>
  <p>To ensure your account's security, it's recommended to use "strong" passwords (passwords that
  include a combination of upper and lower case letters, numbers, and symbols) and protect them
  from disclosure. Also, always ensure that you log out from your account at the end of each
  session, particularly when using a shared computer.</p>
  <p>Deftle cannot and will not be liable for any loss, damage, or other liabilities arising from
  your failure to comply with the requirements of this section. Your account security is primarily
  your own responsibility, and by using Deftle, you accept the obligations as stated herein.</p>
  <div class="privacy-title policy-sub-title">5.4 Reporting Unauthorized Use of Accounts</div>
  <p>If you suspect or become aware of any unauthorized use of your account or any other breach of
  security, including loss, theft, or unauthorized disclosure of your password or account
  information, you must immediately notify Deftle via the contact information provided in section
  18. You should also change your password immediately through the account settings on Deftle.</p>
  <p>Deftle shall not be held liable for any loss or damage resulting from unauthorized access to
  your account unless such access was due to an error on the part of Deftle. You acknowledge that
  you are responsible for any actions taken under your account, including any fees or charges
  incurred, content posted, statements made, or agreements entered into.</p>
  <p>Deftle will fully cooperate with any law enforcement authorities or court order requesting or
  directing us to disclose the identity of anyone posting, publishing, or otherwise making
  available any such information or materials.</p>
  <div class="privacy-title policy-title">6 User Obligations</div>
  <div class="privacy-title policy-sub-title">6.1 Adherence to Applicable Laws and Regulations</div>
  <p>All users of Deftle, whether visitors, job seekers or employers, must conduct their activities
  on the platform in compliance with all applicable laws and regulations. This includes, but is
  not limited to, employment laws, privacy laws, intellectual property laws, anti-discrimination
  laws, minimum wage laws, wage transparency laws, governmental record-keeping requirements, any
  laws related to voluntary self-identification questions, the Fair Credit Reporting Act and
  similar state statutes, and any other relevant laws in the jurisdictions of the United States
  and Canada. This includes offering alternative screening methods in accordance with the
  Americans with Disabilities Act or similar laws.</p>
  <p>Users are responsible for their own actions and the consequences thereof on Deftle and must
  understand and follow the law as it applies to their usage of our services. Deftle does not take
  responsibility for any legal breaches caused by users' actions or content on the site.</p>
  <p>If a user's conduct is found to be in violation of any applicable laws or regulations, Deftle
  reserves the right to take appropriate action, which may include removing the offending content,
  suspending or terminating the user's account, and reporting the matter to relevant authorities.</p>
  <p>Please be aware that this provision applies regardless of the nature of the law or regulation,
  including those that may not be explicitly stated in this document. We advise you to seek legal
  counsel if you have questions or concerns about the legal implications of your use of Deftle.</p>
  <div class="privacy-title policy-sub-title">6.2 Prohibition of Harmful Conduct or Materials</div>
  <p>Users are strictly prohibited from engaging in any conduct or sharing any materials that could
  be deemed harmful when using Deftle's services. This includes, but is not limited to:</p>
  <span style="text-align: left">
  <li>Engaging in any form of harassment, discrimination, or abuse towards other users.</li>
  <li>Posting, sharing, or distributing content that is illegal, defamatory, obscene, threatening,
  invasive of privacy or publicity rights, abusive, inflammatory, or otherwise objectionable or
  injurious to third parties.</li>
  <li>Circumventing any security feature, access controls, or usage limits of the service, including
  keyword searches or profile view caps.</li>
  <li>Uploading, transmitting, or distributing any form of harmful content or software such as
  viruses, trojans, or any other malicious technology that may damage, interfere with,
  surreptitiously intercept, or expropriate any system, data, or personal information.</li>
  <li>Altering or interfering with the services or their appearance, such as by adding elements to the
  services or removing, covering, or obscuring an advertisement included on the services.</li>
  <li>Deep linking to our services for any purpose not explicitly authorized.</li>
  <li>Claiming affiliation with or endorsement by Deftle without our explicit consent.</li>
  <li>Creating or using any tools, applications, scripts, robots, or any other methods to scrape the
  web site or otherwise copy data from the services.</li>
  <li>Creating a false identity, providing false information, or otherwise attempting to mislead
  others about your identity or the origin of any content, message, or other communication.</li>
  <li>Disturbing the operation of, or placing an unreasonable burden on, the services (e.g., spam,
  denial of service attack, or sending mass messages without specific authorization).</li>
  <li>Manipulating or exploiting any aspect of our services for any illegal or unauthorized purpose.</li>
  <li>Attempting to access to another user's account without authorization or attempting to enable such unauthorized access.</li>
  <li>Sharing information that you do not have permission to share, including confidential information
  belonging to others.</li>
  <li>Renting, leasing, selling, or profiting from our services, without Deftle's consent.</li>
  <li>Utilizing bots or other automated methods to access the services, or monitoring the services'
  availability, performance, or functionality.</li>
  <li>Adopting methods to simulate the appearance or function of the services by usage of mirrors or
  frames.</li>
  </span>
  <br/>
  <p>Violation of these guidelines may result in immediate termination of the user's account and
  further legal action. Deftle reserves the right, but is not obligated, to monitor all conduct
  and content posted on its platform, and to remove any content at its sole discretion.</p>
  <div class="privacy-title policy-sub-title">6.3 Accuracy of Provided Information</div>
  <p>At Deftle, we emphasize the necessity of truthfulness, completeness, and accuracy in all
  user-provided information. As a user, you pledge that all data you supply, either during the
  account creation process or when utilizing our services, is true, precise, current, and complete
  to the best of your knowledge. This pertains to, but is not limited to, information provided in
  job postings, job applications, resumes, and profile data.</p>
  <p>The misuse or misrepresentation of personal information, including but not limited to, the use
  of false identities or impersonation of another individual, organization, or entity is strictly
  prohibited. You also agree not to provide any false or misleading statements regarding your
  qualifications, experience, skills, or other pertinent details, especially when applying for
  jobs or posting job offers.</p>
  <p>In the event of any change in the information you have provided to us, it is your responsibility
  to promptly update your account to reflect these changes.</p>
  <p>Please be aware that Deftle reserves the right to verify the accuracy of the information
  provided by its users. Misleading, inaccurate, or false information may lead to account
  suspension, termination, or legal action, as deemed appropriate by Deftle.</p>
  <p>By using Deftle's services, you acknowledge and agree to these requirements. Any violation of
  this policy may lead to your inability to access our services or other potential legal
  ramifications.</p>
  <p>In addition each user carries their individual responsibility to verify the authenticity and
  accuracy of the information shared by other users, whether they are job applicants or employers.
  Deftle does not bear the responsibility of verifying or validating the truthfulness,
  completeness, or accuracy of user-submitted data.</p>
  <p>Therefore, we highly recommend that users exercise due diligence and appropriate caution when
  dealing with other users on our platform. Please note that Deftle does not assume any liability
  for any loss, damage, or inconvenience that arises due to reliance on incorrect, incomplete, or
  misleading information provided by another user. It is the user's responsibility to conduct
  sufficient verification or seek professional advice where appropriate.</p>
  <div class="privacy-title policy-title">7 Job Posting and Application Guidelines</div>
  <div class="privacy-title policy-sub-title">7.1 Guidelines and Requirements for Job Postings by Employers</div>
  <p>Employers agree to adhere to the following guidelines and requirements when posting job
  opportunities on Deftle:</p>
  <p>a) All job postings must comply with all applicable local, state, national, and international
  laws, including but not limited to laws relating to labor and employment, equal employment
  opportunity and employment eligibility requirements, data privacy, data access and use, and
  intellectual property.</p>
  <p>b) Job postings must accurately represent the responsibilities, qualifications, and working
  conditions associated with the position. They must not contain any misleading, deceptive, or
  false statements or information.</p>
  <p>c) Employers are not allowed to post job listings that require job seekers to pay fees or
  purchase equipment, supplies, or services as a condition of employment.</p>
  <p>d) Job postings must not promote or endorse illegal activities or conduct that is
  discriminatory, harassing, or otherwise objectionable.</p>
  <p>e) Employers are responsible for the content of their job postings and must ensure that they do
  not infringe upon the intellectual property rights or other rights of third parties.</p>
  <p>f) Deftle reserves the right to modify, delete, or not publish any job posting for any or no
  reason.</p>
  <p>g) Employers bear the responsibility for verifying the identity, academic credentials, work
  history, experience, and skillset of potential candidates. Deftle does not undertake such
  verification and cannot be held liable for any misrepresentations or inaccuracies.<p>
  <p style="font-weight: bold;">By posting a job on Deftle, employers represent and warrant that they have the necessary rights,
  licenses, and permissions to post such content, and that such content complies with all
  applicable laws and regulations. Violation of these guidelines may result in immediate
  termination of the employer's account and access to Deftle's services without notice.<p>
  <br/>
  <div class="privacy-title policy-sub-title">7.2 Guidelines and Requirements for Job Applications by Job Seekers</div>
  <p>Job seekers agree to comply with the following guidelines and requirements when applying for job
  opportunities on Deftle:</p>
  <p>a) All applications and associated information submitted by job seekers must be accurate,
  truthful, and up-to-date. Job seekers must not submit misleading, false, or deceptive
  information.</p>
  <p>b) Job seekers must comply with all application instructions provided in the job posting.
  Failure to comply with these instructions may result in the application not being considered by
  the employer.</p>
  <p>c) Job seekers are not allowed to apply for jobs using identities other than their own or
  provide information or documentation that is fraudulent, stolen, or otherwise obtained
  illegitimately.</p>
  <p>d) Job seekers must not use the application process to promote or endorse illegal activities,
  products, services, or conduct that is discriminatory, harassing, or otherwise objectionable.</p>
  <p>e) Job seekers are responsible for ensuring their applications and any user content they provide
  do not infringe upon the intellectual property rights or other rights of third parties.</p>
  <p>f) Deftle reserves the right to remove or not transmit any application that it deems, in its
  sole discretion, does not comply with these guidelines or is otherwise inappropriate for the
  website.</p>
  <p>g) Job seekers acknowledge that Employers bear the exclusive responsibility for their hiring
  decisions. Deftle does not participate in or influence these decisions, nor is it liable for the
  outcomes of such decisions.</p>
  <p>h) It falls under the responsibility of Job Seekers to confirm the legitimacy of Employers and
  the validity of job offers presented on Deftle. Deftle does not guarantee the identity of any
  Employer or the authenticity of any job offer and disclaims all liability associated with such
  verification.</p>
 <p style="font-weight: bold;">
  By submitting a job application on Deftle, job seekers represent and warrant that they have the
  necessary rights, licenses, and permissions to submit such content, and that such content
  complies with all applicable laws and regulations. Violation of these guidelines may result in
  immediate termination of the job seeker's account and access to Deftle's services without
  notice.</p>
  <br/>
  <div class="privacy-title policy-title">8 User Content</div>
  <div class="privacy-title policy-sub-title">8.1 Definition and Treatment of User-Generated Content</div>
  <p>For the purposes of these Terms of Service, "User-Generated Content" encompasses all
  information, data, text, images, files, links, software, messages, communications, usage trends,
  user behavior data (including, but not limited to, clicks, scrolls, views, frequency, and time
  spent on Deftle's platform), and any other material or content that is designed, posted, shared,
  submitted, or otherwise provided by users on Deftle, whether publicly posted or privately
  transmitted.</p>
  <p>Deftle serves as a passive conduit for User-Generated Content and does not assume responsibility
  or liability for the content or information that users post, upload, or otherwise share on or
  through the site. This includes, but is not limited to, any errors or omissions in any content,
  or any loss or damage of any kind incurred as a result of the use of any content posted,
  emailed, transmitted, or otherwise made available on or through the website.</p>
  <p>User-Generated Content may include links to third-party websites or services. Deftle is not
  responsible for, does not endorse, and makes no representations or warranties regarding these
  third-party websites or services, including the content, accuracy, or functionality of such
  websites or services. If you decide to access these third-party websites or services, you do so
  at your own risk.</p>
  <p>While Deftle does not and cannot review all User-Generated Content, we reserve the right, but
  are not obligated, to monitor, edit, move, delete, or refuse to post any content, in whole or in
  part, at our sole discretion, without notice at any time for any reason. This includes, but is
  not limited to, content that violates these Terms of Service, applicable law, or may be harmful,
  offensive, or misleading to others.</p>
  <p>By posting or submitting User-Generated Content on or to our site, regardless of the form of
  communication, you represent and warrant that you own or have the necessary rights and
  permissions to use and share the User-Generated Content, and that the posting of your content on
  or through our site does not violate the privacy rights, publicity rights, copyrights, contract
  rights, or any other rights of any person or entity.</p>
  <div class="privacy-title policy-sub-title">8.2 Content Ownership, Licensing, and Permissions</div>
  <p>Unless otherwise indicated, all User-Generated Content remains the intellectual property of the
  original owner. By posting, submitting, or sharing User-Generated Content on or through Deftle,
  you grant Deftle a non-exclusive, royalty-free, fully paid-up, worldwide, perpetual,
  irrevocable, transferable license, with the right to sublicense through multiple tiers, to
  access, use, reproduce, distribute, store, transmit, publicly display and perform, modify, and
  create derivative works from your User-Generated Content in any and all media or distribution
  methods, now known or later developed, including but not limited to commercial purposes and the
  utilization in artificial intelligence ("AI") training and tools.</p>
  <p>Moreover, you expressly grant Deftle comprehensive permissions to store and analyze user
  behavior including, but not limited to, clicks, views, scrolls, frequency and duration of use of
  Deftle's platform for purposes including improving user experience and developing new services.</p>
  <p>Deftle retains the right to make your User-Generated Content available for syndication,
  broadcast, distribution, or publication by other companies, organizations, or individuals who
  collaborate with Deftle. Deftle may also remove metadata associated with your User-Generated
  Content, and you irrevocably waive any claims and assertions of moral rights or attribution
  concerning your User-Generated Content.</p>
  <p>This license persists even if you cease to use our services, primarily due to the interconnected
  nature of users in Deftle, where your User-Generated Content may remain integral to the
  collective content, appearances, and branding. Any feedback, comments, or suggestions you may
  provide about Deftle is entirely voluntary, and we are free to use such feedback, comments, or
  suggestions as we deem appropriate and without any obligation to you.</p>
  <p>You represent and warrant that you possess all the rights, power, and authority necessary to
  grant the rights granted herein to any User-Generated Content that you submit; your
  User-Generated Content is accurate and truthful; and the use of your User-Generated Content does
  not violate these Terms of Service and will not cause injury to any person or entity.</p>
  <div class="privacy-title policy-sub-title">8.3 Prohibited Content</div>
  <p>In using Deftle's services, you agree not to post, distribute, or facilitate the distribution of
  any User-Generated Content — including text, communications, software, images, sounds, data, or
  other information — that:</p>
  <p>a. is unlawful, threatening, abusive, harassing, defamatory, libelous, deceptive, fraudulent,
  invasive of another's privacy, tortious, obscene, or that contains explicit or graphic
  descriptions, or accounts of, sexual acts;</p>
  <p>b. victimizes, harasses, degrades, or intimidates an individual or group of individuals on the
  basis of religion, gender, sexual orientation, race, ethnicity, age, or disability;</p>
  <p>c. infringes on any patent, trademark, trade secret, copyright, right of publicity, or other
  proprietary rights of any party;</p>
  <p>d. constitutes unauthorized or unsolicited advertising, junk or bulk email (also known as
  "spamming"), chain letters, any other form of unauthorized solicitation, or any form of lottery
  or gambling;</p>
  <p>e. contains software viruses or any other computer code, files, or programs that are designed or
  intended to disrupt, damage, or limit the functioning of any software, hardware, or
  telecommunications equipment or to damage or obtain unauthorized access to any data or other
  information of any third party;</p>
  <p>f. impersonates any person or entity, including any of our employees or representatives;</p>
  <p>g. discloses confidential, sensitive, or non-public information about individuals or entities
  without proper authorization.</p>
  <p>We neither endorse nor assume any liability for the contents of any User-Generated Content. We
  generally do not pre-screen, monitor, or edit User-Generated Content. However, we and our agents
  have the right at their sole discretion to remove any content that, in our judgment, does not
  comply with these Terms of Service and any other rules of user conduct for our site, or is
  otherwise harmful, objectionable, or inaccurate. We are not responsible for any failure or delay
  in removing such content.</p>
  <div class="privacy-title policy-sub-title">8.4 Removal or Alteration of Content</div>
  <p>Deftle reserves the right, but does not assume the obligation, to:</p>
  <p>a. Review, monitor, or remove User-Generated Content at our discretion and without notice, at
  any time for any reason, including but not limited to, content that we determine in our sole
  discretion violates these Terms of Service, or could be offensive, illegal, or violate the
  rights, harm, or threaten the safety of users or others;</p>
  <p>b. Investigate and take appropriate legal action against anyone who, in Deftle's sole
  discretion, violates these Terms of Service, including but not limited to, removing the
  offending communication from the Services and terminating the user's account;</p>
  <p>c. Access, preserve and disclose your account information and Content if required to do so by
  law or in a good faith belief that such access preservation or disclosure is reasonably
  necessary to: (i) comply with legal process; (ii) enforce these Terms of Service; (iii) respond
  to claims that any Content violates the rights of third parties; (iv) respond to your requests
  for customer service; or (v) protect the rights, property or personal safety of Deftle, its
  users and the public.</p>
  <p>d. Edit or alter any User-Generated Content submitted to Deftle or displayed on the Site at any
  time for any reason.</p>
  <p>You acknowledge and agree that Deftle may preserve content and may also disclose content if
  required to do so by law or in the good faith belief that such preservation or disclosure is
  reasonably necessary to: (a) comply with legal process; (b) enforce these Terms; (c) respond to
  claims that any content violates the rights of third parties; or (d) protect the rights,
  property, or personal safety of Deftle, its users, and the public.</p>


  <div class="privacy-title policy-title">9 Intellectual Property Rights</div>
  <p>The Deftle Website, its original content, features, functionality, and the trademarks, service
  marks, logos, and domain names associated are owned by Deftle and are protected by international
  copyright, trademark, patent, trade secret, and other intellectual property or proprietary
  rights laws. These Terms do not grant you any rights to use Deftle's trademarks, logos, domain
  names, or other brand features.</p>

  <div class="privacy-title policy-sub-title">9.1 Ownership of the Website and Associated Rights</div>
  <p>Deftle is the sole and exclusive owner of the Website, which includes but is not limited to, all
  content, design elements, images, text, graphics, logos, and the layout. All these components
  are protected by U.S. and international copyright, patent, and trademark laws, international
  conventions, and other laws protecting intellectual property and related proprietary rights. You
  agree not to infringe upon, misappropriate, dilute or otherwise violate the intellectual
  property rights or goodwill of Deftle. Unauthorized use of any of Deftle's intellectual property
  rights is strictly prohibited and may result in legal action.<p>
  <div class="privacy-title policy-sub-title">9.2 Restrictions on Use of the Website's Content and Branding</div>
  <p>All content and materials on the Deftle website including, but not limited to, text, graphics,
  logos, icons, images, audio clips, downloads, interfaces, code, software, and the selection and
  arrangement thereof, are the exclusive property of Deftle and/or its content providers and are
  protected by copyright, trademark and other applicable U.S. and foreign laws.</p>
  <p>The software that powers the Deftle platform is owned by Deftle. Users are prohibited from
  copying, reverse engineering, or attempting to derive source code from the platform, unless
  explicitly stated as open source by Deftle. Unauthorized attempts to do so may result in legal
  actions.</p>
  <p>You may access, copy, download and print the material contained on the site for your personal
  and non-commercial use, provided you do not modify or delete any copyright, trademark or other
  proprietary notice that appears on the material you access, copy, download or print. Any other
  use of content on the site, including but not limited to the modification, distribution,
  transmission, performance, broadcast, publication, licensing, reverse engineering, transfer or
  sale of, or the creation of derivative works from, any material, information, software, products
  or services obtained from the site, is expressly prohibited.</p>
  <p>Deftle respects the intellectual property rights of others. If you believe that your work has
  been copied in a way that constitutes copyright infringement, please contact us.</p>
  <p>In addition, you may not use any meta tags or any other "hidden text" utilizing Deftle's name or
  trademarks without our express written consent. You may not use our logo or other proprietary
  graphics to link to our site without our express written permission. Further, you may not frame
  any of our content, deep-link to our site, trespass or scrape our site with automated agents
  without prior express permission.</p>
  <div class="privacy-title policy-title">10 Limitations and Modifications to Service</div>
  <div class="privacy-title policy-sub-title">10.1 Rights of the Website to Limit or Modify Service</div>
  <p>Deftle reserves the unilateral right, at any time and from time to time, to amend, alter,
  discontinue, either temporarily or permanently, the services (or any part thereof) with or
  without prior notice. The scope and nature of the services provided may vary based on
  geographical location or as determined by Deftle in its sole discretion. Deftle may also modify
  or adapt the services for the purpose of conducting usability and functional tests.</p>
  <p>You agree that Deftle shall not be held accountable to you or to any third party for any such
  modification, suspension, or discontinuation of the services.</p>
  <p>We strive to provide the best possible service, but there are certain aspects that we cannot
  guarantee. Deftle makes no warranties, express or implied, about the services to the fullest
  extent permissible by law. The services are provided "as is," and any warranties of
  merchantability, fitness for a particular purpose, and non-infringement are hereby disclaimed.
  No advice or information, whether oral or written, that you acquire from Deftle shall constitute
  any warranty not explicitly articulated in these Terms of Service.</p>
  <p>Deftle reserves further rights to limit, suspend, or restrict access to the site or usage of the
  services, in whole or in part, due to potential or actual violations of these Terms of Service,
  suspected fraudulent, abusive, or illegal activity, for service improvements or changes that may
  hinder the prior way of using the services, or for any other reason within Deftle's sole and
  absolute discretion.</p>
  <p>Certain jurisdictions may stipulate warranties such as the implied warranty of merchantability,
  fitness for a particular purpose and non-infringement. Insofar as the law permits, we exclude
  all such warranties.</p>
  <div class="privacy-title policy-title">11 Termination of Services</div>
  <p>Deftle reserves the right to suspend
  or terminate your access to and use of the services, at its sole discretion, at any time and
  without notice to you. You may discontinue your use of the services at any time. This section
  should be read in conjunction with section 11.1, which outlines the grounds for termination of
  user accounts.</p>
  <p>Please note that upon termination of your account, you will lose all access to the services and
  any data, information, or content associated with your account. Deftle will not be liable to you
  or any third party for any termination of your access to the services or deletion of your
  information.</p>
  <p>It is important to us that you understand your responsibilities and the limitations of our
  services as outlined in these Terms of Service. If for any reason you do not agree or cannot
  comply with these terms, you should cease using our services immediately.</p>
  <div class="privacy-title policy-sub-title">11.1 Grounds for Termination of User Accounts</div>
  <p>Deftle reserves the right, in its sole discretion, to suspend or terminate your account and
  refuse any and all current or future use of the services (or any portion thereof) at any time
  for the following reasons:</p>
  <p>a) If you breach any term of these Terms of Service or any other agreement with Deftle;</p>
  <p>b) If Deftle is unable to verify or authenticate any information you provide to us;</p>
  <p>c) If you fail to promptly pay amounts due to us;</p>
  <p>d) If your actions may cause legal liability for you, Deftle, or other users;</p>
  <p>e) If we suspect that you have engaged in fraudulent activity, such as impersonating another
  person or entity;</p>
  <p>f) If you share content that is offensive, violent, or inappropriate, or engage in disruptive or
  harmful behavior.</p>
  <p>Upon termination of your account, your right to use the services will immediately cease. If you
  wish to terminate your account, you may simply discontinue using the services. However, even
  after your right to use the services is terminated, these Terms of Service will remain
  enforceable against you and unpaid amounts you owe to Deftle will remain due.</p>
  <div class="privacy-title policy-title">12 Disclaimers</div>

  <div class="privacy-title policy-sub-title">12.1 Disclaimers Regarding Site Content and Service Provision</div>
  <p>Deftle does not represent or guarantee that the website and services will be free from errors or
  omissions, that defects will be corrected, or that the website or the server that makes it
  available are free of viruses or other harmful components. Deftle does not make any warranties
  or representations regarding the use of the content on the website in terms of their
  completeness, correctness, accuracy, adequacy, usefulness, timeliness, reliability, or
  otherwise.</p>
  <p>You understand and agree that your use of the website and the services is at your own risk, and
  that the services are provided on an "as is" and "as available" basis. Deftle expressly
  disclaims all warranties, whether express or implied, including but not limited to the implied
  warranties of merchantability, fitness for a particular purpose, title, and non-infringement.</p>
  <p>Deftle does not guarantee the accuracy or timeliness of information available from the services.
  You acknowledge that computer and telecommunications systems are not fault-free and occasional
  periods of downtime occur. We do not guarantee the services will be uninterrupted, timely,
  secure, or error-free, or that content loss won't occur.</p>
  <p>Your use of any information or materials on this website is entirely at your own risk, for which
  Deftle shall not be liable. It shall be your own responsibility to ensure that any products,
  services or information available through this website meet your specific requirements. Deftle
  does not endorse, warrant or guarantee any products or services offered or provided by or on
  behalf of third parties on or through the website. Deftle is not a party to, and does not
  monitor, any transaction between users and third-party providers of products or services.</p>
  <div class="privacy-title policy-sub-title">12.2 Disclaimer Regarding Third-Party Links or Services</div>
  <p>Our website, Deftle, may contain links to other websites, applications or services operated by
  third parties (collectively, "Third-Party Sites"). Such Third-Party Sites are not under the
  control of Deftle. Deftle provides these links for your convenience, but we do not review,
  approve, monitor, endorse, warrant, or make any representations concerning Third-Party Sites, or
  their products, services, or content.</p>
  <p>Your use of these Third-Party Sites is at your own risk, and may be subject to their respective
  terms of service, privacy policies, or other rules. Deftle is not responsible for any losses,
  damages, or other liabilities incurred as a result of your use of any Third-Party Sites.</p>
  <p>In no event shall any reference to any third party, third party website or third party product
  or service be construed as an approval or endorsement by Deftle of that third party, third party
  website or of any product or service provided by a third party.</p>
  <div class="privacy-title policy-title">13 Limitations of Liability</div>
  <div class="privacy-title policy-sub-title">13.1 Limitations on Deftle's Liability for Various Types of Damages or Harm</div>
  <p>TO THE MAXIMUM EXTENT PERMITTED BY LAW, IN NO EVENT SHALL DEFTLE, ITS AFFILIATES, DIRECTORS,
  EMPLOYEES OR ITS LICENSORS BE LIABLE FOR ANY DIRECT, INDIRECT, PUNITIVE, INCIDENTAL, SPECIAL,
  CONSEQUENTIAL OR EXEMPLARY DAMAGES, INCLUDING WITHOUT LIMITATION DAMAGES FOR LOSS OF PROFITS,
  GOODWILL, USE, DATA OR OTHER INTANGIBLE LOSSES, THAT RESULT FROM THE USE OF, OR INABILITY TO
  USE, THIS SERVICE.</p>
  <p>UNDER NO CIRCUMSTANCES WILL DEFTLE BE RESPONSIBLE FOR ANY DAMAGE, LOSS OR INJURY RESULTING FROM
  HACKING, TAMPERING OR OTHER UNAUTHORIZED ACCESS OR USE OF THE SERVICE OR YOUR ACCOUNT OR THE
  INFORMATION CONTAINED THEREIN.</p>
  <p>DEFTLE ASSUMES NO LIABILITY OR RESPONSIBILITY FOR ANY (I) ERRORS, MISTAKES, OR INACCURACIES OF
  CONTENT; (II) PERSONAL INJURY OR PROPERTY DAMAGE, OF ANY NATURE WHATSOEVER, RESULTING FROM YOUR
  ACCESS TO AND USE OF OUR SERVICE; (III) ANY UNAUTHORIZED ACCESS TO OR USE OF OUR SECURE SERVERS
  AND/OR ANY AND ALL PERSONAL INFORMATION STORED THEREIN; (IV) ANY INTERRUPTION OR CESSATION OF
  TRANSMISSION TO OR FROM THE SERVICE; (V) ANY BUGS, VIRUSES, TROJAN HORSES, OR THE LIKE THAT MAY
  BE TRANSMITTED TO OR THROUGH OUR SERVICE BY ANY THIRD PARTY; (VI) ANY ERRORS OR OMISSIONS IN ANY
  CONTENT OR FOR ANY LOSS OR DAMAGE INCURRED AS A RESULT OF THE USE OF ANY CONTENT POSTED,
  EMAILED, TRANSMITTED, OR OTHERWISE MADE AVAILABLE THROUGH THE SERVICE; AND/OR (VII) USER CONTENT
  OR THE DEFAMATORY, OFFENSIVE, OR ILLEGAL CONDUCT OF ANY THIRD PARTY.</p>
  <p>THE FOREGOING LIMITATION OF LIABILITY SHALL APPLY TO THE FULLEST EXTENT PERMITTED BY LAW IN THE
  APPLICABLE JURISDICTION.</p>
  <p>SOME STATES OR JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR THE LIMITATION OF LIABILITY FOR
  CONSEQUENTIAL OR INCIDENTAL DAMAGES, IN SUCH STATES OR JURISDICTIONS, DEFTLE'S LIABILITY SHALL
  BE LIMITED TO THE EXTENT PERMITTED BY LAW.</p>
  <div class="privacy-title policy-title">14 Indemnification</div>
  <div class="privacy-title policy-sub-title">14.1 User Agreement to Indemnify Deftle for Various Types of Harm or Legal Action</div>
  <p>You agree to defend, indemnify, and hold harmless Deftle, its parent legal entity,, officers,
  directors, employees and agents, from and against any claims, liabilities, damages, losses, and
  expenses, including without limitation, reasonable legal and accounting fees, arising out of or
  in any way connected with your access to or use of the site and our services, or your violation
  of these Terms of Service.</p>
  <p>This includes, but is not limited to: (i) your responsibilities or obligations under these Terms
  of Service; (ii) your violation of any third-party right, including any copyright, property, or
  privacy right; (iii) any claim that your content caused damage to a third party; (iv) any
  misrepresentation made by you; and (v) any breach of the representations, warranties, and
  covenants made by you herein.</p>
  <p>Deftle reserves the right, at your expense, to assume the exclusive defense and control of any
  matter for which you are required to indemnify Deftle, and you agree to cooperate with Deftle's
  defense of these claims. You agree not to settle any matter without the prior written consent of
  Deftle. Deftle will use reasonable efforts to notify you of any such claim, action or proceeding
  upon becoming aware of it.</p>

  <div class="privacy-title policy-title">15. Dispute Resolution</div>
  <p>At Deftle, we expect our users to resolve any disagreements or disputes in a professional and
  amicable manner. However, should a dispute arise that involves the website or is due to the use
  of our services, this section outlines the process by which such disputes will be addressed and
  resolved.</p>
  <p>This includes but is not limited to disputes between users and Deftle, legal jurisdiction, venue
  for disputes, and class action waiver. Please read the following subchapters carefully, as they
  have important legal implications.</p>
  <div class="privacy-title policy-sub-title">15.1 Process for Resolving Disputes Between Users and the Website</div>
  <p>In the event of a dispute arising between a user and Deftle, both parties agree to attempt to
  resolve the issue informally for at least thirty (30) days before initiating any formal
  proceedings. This informal negotiation period begins upon written notice from one party to the
  other.</p>
  <p>Users should send their written notice, which includes a brief written statement explaining the
  name, address, and contact information of the party raising the dispute, the facts leading to
  the dispute, and the relief requested, to Deftle’s designated contact address listed in section
  18 of this ToS.</p>
  <p>If the dispute cannot be resolved through this informal process, either party may initiate
  formal proceedings in accordance with the terms outlined in sections 15.2 and 15.3.</p>
  <div class="privacy-title policy-sub-title">15.2 Legal Jurisdiction and Venue for Disputes</div>
  <p>All disputes arising out of or related to these Terms of Service, the Website, or any associated
  services, will be governed by and construed in accordance with the laws of the State of
  California, United States, without regard to its principles of conflicts of law.</p>
  <p>Users and Deftle agree to submit to the personal jurisdiction of a state court located in San
  Francisco, California or the United States District Court for the Northern District of
  California, for any actions for which the parties retain the right to seek injunctive or other
  equitable relief in a court of competent jurisdiction.</p>
  <p>The parties expressly agree that any dispute resolution proceedings will be conducted only on an
  individual basis and not in a class, consolidated, or representative action. If for any reason a
  claim proceeds in court rather than in arbitration, each party waives any right to a jury trial
  as set forth in section 15.3.</p>
  <div class="privacy-title policy-sub-title">15.3 Class Action Waiver</div>
  <p>You and Deftle agree that any proceedings to resolve or litigate any dispute, whether through a
  court of law or arbitration, will be conducted solely on an individual basis. You specifically
  agree not to sue Deftle as a class plaintiff or class representative, join as a class member, or
  participate as an adverse party in any way in a class-action lawsuit against Deftle regarding
  your use of its services.</p>
  <p>If for any reason a claim proceeds in court rather than in arbitration, each party waives any
  right to a jury trial. This class action waiver provision will also apply to any claims asserted
  by you against any present or future parent or affiliated companies of Deftle to the extent that
  any such claims arise out of your access to, and/or use of the Website, and/or the provision of
  services, and/or your relationship with Deftle.</p>
  <p>If you do not agree to any part of these Terms, including this Class Action Waiver, you should
  not continue your use of Deftle services. Nothing in this paragraph, however, limits your rights
  to bring a lawsuit as an individual plaintiff.</p>
  <div class="privacy-title policy-title">16. Changes to Terms of Service</div>
  <div class="privacy-title policy-sub-title">16.1 Rights of Deftle to modify the ToS</div>
  <p>Deftle reserves the right, at its sole discretion, to change, modify, add, or remove portions of
  these Terms of Service at any time. Any changes will be effective immediately upon posting to
  the Website. While we will make every effort to provide notice of any significant changes, you
  are responsible for regularly reviewing the Terms of Service to ensure you are aware of any
  changes.</p>
  <div class="privacy-title policy-sub-title">16.2 Procedure for notifying users of ToS changes</div>
  <p>When we make significant changes, we will also revise the 'last updated' date at the bottom of
  these Terms of Service. Your continued use of the Website following the posting of changes will
  mean that you accept and agree to the changes. If you disagree with the changes to these Terms
  of Service, you should discontinue your use of the Website.</p>
  <p>You understand and agree that your continued use of the Website after the Terms of Service have
  changed constitutes your acceptance of the Terms of Service as revised. If you do not agree to
  the new Terms, you should stop using the Website.</p>
  <div class="privacy-title policy-title">17. Miscellaneous</div>
  <div class="privacy-title policy-sub-title">17.1 Other Necessary Provisions</div>
  <p>Severability: If any provision of these Terms of Service is found by a court of competent
  jurisdiction to be invalid, the parties nevertheless agree that the court should endeavor to
  give effect to the parties' intentions as reflected in the provision, and the other provisions
  of the Terms of Service remain in full force and effect.</p>
  <p>Waiver: No waiver of any term of these Terms of Service shall be deemed a further or continuing
  waiver of such term or any other term, and Deftle's failure to assert any right or provision
  under these Terms of Service shall not constitute a waiver of such right or provision.</p>
  <p>Assignment: You may not assign or transfer these Terms of Service or any of your rights or
  obligations under these Terms of Service, without our prior written consent. We may assign these
  Terms of Service at any time without notice to you.</p>
  <p>Entire Agreement: These Terms of Service (and any additional terms, rules and conditions of
  participation in particular Services that Deftle may post on the Service) constitute the entire
  agreement between you and Deftle with respect to the Service and supersede any prior agreements,
  oral or written, between you and Deftle.</p>
  <p>Survival: Upon termination of these Terms of Service, any provision which, by its nature or
  express terms should survive, will survive such termination or expiration.</p>
  <div class="privacy-title policy-title">18. Contact Information</div>
  <p>If you have any questions, concerns, or suggestions regarding these Terms of Service, or if you
  need to resolve any issue pertaining to the Terms or the Services, you may contact us at:</p>
  <div>Alnier LLC.</div>
  <div>Attn: Terms of Use / Privacy Policy</div>
  <div>180 Steuart St. #190532<div>
  <div>San Francisco, CA 94105</div>
  <br/>
  <p>We encourage our users to communicate with us, as your feedback not only helps us improve our
  services but also ensures a clear understanding of what we offer. Every correspondence is
  valuable to us, so please include your name, contact information, and a clear summary of your
  request or concern in your communication. We are committed to responding to all inquiries. This
  commitment is central to our mission of maintaining transparency, building trust, and fostering
  a beneficial relationship with our user community.</p>
                </div>
`;
};

export const handleGetTermsCondition = () => {
  return {
    __html: termsConditionDetail(),
  };
};

const privacyPolicyDetail = () => {
  return `<div class="privacy-desc">
  <p class="c1 c3"><span class="c4"></span></p>
  <p class="c1"><span class="c4">Last updated Jun 19th, 2023</span></p>
  <p class="privacy-title policy-title"><span class="c6">1. Introduction</span></p>
  <p class="privacy-title policy-sub-title"><span class="c9">1.1. Overview</span></p>
  <p class="c1"><span class="c4">Welcome to Deftle.com. At Deftle, we understand that
      privacy is important to you, and we are committed to being transparent about how we
      collect, use, and share your information. This Privacy Policy applies to your use of our
      services, including our website and our mobile applications.</span></p>
  <p class="c1"><span class="c4">This policy outlines the types of personal information we
      collect and receive, how we use this information, and who we share it with. We also
      explain the rights and choices you have over your information, and how we protect your
      privacy.</span></p>
  <p class="c1"><span class="c4">We encourage you to read this policy carefully to understand
      our privacy practices. If you do not agree with our practices, your choice is not to use
      our services. By accessing or using our services, you agree to this Privacy Policy.</span>
  </p>
  <p class="c1"><span class="c4">Deftle.com is operated by Alnier LLC (“Alnier” or “we” or
      “us”), a Delaware company with headquarters in San Francisco, CA.<br></span></p>
  <p class="privacy-title policy-sub-title"><span class="c9">1.2. Consent</span></p>
  <p class="c1"><span class="c4">By using our services, you consent to the collection, use, and
      sharing of your personal information as described in this policy. If you do not consent to
      the data practices described in this policy, you should not use our services.</span></p>
  <p class="c1"><span class="c4">Please note, this Privacy Policy does not apply to information
      collected by third party websites, applications, or services that can be accessed through
      our services.</span></p>
  <p class="privacy-title policy-title"><span class="c6"><br>2. Data Collection</span></p>
  <p class="privacy-title policy-sub-title"><span class="c9">2.1. Personal Information You
      Provide</span></p>
  <p class="c1"><span class="c4">When you use our services, you may provide us with certain
      personal information. This includes:</span></p>
  <ul class="c2 lst-kix_o13ipa72kb7k-0 start">
    <li class="c0 li-bullet-0"><span class="c4">Registration information: When you register for
        our services, you provide us with your name, email address, and a password. You may also
        provide us with additional information like your phone number and postal address.</span>
    </li>
    <li class="c0 li-bullet-0"><span class="c4">Job applicant data: If you use our services to
        search for jobs and apply, you provide us with information like your education history,
        work history, job interests, job search history, job search criteria and preferences,
        skills, reference information, background check information, social security number,
        passport and/or driver license numbers, geographical location, pay/compensation expectations, eligibility to
        work in the United States, voice and video recordings of you, languages spoken, and
        other information contained in your resume.</span></li>
    <li class="c0 li-bullet-0"><span class="c4">Employer data: If you use our services to post
        jobs and review applications, you provide us with information like open positions, job
        requirements, benefits, job descriptions, screening and interview questions, pay ranges,
        and bank and payment information.</span></li>
  </ul>
  <p class="privacy-title policy-sub-title"><span class="c9">2.2. Information We Collect
      Automatically</span></p>
  <p class="c1"><span class="c4">As you use our services, we automatically collect certain
      information about your device and your interaction with our services. This
      includes:</span></p>
  <ul class="c2 lst-kix_8nc83hza2d7q-0 start">
    <li class="c0 li-bullet-0"><span class="c4">User activity: We collect information about your
        activity on our website and mobile applications, like your job search history and job
        search criteria and preferences.</span></li>
    <li class="c0 li-bullet-0"><span class="c4">Device and browser data: We collect information
        from your device and browser, like your IP address, device ID, user agent and geolocation.</span>
    </li>
    <li class="c0 li-bullet-0"><span class="c4">Cookies and similar technologies: We use cookies
        and similar technologies to collect information about your interaction with our services
        and your use of them.</span></li>
    <li class="c0 li-bullet-0"><span class="c4">Data collected by third-party analytics tools:
        We use third-party analytics tools like Google Analytics that help us measure traffic
        and usage trends by collecting information sent by your device or our services.</span>
    </li>
  </ul>
  <p class="privacy-title policy-sub-title"><span class="c9">2.3. Purpose of Data Collection</span>
  </p>
  <p class="c1"><span class="c4">We collect your personal information for the following
      purposes:</span></p>
  <ul class="c2 lst-kix_ry12dx3pa46z-0 start">
    <li class="c0 li-bullet-0"><span class="c4">To provide and improve our services: We use your
        information to provide our services, respond to your inquiries, and improve our
        offerings.</span></li>
    <li class="c0 li-bullet-0"><span class="c4">To communicate with you: We use your information
        to communicate with you, including sending transactional emails and reminders.</span>
    </li>
    <li class="c0 li-bullet-0"><span class="c4">To facilitate payments: We use your information
        to process payments for chargeable services.</span></li>
    <li class="c0 li-bullet-0"><span class="c4">For security and fraud prevention: We use your
        information to protect the security and integrity of our services, and to prevent
        fraud.</span></li>
    <li class="c0 li-bullet-0"><span class="c4">For marketing and promotional purposes: We may
        use your information to send you promotional materials and updates about our
        services.</span></li>
  </ul>
  <p class="c1"><span class="c4">Please note that if certain personal information is not
      provided, it may affect the availability or functionality of our services.</span></p>
  <p class="privacy-title policy-title"><span class="c6">3. Use of Data</span></p>
  <p class="privacy-title policy-sub-title"><span class="c9">3.1. General Use</span></p>
  <p class="c1"><span class="c4">We use your personal information to provide, maintain, and
      improve our services. Here are some examples of how we use the data we collect:</span></p>
  <ul class="c2 lst-kix_8tut0ckf4a5u-0 start">
    <li class="c0 li-bullet-0"><span class="c4">To provide our services: We use your information
        to connect job seekers with employers, process payments, and facilitate
        interviews.</span></li>
    <li class="c0 li-bullet-0"><span class="c4">To communicate with you: We use your contact
        information to send transactional communications, including confirming your account,
        reminding you of upcoming interviews, responding to your comments, questions and
        requests, providing customer support, and sending you technical notices and
        administrative messages.</span></li>
    <li class="c0 li-bullet-0"><span class="c4">For marketing purposes: We may use your contact
        information to send promotional communications that may be of specific interest to you.
        These communications are aimed at driving engagement and maximizing the value you get
        from our services. We also use your information to send you job alerts.</span></li>
    <li class="c0 li-bullet-0"><span class="c4">For research and development: We use collective
        learnings about how people use our services and feedback provided directly to us to
        troubleshoot, to identify trends, usage, activity patterns and areas for integration and
        improvement of the service.</span></li>
    <li class="c0 li-bullet-0"><span class="c4">For safety and security: We use information
        about you and your service use to verify accounts and activity, to monitor suspicious or
        fraudulent activity and to identify violations of service policies.</span></li>
    <li class="c0 li-bullet-0"><span class="c4">To protect our legitimate business interests and
        legal rights: Where required by law or where we believe it is necessary to protect our
        legal rights, interests and the interests of others, we use information about you in
        connection with legal claims, compliance, regulatory, and audit functions, and
        disclosures in connection with the acquisition, merger or sale of a business.</span>
    </li>
  </ul>
  <p class="c1"><span class="c4">We use various technologies to collect and store information,
      including cookies, pixel tags, local storage, such as browser web storage or application
      data caches, databases, and server logs.</span></p>
  <p class="privacy-title policy-sub-title"><span class="c9">3.2. Use for AI Training</span></p>
  <p class="c1"><span class="c4">In addition to the uses described above, we may use your
      information for the purpose of improving our artificial intelligence systems. This usage
      helps our systems to learn and evolve, ultimately improving the services we provide to
      you. Please note that any data used for this purpose will be de-identified and used in an
      aggregated manner to maintain your privacy.</span></p>
  <p class="c1 c3"><span class="c4"></span></p>
  <p class="privacy-title policy-title"><span class="c6">4. Data Sharing</span></p>
  <p class="c1"><span class="c4">We may share your data with third parties under the following
      circumstances:</span></p>
  <ul class="c2 lst-kix_rrs1lw7y65i1-0 start">
    <li class="c0 li-bullet-0"><span class="c4">With your consent: We may share your information
        for a particular purpose, if you agree. You have the right to withdraw your consent at
        any time.</span></li>
    <li class="c0 li-bullet-0"><span class="c4">For external processing: We may provide your
        information to our affiliates or other trusted businesses or persons to process it for
        us, based on our instructions and in compliance with our Privacy Policy and any other
        appropriate confidentiality and security measures.</span></li>
    <li class="c0 li-bullet-0"><span class="c4">For legal reasons: We will share personal
        information with third parties if we have a good-faith belief that access, use,
        preservation, or disclosure of the information is reasonably necessary to:</span></li>
  </ul>
  <ul class="c2 lst-kix_rrs1lw7y65i1-1 start">
    <li class="c1 c10 li-bullet-0"><span class="c4">Meet any applicable law, regulation, legal
        process, or enforceable governmental request.</span></li>
    <li class="c1 c10 li-bullet-0"><span class="c4">Enforce applicable terms of service,
        including investigation of potential violations.</span></li>
    <li class="c1 c10 li-bullet-0"><span class="c4">Detect, prevent, or otherwise address fraud,
        security, or technical issues.</span></li>
    <li class="c1 c10 li-bullet-0"><span class="c4">Protect against harm to the rights, property
        or safety of our users, the public, or to us as required or permitted by law.</span>
    </li>
  </ul>
  <ul class="c2 lst-kix_rrs1lw7y65i1-0">
    <li class="c0 li-bullet-0"><span class="c4">In case of a sale or asset transfer: If we
        become involved in a merger, acquisition, or any form of sale of some or all of our
        assets, we will ensure the confidentiality of any personal information involved in such
        transactions and provide notice before personal information is transferred and becomes
        subject to a different privacy policy.</span></li>
    <li class="c0 li-bullet-0"><span class="c4">In aggregated and/or anonymized form: We may
        also share aggregated or de-identified information that cannot reasonably be used by
        those third parties to identify you.</span></li>
  </ul>
  <p class="c1"><span class="c4">Please note, our Privacy Policy does not cover information you
      choose to share publicly or between users of our service. It also does not cover data
      collected by third-party websites, platforms, and applications that our service may link
      to. We encourage you to review the privacy policies of those third parties to learn about
      their data practices.</span></p>
  <p class="privacy-title policy-title"><span class="c6">5. Data Retention and Deletion</span></p>
  <p class="privacy-title policy-sub-title"><span class="c9">5.1 Retention</span></p>
  <p class="c1"><span class="c4">We retain personal data for as long as your account is active,
      or as needed to provide you services, comply with our legal obligations, resolve disputes,
      and enforce our agreements. We may also retain personal data for a reasonable period to
      fulfill the purposes outlined in this Privacy Policy unless a longer retention period is
      required or permitted by law.</span></p>
  <p class="c1"><span class="c4">When we have no ongoing legitimate business need to process
      your personal data, we will either delete or anonymize it, or, if this is not possible
      (for example, because your personal data has been stored in backup archives), then we will
      securely store your personal data and isolate it from any further processing until
      deletion is possible.</span></p>
  <p class="privacy-title policy-sub-title"><span class="c9">5.2 Deletion</span></p>
  <p class="c1"><span class="c4">If you wish to delete your account, you can do so by initiating
      the account deletion from the Settings page of our website app or our mobile app. Upon
      your request, we will delete your account and information from our active databases. Such
      deletion is completed within 7 days.</span></p>
  <p class="c1"><span class="c4">Please note that some information may remain in our records
      after your account has been deleted, as necessary for us to comply with our legal
      obligations, resolve disputes, enforce our agreements.</span></p>
  <p class="c1 c3"><span class="c4"></span></p>
  <p class="privacy-title policy-title"><span class="c6">6. Data Security</span></p>
  <p class="c1"><span class="c4">We take the security of your personal data very seriously. We
      use state-of-the-art technology to protect your personal data from unauthorized access,
      disclosure, alteration, or destruction. All data transmitted over the internet is
      encrypted using HTTPS. We implement various security measures, including data encryption
      and physical security measures to guard against unauthorized access to our systems and
      data.</span></p>
  <p class="c1"><span class="c4">In addition, we ensure that our third-party data center vendors
      provide adequate security measures. Furthermore, access to personal data is limited to
      those employees, contractors, and agents who need to know such information to perform
      their job duties and responsibilities.</span></p>
  <p class="c1"><span class="c4">However, please be aware that no method of transmission over
      the internet, or method of electronic storage, is 100% secure. While we strive to use
      commercially acceptable means to protect your personal data, we cannot guarantee its
      absolute security.</span></p>
  <p class="c1"><span class="c4">In the event that any information under our control is
      compromised as a result of a breach of security, we will take reasonable steps to
      investigate the situation, notify those individuals whose information may have been
      compromised, and take other steps, in accordance with any applicable laws and
      regulations.</span></p>
  <p class="c1 c3"><span class="c4"></span></p>
  <p class="privacy-title policy-title"><span class="c6">7. Cookies and Similar Technologies</span>
  </p>
  <p class="c1"><span class="c4">We use cookies and similar technologies to improve and
      customize our services and your experience. Cookies are small files that a site or its
      service provider transfers to your computer's hard drive through your web browser (if you
      allow) that enables the site's or service provider's systems to recognize your browser and
      capture and remember certain information.</span></p>
  <p class="c1"><span class="c4">Cookies are used to help us understand your preferences based
      on previous or current site activity, which enables us to provide you with improved
      services. We also use cookies to help us compile aggregate data about site traffic and
      site interaction so that we can offer better site experiences and tools in the
      future.</span></p>
  <p class="c1"><span class="c4">We may contract with third-party service providers to assist us
      in better understanding our site visitors. These service providers are not permitted to
      use the information collected on our behalf except to help us conduct and improve our
      business.</span></p>
  <p class="c1"><span class="c4">Additionally, we may use technologies like session storage to
      provide you with better user experience, for example, to store your login session
      identifier so that you do not need to log in every time you visit our website.</span></p>
  <p class="c1"><span class="c4">You can choose to have your computer warn you each time a
      cookie is being sent, or you can choose to turn off all cookies. You do this through your
      browser settings. Each browser is a little different, so look at your browser's Help menu
      to learn the correct way to modify your cookies. If you disable cookies, some features
      will be disabled that make your site experience more efficient and some of our services
      may not function properly.</span></p>
  <p class="c1 c3"><span class="c4"></span></p>
  <p class="privacy-title policy-title"><span class="c6">8. Age Restrictions</span></p>
  <p class="c1"><span class="c4">In accordance with the U.S. Children's Online Privacy
      Protection Act ("COPPA"), we do not knowingly collect or store any personal information,
      even in aggregate, about children under the age of 13. If we discover we have received any
      information from a child under the age of 13 in violation of this policy, we will delete
      that information immediately.</span></p>
  <p class="c1"><span class="c4">In addition, our services are not designed for individuals
      under the age of 18. If you are under 18 years of age, please do not use or access our
      services at any time or in any manner. If we learn that personal information from users
      less than 18 years of age has been collected, we will take reasonable measures to promptly
      delete such information from our records.</span></p>
  <p class="c1"><span class="c4">If you believe that we might have any information from or about
      a child under 13, or from or about a minor under 18, please contact us in accordance with
      the “Contact Us” section below.</span></p>
  <p class="c1 c3"><span class="c4"></span></p>
  <p class="privacy-title policy-title"><span class="c6">9. Policy Updates</span></p>
  <p class="c1"><span class="c4">Deftle reserves the right to update this Privacy Policy
      from time to time in order to reflect changes in our practices, services, or in response
      to changes in the applicable law. We will post the updated Privacy Policy on our website
      and application, indicating the date of the latest update at the top of the page.</span>
  </p>
  <p class="c1"><span class="c4">It is your responsibility to review the Privacy Policy
      periodically to become aware of any changes. The changes will take effect as soon as they
      are posted on the website or application. Your continued use of our services following the
      posting of changes will constitute your acceptance of such changes.</span></p>
  <p class="c1"><span class="c4">If we make any significant changes that materially alter our
      privacy practices, we may also notify you by other means, such as sending an email or
      posting a notice on our website and application. However, it is ultimately your
      responsibility to review the updated policy.</span></p>
  <p class="privacy-title policy-title"><span class="c6">10. Jurisdiction and Legal
      Compliance</span></p>
  <p class="c1"><span class="c4">Deftle operates primarily in the United States and complies
      with all applicable local, state, national, and international laws and regulations
      concerning data privacy. By using our services, you understand and agree that your
      information may be transferred to and processed in the United States, which may have data
      protection laws that are different from those in your country.</span></p>
  <p class="c1"><span class="c4">We will take all reasonable steps to ensure that your data is
      treated securely and in accordance with this Privacy Policy. We will not transfer your
      personal information to an organization or a country unless there are adequate controls in
      place, including the security of your data and other personal information.</span></p>
  <p class="c1"><span class="c4">If you are using our services from outside the United States,
      please be aware that your information may be transferred to, stored, and processed in the
      United States where our servers are located and our central database is
      operated.<br></span></p>
  <p class="privacy-title policy-sub-title"><span class="c9">10.1. California residents</span></p>
  <p class="c1"><span class="c4">If you are a California resident, you have the right,
      subject to certain exceptions defined in the California Consumer Privacy Act (“CCPA”) and
      other applicable laws and regulations, to request that Alnier LLC disclose certain
      information to you about our collection and use of your personal information over the past
      twelve (12) months. You will not receive discriminatory treatment for exercising your
      rights under the CCPA. If you would like to exercise your rights to make an information
      access request or a deletion request, please contact us in accordance with the “Contact
      Us” section below.</span></p>
  <p class="privacy-title policy-sub-title"><span class="c9">10.2. Dispute Resolution</span></p>
  <p class="c1"><span class="c4">If you have a complaint or dispute regarding this Privacy
      Policy or our handling of your data, please contact us in accordance with the “Contact Us”
      section below. We will do our best to address your concerns. If you feel that your
      complaint has been inadequately addressed, please note that you have the right to lodge a
      complaint with the relevant supervisory authority in your jurisdiction.</span></p>
  <p class="c1 c3"><span class="c4"></span></p>
  <p class="privacy-title policy-title"><span class="c6">11. Contact Us</span></p>
  <p class="c1"><span class="c4">If you have any questions or concerns about this Privacy Policy
      or our data practices, please contact us at:</span></p>
  <p class="c1 c3"><span class="c4"></span></p>
  <p></p>
  <div>Alnier LLC</div>
  <div>Attn: Terms of Use / Privacy Policy</div>
  <div>180 Steuart St. #190532</div>
  <div>San Francisco, CA 94105</div>
  <p></p>
  <p class="c1 c3"><span class="c4"></span></p>
  <p class="c1"><span class="c4">We will make every effort to resolve your concerns. We are
      committed to working with you to obtain a fair resolution of any complaint or concern
      about privacy. If you are not satisfied with our response, you may also have the right to
      complain to the data protection authority in your country.</span></p>
  <p class="c1 c3"><span class="c4"></span></p>
  <p class="c1 c3"><span class="c4"></span></p>
  <p class="c1 c3"><span class="c4"></span></p>
  <p class="c1 c3"><span class="c4"></span></p>
</div>`;
};

export const handleGetPrivacyPolicy = () => {
  return {
    __html: privacyPolicyDetail(),
  };
};
