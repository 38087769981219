import React, { useState, useEffect, useRef, useMemo } from "react";
import { Col, Row, Button, Card, Pagination } from "react-bootstrap";
import { withRouter, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { compose } from "recompose";
import { withFirebase } from "../../components/Firebase";
import * as ROUTES from "../../constants/routes";
import { errorMessage, searchJobsValidationMessage } from "../../util/Validatios";
import Loader from "../../components/Loader";
import { createMarkup } from "../../helpers/createMarkup";
import { PAGE_SIZE, DEFAULT_MILES } from "../../constants/keys";
import { findJobs } from "../../Action/jobs";
import { fetchOpeningApplication } from "../../Action/applications";
import { OK } from "../../constants/apiStatusCodes";
import ApplyForJob from "../ApplyForJob";
import { appliedSearchJob, storeSearchJobDetails, getSearchResults, getSearchExpand } from "../../redux/actions/jobs.action";
import SearchJobNotFound from "../../pages/SearchJobNotFound";
import { fbSetScreenName, fbSetEvent, fbSetParams } from "../../Action/firebaseAnalytics";
import { OPEN_SCREEN, JOB_DETAIL_SCREEN, APPLY_JOB, APP_BUTTON } from "../../constants/firebaseAnalytics";
import "./index.css";

export const SearchExpand = ({ history }) => {
  const [searchResults, setSearchResults] = useState([]);
  const [selectedJob, setSelectedJob] = useState({});
  const [showApplyJob, setShowApplyJob] = useState(false);
  const [previous, setPrevious] = useState(-1);
  const [error, setError] = useState("");
  const [hasMore, setHasMore] = useState(false);
  const [start, setStart] = useState(0);
  const [loading, setLoading] = useState(false);
  const [searchLoading, setSearchLoading] = useState(false);
  const [isFromSearch, setIsFromSearch] = useState(true);
  const [showSearchAlert, setShowSearchAlert] = useState(false);
  const [didMount, setDidMount] = useState(true);
  const jobDescRef = useRef(null);
  const jobFilterRef = useRef(null);

  const isAppliedSearchJob = useSelector((state) => state.job.isAppliedSearchJob);
  const dispatch = useDispatch();
  const { jobId } = useParams();
  const handleGetJobDetail = () => {
    setLoading(true);
    setSearchLoading(true);
    fetchOpeningApplication(jobId)
      .then((jobResponse) => {
        setSelectedJob(jobResponse?.data?.opening);
        let searchJobDetailArr = [];
        searchJobDetailArr.push(jobResponse?.data?.opening);
        setSearchResults(searchJobDetailArr);
        setLoading(false);
        setSearchLoading(false);
      })
      .catch((e) => {
        handleShowSearchAlert(true);
        setLoading(false);
        setSearchLoading(false);
      });
  };

  const handleShowSearchAlert = (status) => {
    setShowSearchAlert(status);
  };
  const handleSearch = (startPoint) => {
    const zipCode = history?.location?.state?.searchInputDetail?.zipCode;
    const position = history?.location?.state?.searchInputDetail?.position;
    const miles = history?.location?.state?.searchInputDetail?.miles;
    const city = history?.location?.state?.searchInputDetail?.city;
    const selectedState = history?.location?.state?.searchInputDetail?.selectedState;
    setError("");
    jobFilterRef.current.scrollTo(0, 0);
    setLoading(true);
    findJobs(
      startPoint,
      PAGE_SIZE,
      city || "",
      selectedState || "",
      zipCode,
      miles?.value || DEFAULT_MILES,
      position || "",
    )
      .then((results) => {
        if (results?.status === OK && results?.data?.openings?.length > 0) {
          setSearchResults(results?.data?.openings);
          setHasMore(results?.data?.hasMore);
          dispatch(storeSearchJobDetails(results?.data?.openings));
        } else if (results?.status === OK && results?.data?.openings?.length === 0) {
          setError(searchJobsValidationMessage.noResultFound);
        } else {
          setError(errorMessage.others);
        }
        setLoading(false);
      })
      .catch((e) => {
        setError(errorMessage.others);
        setLoading(false);
      });
  };

  const handlePrevious = () => {
    handleSearch(previous);
    setStart(previous);
  };

  const handleSelectJob = (searchDetail) => {
    setSelectedJob(searchDetail);
    jobDescRef.current.scrollTo(0, 0);
  };

  const handleApplyJob = (status, jobId) => {
    handleFBEvents(APP_BUTTON, {
      cta: APPLY_JOB,
    });
    setShowApplyJob(status);
    dispatch(appliedSearchJob(false, ""));

    setTimeout(() => {
      dispatch(appliedSearchJob(true, jobId));
    }, 100);
  };

  const handleFBEvents = (eventName, params) => {
    dispatch(fbSetEvent(eventName));
    dispatch(fbSetParams(params));
  };

  const handlefbSetScreen = () => {
    handleFBEvents(OPEN_SCREEN, {});
    dispatch(fbSetScreenName(JOB_DETAIL_SCREEN));
  };

  const handleBackPress = () => {
    dispatch(getSearchResults(true))
    dispatch(getSearchExpand(false))
    history.push(ROUTES.SEARCH, {
      currentPage: start - PAGE_SIZE,
      previousPage: previous,
      hasMore: hasMore,
    });
  };

  useEffect(() => {
    if (history?.location?.state?.searchJobDetails?.length > 0) {
      setSearchResults(history?.location?.state?.searchJobDetails);
      setLoading(false);
    }
  }, [history?.location?.state?.searchJobDetails]);

  useEffect(() => {
    if (history?.location?.state?.currentPage) {
      setStart(history?.location?.state?.currentPage);
    }
  }, [history?.location?.state?.currentPage]);

  useMemo(() => {
    if (Math.sign(history?.location?.state?.previousPage) >= 0) {
      setPrevious(history?.location?.state?.previousPage);
    }
  }, [history?.location?.state?.previousPage]);

  useEffect(() => {
    if (history?.location?.state?.selectedJob) {
      setSelectedJob(history?.location?.state?.selectedJob);
      setDidMount(false);
    }
  }, [history?.location?.state?.selectedJob]);

  useEffect(() => {
    if (history?.location?.state?.hasMore) {
      setHasMore(history?.location?.state?.hasMore);
    }
  }, [history?.location?.state?.hasMore]);

  useEffect(() => {
    if (selectedJob) {
      setLoading(false);
    }
  }, [selectedJob]);

  useEffect(() => {
    if (!history?.location?.state?.selectedJob && jobId && isFromSearch) {
      setIsFromSearch(true);
      handleGetJobDetail();
    } else {
      setIsFromSearch(false);
    }
  }, [jobId, history?.location?.state?.selectedJob]);

  useMemo(() => {
    if (hasMore && searchResults?.length > 0 && !didMount) {
      setPrevious(start - PAGE_SIZE);
      setStart(start + PAGE_SIZE);
    }
  }, [hasMore, searchResults]);

  useEffect(() => {
    handlefbSetScreen();
  }, []);

  return (
    <div>
      <div className="container-fluid">
        <Row>
          <Col xl={3} md={4} col={12} className="sidebar-searchexpand-data" ref={jobFilterRef}>
            <div className="searchexpand-sidebar-innercontainer">
              <div onClick={() => handleBackPress()} className="d-flex back-edit-filter align-items-center mb-3">
                <img className="float-right fullscreen-icon" src="/icon/back_arrow_angle.svg" alt="back" />
                <label className="edit-filter px-3 mb-0 txt-edit-filter">Edit filters</label>
              </div>
              {loading && <Loader loaderStyle="search-expand-loader" />}
              {!loading &&
                searchResults?.length > 0 &&
                searchResults.map((searchVal, index) => {
                  return (
                    <Card className="mb-4" key={index} onClick={() => handleSelectJob(searchVal)}>
                      <Card.Body
                        className={`${
                          searchVal?.jobId === selectedJob?.jobId ? "selected-search-job" : "left-panel-search-job"
                        }`}
                      >
                        <Card.Title className="searchexapand-block-card">{searchVal.title}</Card.Title>
                        <Card.Text className="searchexapand-text">
                          {searchVal?.companyName || ""} -{" "}
                          {searchVal?.location ||
                            (searchVal?.city || "") +
                              ", " +
                              (searchVal?.state || "") +
                              " " +
                              (searchVal?.zipCode || "") ||
                            ""}
                        </Card.Text>
                      </Card.Body>
                    </Card>
                  );
                })}

              {!isFromSearch && !loading && !error && (
                <Pagination className="pagination-data float-right bottom-pagination pb-3">
                  {Math.sign(previous) >= 0 && <Pagination.Prev onClick={() => handlePrevious()} />}
                  {hasMore && <Pagination.Next onClick={() => handleSearch(start)} />}
                </Pagination>
              )}
            </div>
          </Col>

          <Col
            xl={9}
            md={8}
            col={12}
            className="joblist-sections lg-search-result searchexpand-data-block"
            ref={jobDescRef}
          >
            {searchLoading && <Loader loaderStyle="search-loader" />}

            {Object.keys(selectedJob).length > 0 && (
              <Card className="search-application-type">
                <Card.Header className="pl-6">
                  <div className="job-type-title d-md-flex justify-content-between align-items-start">
                    <div>
                      <Card.Title className="job-type text-brown mb-4">{selectedJob?.title}</Card.Title>
                      <Card.Subtitle className="mb-2 text-brown job-type-subtitle pb-2">
                        {selectedJob?.companyName || ""} -{" "}
                        {selectedJob?.location ||
                          (selectedJob?.city || "") +
                            ", " +
                            (selectedJob?.state || "") +
                            " " +
                            (selectedJob?.zipCode || "") ||
                          " "}
                      </Card.Subtitle>
                    </div>
                    <div className="search-apply-btn-container">
                      <Button
                        variant="info"
                        className="search-button-css closing apply-btn-style"
                        onClick={() => handleApplyJob(true, selectedJob?.jobId || jobId)}
                      >
                        Apply
                      </Button>

                      <Card.Img
                        className="float-right fullscreen-icon"
                        src="/icon/collapse.svg"
                        onClick={() => handleBackPress()}
                      />
                    </div>
                  </div>
                </Card.Header>
                <Card.Body>
                  <div className="job-type-title">
                    <Card.Subtitle className="mb-2 text-muted">
                      <div
                        dangerouslySetInnerHTML={createMarkup(selectedJob?.description || "")}
                        className="job-desc-block qualification-section-block"
                      ></div>
                    </Card.Subtitle>
                  </div>
                </Card.Body>
              </Card>
            )}
          </Col>
        </Row>
      </div>
      {isAppliedSearchJob && showApplyJob && (
        <ApplyForJob jobId={selectedJob?.jobId || jobId} />
      )}

      {showSearchAlert && (
        <SearchJobNotFound
          title={"Job not found"}
          message={"The job opening you are looking for does not exists or it has been closed"}
          handleShowSearchAlert={handleShowSearchAlert}
        />
      )}
    </div>
  );
};
export default compose(withRouter, withFirebase)(SearchExpand);
