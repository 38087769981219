import React, { useState, useEffect, useMemo, lazy } from "react";
import { Col, Row, Dropdown, Form, Button, Card, Pagination } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { withRouter, useLocation } from "react-router-dom";
import { compose } from "recompose";
import { withFirebase } from "../../components/Firebase";
import * as ROUTES from "../../constants/routes";
import { STATES_LIST, PAGE_SIZE, MILES_LIST, SEARCH_VALUES, DEFAULT_MILES } from "../../constants/keys";
import {
  setCurrentPage,
  setPreviousPage,
  storeSearchJobDetails,
  storeSelectedSearchJobDetails,
  setSearchInputDetail,
  getSearchResults,
  getSearchExpand,
  jobDetails
} from "../../redux/actions/jobs.action";
import { findJobs } from "../../Action/jobs";
import { errorMessage, searchJobsValidationMessage } from "../../util/Validatios";
import { OK } from "../../constants/apiStatusCodes";
import { createMarkup } from "../../helpers/createMarkup";
import { SetValue, GetValue } from "../../util/DataRepositry";
import {
  searchByFirstCharacter,
  findUniqueValue,
  searchElementsByFirstCharacter,
} from "../../helpers/searchByFirstCharacter";
import { FixedSizeList as List } from "react-window";
import { fbSetScreenName, fbSetEvent, fbSetParams } from "../../Action/firebaseAnalytics";
import { OPEN_SCREEN, SEARCH_JOB_SCREEN, SEARCH_JOB, EXPAND_JOB, APP_BUTTON } from "../../constants/firebaseAnalytics";
import { validateZipcode } from "../../util/Validatios";
import citiesJson from "../../constants/cities.json";
import Loadable from "../../components/Loadable";
import "./index.css";

const Loader = Loadable(lazy(() => import("../../components/Loader")));
const SearchJobNotFound = Loadable(lazy(() => import("../../pages/SearchJobNotFound")));

export const Search = ({ history }) => {
  const isMobile = window.innerWidth <= 991;
  const [inputVal, setInputVal] = useState({});
  const [hasMore, setHasMore] = useState(false);
  const [miles, setMiles] = useState({});
  const [searchResults, setSearchResults] = useState([]);
  const [selectedState, setSelectedState] = useState("");
  const [loading, setLoading] = useState(false);
  const [start, setStart] = useState(0);
  const [previous, setPrevious] = useState(-1);
  const [error, setError] = useState("");
  const [inputError, setInputError] = useState({});
  const [isDidMount, setDidMount] = useState(true);
  const [stateList, setStateList] = useState(STATES_LIST);
  const [showStateList, setShowStateList] = useState(false);
  const [selectedCity, setSelectedCity] = useState("");
  const [cityList, setCityList] = useState(citiesJson);
  const [showCityList, setShowCityList] = useState(false);
  const { search } = useLocation();
  const [showSearchAlert, setShowSearchAlert] = useState(new URLSearchParams(search).has("error"));
  const [scrollPostion, setScrollPosition] = useState(0);
  const dispatch = useDispatch();
  const searchJobResult = useSelector((state) => state.job.searchJobDetails);
  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <div
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
      className="select-miles-container"
    >
      <label className="select-dropdown-title">{children}</label>
      <label className="m-0">
        <img src="/icon/down_arrow.svg" alt="open" />
      </label>
    </div>
  ));

  useEffect(() => {
    if (history?.location?.state?.currentPage) {
      setStart(history?.location?.state?.currentPage);
    }
  }, [history?.location?.state?.currentPage]);

  useMemo(() => {
    if (Math.sign(history?.location?.state?.previousPage) >= 0) {
      setPrevious(history?.location?.state?.previousPage);
    }
  }, [history?.location?.state?.previousPage]);

  useEffect(() => {
    if (history?.location?.state?.hasMore) {
      setHasMore(history?.location?.state?.hasMore);
    }
  }, [history?.location?.state?.hasMore]);

  const onChangeHandler = (evt) => {
    const value = evt.target.value;

    setInputVal({
      ...inputVal,
      [evt.target.name]: value,
    });

    setInputError({});
  };

  const handleSearchState = (evt) => {
    evt.stopPropagation();
    const value = evt.target.value;
    setSelectedState(value);
    setShowStateList(true);
    if (value) {
      const stateSearchRes = searchByFirstCharacter(STATES_LIST, value);
      const uniqueValue = findUniqueValue(stateSearchRes);
      if (stateSearchRes.length > 0) {
        setStateList(stateSearchRes);
        if (uniqueValue[0].name.toLowerCase() === value.toLowerCase()) {
          setShowStateList(false);
        }
      } else {
        setShowStateList(false);
      }
    } else {
      setStateList(STATES_LIST);
    }
  };

  const handleSearchCity = (evt) => {
    evt.stopPropagation();
    const value = evt.target.value;
    setSelectedCity(value);

    if (value) {
      setShowCityList(true);
      const citySearchRes = searchElementsByFirstCharacter(citiesJson, value);
      const uniqueValue = findUniqueValue(citySearchRes);
      if (uniqueValue.length > 0) {
        setCityList(uniqueValue);
        if (uniqueValue[0].toLowerCase() === value.toLowerCase()) {
          setShowCityList(false);
        }
      } else {
        setShowCityList(false);
      }
    } else {
      setCityList(citiesJson);
      setShowCityList(false);
    }
  };

  const validate = () => {
    let errorMessages = {};

    if (!inputVal.position) {
      errorMessages.message = searchJobsValidationMessage.position;
      errorMessages.key = "position";
    } else if (!miles) {
      errorMessages.message = searchJobsValidationMessage.miles;
      errorMessages.key = "miles";
    } else if ((!selectedCity || !selectedState) && !inputVal.zipCode) {
      errorMessages.message = searchJobsValidationMessage.location;
      errorMessages.key = "location";
    } else if (!inputVal.zipCode && !selectedCity && !selectedState) {
      errorMessages.message = searchJobsValidationMessage.zipCode;
      errorMessages.key = "zipCode";
    } else if (inputVal.zipCode && !validateZipcode(inputVal.zipCode)) {
      errorMessages.message = searchJobsValidationMessage.zipCodeInvalid;
      errorMessages.key = "zipCode";
    }

    if (errorMessages?.message) {
      setInputError(errorMessages);
      return false;
    } else {
      return true;
    }
  };

  const handleSearch = async (startPoint) => {
    const localStorageValue = await handleGetLocalstorageValue();
    const position = inputVal?.position || localStorageValue?.position || "";
    const city = selectedCity || localStorageValue?.city || "";
    const zipCode = inputVal?.zipCode || localStorageValue?.zipCode || "";
    const state = selectedState || localStorageValue?.state || "";
    const searchRadius = miles?.value || localStorageValue?.miles?.value || DEFAULT_MILES;

    setError("");
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
    setLoading(true);
    return findJobs(startPoint, PAGE_SIZE, city, state, zipCode, searchRadius, position)
      .then((results) => {
        if (results?.status === OK && results?.data?.openings?.length > 0) {
          setSearchResults(results?.data?.openings);
          setHasMore(results?.data?.hasMore);
          dispatch(storeSearchJobDetails(results?.data?.openings));
          dispatch(
            setSearchInputDetail({
              position,
              city,
              zipCode,
              miles,
              selectedState,
            }),
          );
        } else if (results?.status === OK && results?.data?.openings?.length === 0) {
          setError(searchJobsValidationMessage.noResultFound);
        } else {
          setError(errorMessage.others);
        }

        setLoading(false);
        return results?.data;
      })
      .catch((e) => {
        setError(errorMessage.others);
        setLoading(false);
      });
  };

  const submitSearchJobs = async () => {
    setInputError({});
    handleFBEvents(APP_BUTTON, {
      cta: SEARCH_JOB,
    });
    if (!validate()) {
      return;
    }
    setLoading(true);
    setStart(0);
    // setPrevious(-1);
    const searchResponse = await handleSearch(0);
    if (isMobile) {
      const { position, zipCode } = inputVal;
      const searchInputDetail = {
        position,
        city: selectedCity,
        zipCode,
        miles,
        selectedState,
      };
      setLoading(false);
      history.push(ROUTES.SEARCH_RESULTS, {
        searchJobDetails: searchResponse?.openings,
        selectedJob: {},
        searchInputDetail: searchInputDetail,
        currentPage: start,
        previousPage: previous,
        hasMore: searchResponse?.hasMore,
      });
    }
  };

  const handlePrevious = () => {
    handleSearch(previous);
    setStart(previous);
  };

  const handleSetLocalStrogeSearchDetail = async () => {
    const localSearchValue = await handleGetLocalstorageValue();
    setInputVal({
      zipCode: localSearchValue?.zipCode,
      position: localSearchValue?.position,
    });
    setSelectedCity(localSearchValue?.city);
    setSelectedState(localSearchValue?.state);
    setMiles(localSearchValue?.miles);
  };

  const handleGetLocalstorageValue = async () => {
    const localSearchValue = await GetValue(SEARCH_VALUES);
    const parseLocalValue = await JSON.parse(localSearchValue);
    return parseLocalValue;
  };

  const handleFullscreenView = (jobDetail) => {
    handleFBEvents(APP_BUTTON, {
      cta: EXPAND_JOB,
    });
    dispatch(storeSelectedSearchJobDetails(jobDetail));
    const { position, zipCode } = inputVal;
    const searchInputDetail = {
      position,
      city: selectedCity,
      zipCode,
      miles,
      selectedState,
    };
    const currentPageNumber = start - PAGE_SIZE;
    history.push(ROUTES.JOBS + "/" + jobDetail?.jobId, {
      searchJobDetails: searchResults,
      selectedJob: jobDetail,
      searchInputDetail: searchInputDetail,
      currentPage: currentPageNumber,
      previousPage: previous,
      hasMore: hasMore,
    });
    dispatch(jobDetails(jobDetail?.jobId));
    dispatch(getSearchResults(false))
    dispatch(getSearchExpand(true))
  };

  useEffect(() => {setDidMount(false); dispatch(getSearchResults(true))}, []);

  useEffect(() => {
    if (!isDidMount) {
      SetValue(
        SEARCH_VALUES,
        JSON.stringify({
          ...inputVal,
          miles: miles,
          city: selectedCity,
          state: selectedState,
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputVal, selectedState, miles, selectedCity]);

  useMemo(() => {
    if (history?.location?.state?.homeSearch) {
      setStart(0);
      // setPrevious(-1);
      handleSearch(0);
    }
  }, [history?.location?.state?.homeSearch]);

  const onScroll = () => {
    setScrollPosition(window.pageYOffset);
  };

  const handleFBEvents = (eventName, params) => {
    dispatch(fbSetEvent(eventName));
    dispatch(fbSetParams(params));
  };

  const handlefbSetScreen = () => {
    handleFBEvents(OPEN_SCREEN, {});
    dispatch(fbSetScreenName(SEARCH_JOB_SCREEN));
  };

  const renderCities = useMemo(() => {
    return (
      <Dropdown.Menu>
        <List height={window.innerHeight} itemCount={cityList?.length} itemSize={35} className="search-list-dropdown">
          {({ index, style }) => (
            <div
              key={index}
              onClick={() => {
                setSelectedCity(cityList[index]);
                setInputError({});
                setShowCityList(false);
              }}
              style={style}
              className="search-list-item"
            >
              {cityList[index]}
            </div>
          )}
        </List>
      </Dropdown.Menu>
    );
  }, [cityList]);

  useEffect(() => {
    window.addEventListener("scroll", onScroll);
    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  useEffect(() => {
    handleSetLocalStrogeSearchDetail();
    handlefbSetScreen();
  }, []);

  useEffect(() => {
    if (searchJobResult?.length > 0) {
      setSearchResults(searchJobResult);
    } else if (!history?.location?.state?.homeSearch) {
      setStart(0);
      setPrevious(-1);
     !isMobile && handleSearch(0);
    }
  }, [searchJobResult]);

  useEffect(() => {
    dispatch(setCurrentPage(start));
    dispatch(setPreviousPage(previous));
  }, [start, previous]);

  useMemo(() => {
    if (hasMore && searchResults?.length && !isDidMount) {
      setPrevious(start - PAGE_SIZE);
      setStart(start + PAGE_SIZE);
    }
  }, [hasMore, searchResults]);

  const handleShowSearchAlert = (status) => {
    setShowSearchAlert(status);
    if (!status) {
      window.history.replaceState({}, "", ROUTES.SEARCH); //remove the "?error"
    }
  };

  return (
    <div data-nosnippet>
      <div className="container-fluid">
        {isMobile && loading && <Loader loaderStyle="search-responsive-loader" />}
        <Row>
          <Col xl={3} lg={4} col={12} className="search-block">
            <div
              className={`sidebar-search-container ${
                scrollPostion > 100 ? "sidebar-search-container-reduce-top" : "sidebar-search-container-top"
              } `}
            >
              <Form className="search-section sidebar-search-block">
                <Form.Group className="mb-3">
                  <Form.Label>Job title or skills:</Form.Label>
                  <Form.Control
                    type="text"
                    name="position"
                    placeholder=""
                    onChange={(e) => onChangeHandler(e)}
                    className={inputError?.key === "position" && !inputVal?.position ? "invalid-input" : "valid-input"}
                    value={inputVal?.position || ""}
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Search within:</Form.Label>
                  <Dropdown
                    className={`select-search-dropdown ${inputError?.key === "miles" && !miles ? "invalid-input" : ""}`}
                  >
                    <Dropdown.Toggle
                      variant="Secondary"
                      id="dropdown-basic"
                      className="w-100 d-flex justify-content-between"
                      as={CustomToggle}
                    >
                      {miles?.label || "Select miles"}
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      {MILES_LIST.map((item, index) => {
                        return (
                          <Dropdown.Item
                            onClick={() => {
                              setMiles(item);
                              setInputError({});
                            }}
                            key={index}
                          >
                            {item?.label}
                          </Dropdown.Item>
                        );
                      })}
                    </Dropdown.Menu>
                  </Dropdown>
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>From City:</Form.Label>
                  <Dropdown
                    className={`select-search-dropdown state-search-dropdown city-search-dropdown ${
                      (inputError?.key === "location" || inputError?.key === "city") && !selectedCity
                        ? "invalid-input"
                        : ""
                    }`}
                    show={showCityList}
                  >
                    <Dropdown.Toggle id="dropdown-basic" variant="Secondary">
                      <div className="select-miles-container">
                        <label className="select-dropdown-title">
                          <Form.Control
                            type="text"
                            id="city"
                            name="city"
                            placeholder="Select a city"
                            className="search-state-input"
                            value={selectedCity || ""}
                            onChange={(e) => handleSearchCity(e)}
                            autoComplete={"off"}
                          />
                        </label>
                        <label className="m-0" onClick={() => setShowCityList(!showCityList)}>
                          <img src="/icon/down_arrow.svg" alt="open" />
                        </label>
                      </div>
                    </Dropdown.Toggle>
                    {renderCities}
                  </Dropdown>
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>State:</Form.Label>
                  <Dropdown
                    show={showStateList}
                    className={`select-search-dropdown state-search-dropdown ${
                      inputError?.key === "location" && !selectedState ? "invalid-input" : ""
                    }`}
                    onToggle={() => setShowStateList(!showStateList)}
                  >
                    <Dropdown.Toggle
                      variant="Secondary"
                      id="dropdown-basic"
                      onClick={() => setShowStateList(!showStateList)}
                    >
                      <div className="select-miles-container">
                        <label className="select-dropdown-title">
                          <Form.Control
                            type="text"
                            id="state"
                            name="state"
                            placeholder="Select a state"
                            className="search-state-input"
                            value={selectedState || ""}
                            onChange={(e) => handleSearchState(e)}
                            autoComplete={"off"}
                          />
                        </label>
                        <label className="m-0">
                          <img src="/icon/down_arrow.svg" alt="open" />
                        </label>
                      </div>
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      {stateList.map((item, index) => {
                        return (
                          <Dropdown.Item
                            key={index}
                            onClick={() => {
                              setSelectedState(item.name);
                              setInputError({});
                            }}
                          >
                            {item.name}
                          </Dropdown.Item>
                        );
                      })}
                    </Dropdown.Menu>
                  </Dropdown>
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Or Zip Code:</Form.Label>
                  <Form.Control
                    id="zipCode"
                    name="zipCode"
                    type="text"
                    placeholder="zip code"
                    onChange={(e) => onChangeHandler(e)}
                    className={
                      inputError?.key === "zipCode" || inputError?.key === "location" ? "invalid-input" : "valid-input"
                    }
                    value={inputVal?.zipCode || ""}
                  />
                </Form.Group>
                {inputError?.message && (
                  <div className="error-msg search-input-error">
                    <p>{inputError?.message}</p>
                  </div>
                )}
                <Button
                  type="button"
                  className="w-100 search-button-css mt-3 text-center"
                  onClick={() => submitSearchJobs()}
                >
                  Search
                </Button>
              </Form>
            </div>
          </Col>
          <Col xl={9} md={8} col={12} className="joblist-sections lg-search-result searchexpand-data pt-0">
            {loading && <Loader loaderStyle="search-loader" />}

            {searchResults?.length > 0 &&
              !loading &&
              !error &&
              searchResults.map((jobs, index) => (
                <Card className="search-application-type" key={index} onClick={() => handleFullscreenView(jobs)}>
                  <Card.Header className="pl-6">
                    <div className="job-type-title d-md-flex justify-content-between align-items-center">
                      <Card.Title className="job-type text-brown mb-4">{jobs?.title || ""}</Card.Title>
                      <Card.Img className="float-right fullscreen-icon" src="./icon/fullscreen_view.svg" />
                    </div>
                    <div className="job-type-title">
                      <Card.Subtitle className="mb-2 text-brown job-type-subtitle pb-2">
                        {jobs?.companyName || ""} - {jobs?.location || ""}
                      </Card.Subtitle>
                    </div>
                  </Card.Header>
                  <Card.Body>
                    <div className="job-type-title">
                      <Card.Subtitle className="mb-2 text-muted search-job-desc">
                        <div
                          dangerouslySetInnerHTML={createMarkup(jobs?.description || "")}
                          className="job-desc-block qualification-section-block"
                        ></div>
                      </Card.Subtitle>
                    </div>
                  </Card.Body>
                </Card>
              ))}
            {error && (
              <div className="error-msg search-error-container">
                <p>{error}</p>
              </div>
            )}
            {!loading && !error && (
              <Pagination className="pagination-data float-right bottom-pagination">
                {Math.sign(previous) >= 0 && <Pagination.Prev onClick={() => handlePrevious()} />}
                {hasMore && <Pagination.Next onClick={() => handleSearch(start)} />}
              </Pagination>
            )}
          </Col>
        </Row>
      </div>
      {showSearchAlert && (
      <SearchJobNotFound
          title={"Job not found"}
          message={"The job opening you are looking for does not exists or it has been closed"}
          handleShowSearchAlert={handleShowSearchAlert}
        />
      )}
    </div>
  );
};
export default compose(withRouter, withFirebase)(Search);
