import { SaveSessionExpiry } from "../../util";
import { LOGIN_METHOD_GOOGLE, LOGIN_METHOD_FACEBOOK, LOGIN_METHOD_APPLE } from "../../constants/keys";
import { firebaseAuth } from "./firebase";

export const loginWithPwd = async (firebase, email, password, recaptchaContainerId) => {
  return new Promise((resolve, reject) => {
    firebase
      .recaptchaVerifier(recaptchaContainerId)
      .then(() => {
        firebase
          .doSignInWithEmailAndPassword(email, password)
          .then(async (response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const appLoginWithGoogle = async (firebase) => {
  return new Promise((resolve, reject) => {
    firebase
      .loginWithGoogle()
      .then((authUser) => {
        SaveSessionExpiry(true, LOGIN_METHOD_GOOGLE);
        resolve(authUser);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const appLoginWithFacebook = async (firebase) => {
  return new Promise((resolve, reject) => {
    firebase
      .loginWithFacebook()
      .then(async (authUser) => {
        SaveSessionExpiry(true, LOGIN_METHOD_FACEBOOK);
        resolve(authUser);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const appLoginWithApple = async (firebase) => {
  return new Promise((resolve, reject) => {
    firebase
      .loginWithApple()
      .then(async (authUser) => {
        SaveSessionExpiry(true, LOGIN_METHOD_APPLE);
        resolve(authUser);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const appFirebaseAuthStateChanged = async (firebase) => {
  return new Promise((resolve, reject) => {
    firebase.auth.onAuthStateChanged((firebaseAuthUser) => {
      firebaseAuthUser
        .getIdToken()
        .then(async (response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  });
};

export const appFirebaseAuthStateRefresh = async (firebase) => {
  return new Promise((resolve, reject) => {
    firebase.auth.onAuthStateChanged((firebaseAuthUser) => {
      firebaseAuthUser
        .getIdToken(true)
        .then(async (response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  });
};

export const appFirebaseReauthentincation = async (firebase, email, password) => {
  return new Promise((resolve, reject) => {
    firebase
      .auth()
      .currentUser()
      .reauthenticateWithCredential({ email, password })
      .then(function (result) {
        resolve(result);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const appSendVarificationEmail = async (firebase, recaptchaContainerId) => {
  return new Promise((resolve, reject) => {
    firebase
      .recaptchaVerifier(recaptchaContainerId)
      .then(() => {
        firebase
          .sendVarificationEmail()
          .then(async (response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getToken = async () => {
  try {
    return await firebaseAuth?.currentUser?.getIdToken(false);
  } catch (e) {
    return null;
  }
};
