import React, { useEffect, useState, useRef, useMemo } from "react";
import { Modal } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import { compose } from "recompose";
import { useSelector, useDispatch } from "react-redux";
import { withFirebase } from "../../components/Firebase";
import LoadingIndicator from "../../components/LoadingIndicator";
import useEnterKeyListener from "../../helpers/useEnterKeyListener";
import { AuthUserContext, withAuthorization } from "../../components/Session";
import { GetValue, ClearAllStoredData } from "../../util/DataRepositry";
import {
  EMAIL,
  SEND_VERIFICATION_EMAIL,
  INTRODUCTION,
  SKILLS,
  SKILLS_INTRODUCTION,
  RESUME,
  VIDEO,
  COVER_LETTER,
  VIDEO_INTRODUCTION,
  ADD_PERSONAL_INFO,
} from "../../constants/keys";
import { errorCode, errorMessage } from "../../util/Validatios";
import EmailVerifiedModal from "../EmailVerifiedModal";
import Home from "../Home";
import {
  IS_AUTH,
  IS_EMAIL_VERIFY,
  UPLOAD_RESUME_AUTH,
  EMAIL_CONFIRMATION_DETAIL,
} from "../../redux/constants/auth.constants";
import { APPLY_JOB_STEP, IS_UPLOAD_RESUME } from "../../redux/constants/jobs.constants";
import * as ROUTES from "../../constants/routes";
import { appSendVarificationEmail } from "../../components/Firebase/fbAuthentication";
import { setDocSteps } from "../../redux/actions/jobs.action";
import { fbSetScreenName, fbSetEvent, fbSetParams } from "../../Action/firebaseAnalytics";
import {
  OPEN_SCREEN,
  EMAIL_VERIFY_SCREEN,
  FIREBASEAUTH_ERROR,
  CAPTCHA_FAILURE,
} from "../../constants/firebaseAnalytics";
import "./index.css";

const EmailConfirmation = () => (
  <AuthUserContext.Consumer>
    {(authUser) => <EmailVerificationMessageScreen authUser={authUser} />}
  </AuthUserContext.Consumer>
);

const EmailVerificationMessage = ({ firebase, history, authUser, location }) => {
  const [error, setError] = useState("");
  const [showEmailConf, setShowEmailConf] = useState(false);
  const [modalCloseStatus, setModalCloseStatus] = useState("close-left");
  const [loading, setLoading] = useState(false);
  const [emailVerified, setEmailVerfied] = useState(false);
  const [isResend, setIsResend] = useState(false);
  const [emailVeriMsg, setEmailVeriMsg] = useState("");
  const emailConfCaptchaRef = useRef(null);
  const dispatch = useDispatch();
  const jobEmailVerify = useSelector((state) => state.auth.isEmailVerify);
  const uploadEmailVerify = useSelector((state) => state.auth.isUpdEmailVerify);
  const isAuth = useSelector((state) => state.auth.isAuth);
  const isAppliedSearchJob = useSelector((state) => state.job.isAppliedSearchJob);
  const emailConfirmTitle = useSelector((state) => state.auth.emailConfirmTitle);
  const emailConfirmMsg = useSelector((state) => state.auth.emailConfirmMsg);
  const jobStepsDetails = useSelector((state) => state.job.jobStepsDetails);

  useEffect(() => {
    setEmailVeriMsg(location?.state?.messageTitle || emailConfirmTitle || "");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderNextStep = () => {
    // const stepIndex = jobStepsDetails.findIndex((x) => x.screen === steps);
    const screenName = jobStepsDetails[0]?.screen;
    dispatch(setDocSteps(screenName));

    switch (screenName) {
      case INTRODUCTION:
        return INTRODUCTION;
      case SKILLS:
      case SKILLS_INTRODUCTION:
      case RESUME:
      case COVER_LETTER:
      case VIDEO_INTRODUCTION:
      case VIDEO:
        return SKILLS;
      default:
        return ADD_PERSONAL_INFO;
    }
  };

  const handleJobAuth = async () => {
    await dispatch({
      type: IS_AUTH,
      payload: false,
    });

    if (!isAuth && emailConfirmTitle === "") {
      await dispatch({
        type: APPLY_JOB_STEP,
        payload: renderNextStep(),
      });
    }

    await dispatch({
      type: IS_EMAIL_VERIFY,
      payload: false,
    });
  };

  const handleUploadAuth = async () => {
    await dispatch({
      type: UPLOAD_RESUME_AUTH,
      payload: false,
    });

    await dispatch({
      type: IS_UPLOAD_RESUME,
      payload: true,
    });
  };

  const loaderHandler = (status) => {
    setLoading(status);
  };

  const handleResetCaptchaContainer = () => {
    if (emailConfCaptchaRef?.current?.innerHTML) {
      emailConfCaptchaRef.current.innerHTML = `<div id="emailConfRecaptcha" className="recaptcha-container"></div>`;
    }
  };

  const handleResendEmail = () => {
    setIsResend(false);
    setError("");
    loaderHandler(true);
    appSendVarificationEmail(firebase, "emailConfRecaptcha")
      .then((res) => {
        handleResetCaptchaContainer();
        loaderHandler(false);
        setIsResend(true);
      })
      .catch((e) => {
        handleResetCaptchaContainer();
        handleFBEvents(FIREBASEAUTH_ERROR, {
          method: SEND_VERIFICATION_EMAIL,
          message: e.message || "",
        });

        if (e.code === errorCode.toManyRequest) {
          setError(errorMessage.toManyRequest);
        } else if (!e.code && e.indexOf(CAPTCHA_FAILURE) !== -1) {
          handleFBEvents(CAPTCHA_FAILURE, {});
        } else {
          setError(errorMessage.others);
        }

        loaderHandler(false);
      });
  };

  const handleResetModal = (status) => {
    setShowEmailConf(status);
  };

  const handleModalCloseState = (status) => {
    setModalCloseStatus(status);
  };

  const handleCloseConfModal = async () => {
    authUser.reload().then(async (ok) => { 
      if (authUser.emailVerified) { //user is verified
        setModalCloseStatus("close-left");
        setShowEmailConf(false);

        if (jobEmailVerify) {
          handleJobAuth();
        } else if (uploadEmailVerify) {
          handleUploadAuth();
        } else {
          setEmailVerfied(true);
        }
      } else { //user is not verified: logout
        handleModalCloseState("close-left");
        await firebase.doSignOut();
        ClearAllStoredData();
        //window.location.replace(ROUTES.HOME);
      }
    });
  };

  const handleMailBackDrop = () => {
    handleResetModal(false);
    handleCloseConfModal();
  };

  useEffect(() => {
    setShowEmailConf(true);
  }, []);

  useMemo(() => {
    if (emailConfirmTitle) {
      setEmailVeriMsg(emailConfirmTitle);
    }
  }, [emailConfirmTitle]);

  const handleFBEvents = (eventName, params) => {
    dispatch(fbSetParams(params));
    dispatch(fbSetEvent(eventName));
  };

  const handlefbSetScreen = () => {
    dispatch(fbSetScreenName(EMAIL_VERIFY_SCREEN));
    handleFBEvents(OPEN_SCREEN, {});
  };

  useEffect(() => {
    if (showEmailConf) {
      handlefbSetScreen();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showEmailConf]);

  useEnterKeyListener({
    querySelectorToExecuteClick: "#EmailConfimationBtn",
  });

  return (
    <>
      {!isAppliedSearchJob && (
        <iframe
          title="This is a unique title"
          src="/index.html#home-main-container"
          width="100%"
          align="center"
          scrolling="no"
          className="homepage-iframe"
        />
      )}
      <Modal
        centered
        show={showEmailConf}
        onHide={() => handleMailBackDrop()}
        className={`auth-modal login-modal ${showEmailConf ? "right" : modalCloseStatus}`}
      >
        <Modal.Header>
          <div className="reset-password-back">
            <span
              onClick={() => {
                handleModalCloseState("close-right");
                handleResetModal(false);
              }}
            ></span>
          </div>
        </Modal.Header>
        <Modal.Body className="auth-modal-body">
          {loading && <LoadingIndicator loaderStyle="homePageLoader" />}
          <div ref={emailConfCaptchaRef} className="recaptcha-container">
            <div id="emailConfRecaptcha" className="recaptcha-container"></div>
          </div>
          <div>
            <div className="email-confirm-title">
              {emailVeriMsg !== "" ? emailVeriMsg : "Verify your email address"}
            </div>

            <div className="email-cnfrm-subtitle">
              {emailConfirmMsg ||
                ` We have sent an email to ${GetValue(
                  EMAIL,
                )} with details on how to verify your account. Please check your
              inbox and follow the instructions in the email.`}
            </div>

            <div className="lbl-resend-email">
              {isResend && `We just resent the email. Please, check your spam folder too.`}
            </div>

            {error && (
              <div className="error-msg">
                <p>{error}</p>
              </div>
            )}

            <div className="login-btn btn-confirm-container">
              <button
                type="button"
                id="EmailConfimationBtn"
                className="btn-resend-email"
                onClick={() => handleResendEmail()}
              >
                resend email
              </button>

              <button
                type="button"
                id="EmailConfimationBtnClose"
                className="btn-close-confirm"
                onClick={() => handleCloseConfModal()}
              >
                close
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {emailVerified && <EmailVerifiedModal />}
    </>
  );
};

const condition = (authUser) => !!authUser;
const EmailVerificationMessageScreen = compose(
  withRouter,
  withFirebase,
  withAuthorization(condition),
)(EmailVerificationMessage);

export default EmailConfirmation;
