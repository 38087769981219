import {
  SESSION_EXPIRE,
  IS_AUTH,
  AUTH_STATUS,
  IS_EMAIL_VERIFY,
  UPLOAD_RESUME_AUTH,
  IS_UPLOAD_EMAIL_VERIFY,
  SWITCH_ACCOUNT,
  IS_UPD_RESUME_AUTH,
  EMAIL_CONFIRMATION_DETAIL,
} from "../constants/auth.constants";

const INITIAL_STATE = {
  isSessionExpire: false,
  isAuth: false, // this is used for open auth modal when user click on switch account on apply job steps
  authStatus: "",
  isEmailVerify: false,
  isUpdResumeAuth: false,
  isUpdEmailVerify: false,
  switchAccount: false,
  authUploadResume: false,
  emailConfirmTitle: "",
  emailConfirmMsg: "",
};

const authReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SESSION_EXPIRE:
      return {
        ...state,
        isSessionExpire: action.payload,
      };
    case IS_AUTH:
      return {
        ...state,
        isAuth: action.payload,
      };
    case AUTH_STATUS:
      return {
        ...state,
        authStatus: action.payload,
      };
    case IS_EMAIL_VERIFY:
      return {
        ...state,
        isEmailVerify: action.payload,
      };
    case UPLOAD_RESUME_AUTH:
      return {
        ...state,
        isUpdResumeAuth: action.payload,
      };
    case IS_UPLOAD_EMAIL_VERIFY:
      return {
        ...state,
        isUpdEmailVerify: action.payload,
      };
    case SWITCH_ACCOUNT:
      return {
        ...state,
        switchAccount: action.payload,
      };
    case IS_UPD_RESUME_AUTH:
      return {
        ...state,
        authUploadResume: action.payload,
      };
    case EMAIL_CONFIRMATION_DETAIL:
      return {
        ...state,
        emailConfirmTitle: action?.payload?.emailTitle,
        emailConfirmMsg: action?.payload?.emailMsg,
      };
    default:
      return state;
  }
};

export default authReducer;
