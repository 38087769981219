import React from "react";
import { Switch, Route, withRouter } from "react-router-dom";
import { FirebaseContext } from "../Firebase";
import Slider from "./index";

const _SlideOut = (props) => (
  <FirebaseContext.Consumer>
    {(firebase) => <SlideOut {...props} firebase={firebase} />}
  </FirebaseContext.Consumer>
);

class SlideOut extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      childPosition: Slider.CENTER,
      curChild: props.children,
      curUniqId: props.uniqId,
      prevChild: null,
      prevUniqId: null,
      animationCallback: null,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    const prevUniqId = prevProps.uniqKey || prevProps.children.type;
    let uniqId = this.props.uniqKey || this.props.children.type;
    if (uniqId === "/re-authenticate") {
      setTimeout(() => {
        if (prevUniqId !== uniqId) {
          console.log("updaing screen");
          this.setState({
            childPosition: Slider.TO_LEFT,
            curChild: this.props.children,
            curUniqId: uniqId,
            prevChild: prevProps.children,
            prevUniqId,
            animationCallback: () => {
              this.swapChildren(prevUniqId, uniqId);
            },
          });
        }
      }, 1000);
    } else {
      if (prevUniqId !== uniqId) {
        console.log("updaing screen");
        this.setState({
          childPosition: Slider.TO_LEFT,
          curChild: this.props.children,
          curUniqId: uniqId,
          prevChild: prevProps.children,
          prevUniqId,
          animationCallback: () => {
            this.swapChildren(prevUniqId, uniqId);
          },
        });
      }
    }
  }

  swapChildren = (prevUniqId, uniqId) => {
    console.log("swapChildren called");
    this.setState({
      childPosition: Slider.FROM_RIGHT,
      prevChild: null,
      prevUniqId: null,
      animationCallback: null,
    });
  };

  render() {
    const { location } = this.props.children.props;

    if (location && location.state && location.state.isStopAnimation) {
      return <>{this.state.curChild}</>;
    }
    return (
      <Slider
        position={this.state.childPosition}
        animationCallback={this.state.animationCallback}
      >
        {this.state.prevChild || this.state.curChild}
      </Slider>
    );
  }
}

const animateSwitch = (CustomSwitch, AnimatorComponent) => ({
  updateStep,
  children,
}) => (
  <Route
    render={({ location }) => (
      <AnimatorComponent uniqKey={location.pathname} updateStep={updateStep}>
        <CustomSwitch location={location}>{children}</CustomSwitch>
      </AnimatorComponent>
    )}
  />
);

const SwitchWithSlide = animateSwitch(Switch, _SlideOut);

export default withRouter(SwitchWithSlide);
