import React, { useEffect, useState, useRef, useLayoutEffect, lazy } from "react";
import { useSelector, useDispatch } from "react-redux";
import { withRouter, useLocation } from "react-router-dom";
import { compose } from "recompose";
import { withFirebase } from "../../components/Firebase";
import * as ROUTES from "../../constants/routes";
import { AuthUserContext } from "../Session";
import {
  FIRST_NAME,
  LAST_NAME,
  KEEP_ME_LOGGED_IN,
  SESSION_LIMIT,
  AUTH_TOKEN,
  SESSION_EXPIRATION,
  SESSION_COUNTDOWN_LIMIT,
  EMPLOYERS_BASE_URL,
  IS_SESSION_EXPIRED,
} from "../../constants/keys";
import { GetValue, SetValue } from "../../util/DataRepositry";
import useEnterKeyListener from "../../helpers/useEnterKeyListener";
import { fetchUserInformation, updateUserInformation } from "../../Action/authentication";
import { appliedSearchJob } from "../../redux/actions/jobs.action";
import {
  SESSION_WARNING,
  EXTEND_SESSION,
  SESSION_EXPIRED,
  IDLE_TIMEOUT,
  USER_ERROR,
  FIREBASEAUTH_ERROR,
  APPLY_JOB, 
  APP_BUTTON,
} from "../../constants/firebaseAnalytics";
import { IS_UPLOAD_RESUME } from "../../redux/constants/jobs.constants";
import { UPLOAD_RESUME_AUTH } from "../../redux/constants/auth.constants";
import { getToken } from "../../components/Firebase/fbAuthentication";
import Loadable from "../../components/Loadable";

import "./responsive.css";
import "./index.css";
import "../../pages/ApplyForJob/AddPersonalDetails/index.css";
import "../../pages/ResetPasswordModal/index.css";
import "../../pages/ApplyForJob/JobOptionSelection/index.css";
import "../../pages/ApplyForJob/JobDescription/index.css";
import "../../pages/ApplyForJob/DocumentSubmit/index.css";

const AuthModal = Loadable(lazy(() => import("../../pages/AuthModal")));
const SignInModal = Loadable(lazy(() => import("../../pages/SignInModal")));
const SignUpModal = Loadable(lazy(() => import("../../pages/SignUpModal")));
const ForgotPassModal = Loadable(lazy(() => import("../../pages/ForgotPasswordModal")));
const Settings = Loadable(lazy(() => import("../../pages/Settings")));
const SessionExpiration = Loadable(lazy(() => import("../../pages/SessionExpiration")));
const UploadResume = Loadable(lazy(() => import("../../pages/UploadResume")));
const ApplyForJob = Loadable(lazy(() => import("../../pages/ApplyForJob")));
const SessionExpiredAlert = Loadable(lazy(() => import("../../pages/SessionExpiredAlert")));
const DocumentList = Loadable(lazy(() => import("../../pages/DocumentList")));

const Navigation = () => {
  return (
    <>
      <AuthUserContext.Consumer>
        {(authUser) => <NavBar user={authUser} isAuthenticated={authUser != null} />}
      </AuthUserContext.Consumer>
    </>
  );
};

const TopNavBar = (props) => {
  const [firstName, setFirstname] = useState(GetValue(FIRST_NAME));
  const [lastName, setLastname] = useState(GetValue(LAST_NAME));
  const [authToken, setAuthToken] = useState(GetValue(AUTH_TOKEN));
  const [showMenu, setShowMenu] = useState(false);
  const [show, setShow] = useState(false);
  const [showSignIn, setShowSignIn] = useState(false);
  const [showSignUp, setShowSignUp] = useState(false);
  const [showApplyForJob, setShowApplyForJob] = useState(false);
  const [jobIdToApply, setJobIdToApply] = useState("");
  const [loading, setLoading] = useState(false);
  const [settings, setSettings] = useState("");
  const [authStatus, setAuthStatus] = useState("signin");
  const [modalCloseStatus, setModalCloseStatus] = useState("close-right");
  const [modalOpenStatus, setModalOpenStatus] = useState("right");
  const [showForgotPass, setShowForgotPass] = useState(false);
  const [isSessionExpiring, setIsSessionExpiring] = useState(false);
  const [showSesExpAlert, setShowSesExpAlert] = useState(false);
  const [activeMenu, setActiveMenu] = useState("");
  const [submissionId, setSubmissionId] = useState("authSubBtn");
  const [expanded, setExpanded] = useState(false);
  const [showSupport, setShowSupport] = useState(false);
  const [showUpdDoc, setShowUpdDoc] = useState(false);
  const [viewUploadedDoc, setViewUploadedDoc] = useState(false);
  const location = useLocation();

  const profileDetail = useSelector((state) => state.profile.profileDetail);
  const screenName = useSelector((state) => state.fbAnalytics.screenName);
  const fbEventName = useSelector((state) => state.fbAnalytics.eventName);
  const fbEventParams = useSelector((state) => state.fbAnalytics.eventParams);
  const isAuth = useSelector((state) => state.auth.isAuth);
  const authMethod = useSelector((state) => state.auth.authStatus);
  const showUpdResume = useSelector((state) => state.job.showResumeUpload);
  const isUpdResumeAuth = useSelector((state) => state.auth.isUpdResumeAuth);
  const authUploadResume = useSelector((state) => state.auth.authUploadResume);
  const isAppliedSearchJob = useSelector((state) => state.job.isAppliedSearchJob);

  const warningTimerRef = useRef(null);
  const dispatch = useDispatch();
  let isPageVisible = true;

  const isMobile = window.innerWidth <= 425;

  const isXLMobile = window.innerWidth <= 991;

  const loaderHandler = (status) => {
    setLoading(status);
  };

  function handleHideLogs() {}

  if (process.env.REACT_APP_ENABLE_LOG !== "true") {
    console.log = handleHideLogs;
    console.warn = handleHideLogs;
    console.error = handleHideLogs;
  }

  const handleModalCloseState = (closeStatus) => {
    setModalCloseStatus(closeStatus);
  };

  const handleModalOpenState = (openStatus) => {
    setModalOpenStatus(openStatus);
  };

  const handleClose = () => setShow(false);

  const handleShow = () => setShow(true);

  const handleAuthModal = (status) => {
    handleModalCloseState("close-left");
    handleClose();
    setShow(status);
  };

  const handleSignInModal = (status, modalStatus) => {
    handleModalCloseState(modalStatus || "close-left");
    handleClose();
    setShowSignIn(status);
  };

  const handleSignUpModal = (status, modalStatus) => {
    handleModalCloseState(modalStatus || "close-left");
    handleClose();
    setShowSignUp(status);
  };

  const handleCloseSettingStatus = (status) => {
    setSettings(status);
  };

  const handleForgotPassModal = (status, modalStatus) => {
    handleModalCloseState(modalStatus || "close-left");
    // handleSignInModal(false);
    setShowForgotPass(status);
  };

  const handleSettingModal = (status) => {
    setSettings(status);
    setShowMenu(false);
  };

  const handleApplyModal = async (status, jobId) => {
    if (status == true) {
      handleFbEvents(APP_BUTTON, {
        cta: APPLY_JOB,
      });
    }
    dispatch(appliedSearchJob(true, jobId));
    setJobIdToApply(jobId);
    setShowApplyForJob(status);
  };

  const handleFbEvents = (eventName, eventParams) => {
    eventParams.viewType = isMobile ? "mobile" : "desktop";
    props.firebase.analytics.logEvent(eventName, eventParams);
  };

  const handleSubmissionId = (id) => {
    setSubmissionId(id);
  };

  function handleSessionWarning() {
    console.log("start countdown warning");
    document.removeEventListener("mousedown", handleUserDidSomething);

    if (GetValue(AUTH_TOKEN)) {
      setIsSessionExpiring(true);
    }
  }

  let debounceTime = 0;
  const handleUserDidSomething = async () => {
    if (warningTimerRef.current && GetValue(AUTH_TOKEN)) {
      const currentTime = new Date().getTime();
      if (currentTime > debounceTime + 1000) {
        // execute only once a second
        console.log("user did something, extending the session time");
        debounceTime = currentTime;
        clearTimeout(warningTimerRef.current); // remove existing timer
        // set new timer
        warningTimerRef.current = setTimeout(handleSessionWarning, (SESSION_LIMIT - SESSION_COUNTDOWN_LIMIT) * 1000);

        if (GetValue(AUTH_TOKEN)) {
          await SetValue(SESSION_EXPIRATION, currentTime + SESSION_LIMIT * 1000);
        }
      }
    }
  };

  const handleExtendSession = () => {
    handleFbEvents(EXTEND_SESSION, {});
    setIsSessionExpiring(false);
    document.addEventListener("mousedown", handleUserDidSomething);
    const currentTime = new Date().getTime();
    if (warningTimerRef.current) {
      clearTimeout(warningTimerRef.current); // remove existing timer
    }
    // set new timer
    warningTimerRef.current = setTimeout(handleSessionWarning, (SESSION_LIMIT - SESSION_COUNTDOWN_LIMIT) * 1000);

    SetValue(SESSION_EXPIRATION, currentTime + SESSION_LIMIT * 1000);
  };

  const handlePageVisible = () => {
    if (GetValue(AUTH_TOKEN) && GetValue(KEEP_ME_LOGGED_IN) !== "true") {
      //if user is logged in and login is not persisted
      console.log("user is logged in");
      const expiration = GetValue(SESSION_EXPIRATION);
      const currentTime = new Date().getTime();
      if (currentTime > expiration) {
        //user is resuming the app but the session has expired
        console.log("session expired in the background");
        warningTimerRef.current = null;
        document.removeEventListener("mousedown", handleUserDidSomething);
        handleLogOut();
      } else {
        console.log("session is still valid, resetting the timeout and the user activity listener");
        if (warningTimerRef.current) {
          clearTimeout(warningTimerRef.current);
        }
        warningTimerRef.current = setTimeout(
          handleSessionWarning,
          expiration - currentTime - SESSION_COUNTDOWN_LIMIT * 1000,
        );
        document.addEventListener("mousedown", handleUserDidSomething);
      }
    }
  };

  const handleVisibility = () => {
    console.log("isVisible: " + !document.hidden);
    if (isPageVisible !== true && !document.hidden) {
      // page going from invisible to visible
      handlePageVisible();
    } else if (isPageVisible !== false && document.hidden) {
      // page going from visible to invisible
      // reset the timer and the user activity listener
      setIsSessionExpiring(false);
      console.log("app going to the background, remove the timer and the activity listener");
      if (warningTimerRef.current) {
        clearTimeout(warningTimerRef.current);
        warningTimerRef.current = null;
      }
      document.removeEventListener("mousedown", handleUserDidSomething);
    }
    isPageVisible = !document.hidden;
  };

  function usePageVisibility() {
    useLayoutEffect(() => {
      document.addEventListener("visibilitychange", handleVisibility);
      return () => {
        document.removeEventListener("visibilitychange", handleVisibility);
      };
    }, []);

    return { isPageVisible };
  }

  const handleLogOut = async () => {
    handleFbEvents(SESSION_EXPIRED, { expirationType: IDLE_TIMEOUT });
    document.removeEventListener("visibilitychange", handleVisibility);
    window.handleLogoutAuthUser();
    props.firebase.doSignOut();
  };

  const handleShowUpdResume = async () => {
    await dispatch({ type: IS_UPLOAD_RESUME, payload: false });
    await dispatch({ type: UPLOAD_RESUME_AUTH, payload: false });
    if (!authToken && !firstName) {
      await dispatch({ type: UPLOAD_RESUME_AUTH, payload: true });
      setAuthStatus("signup");
      handleShow();
    } else {
      await dispatch({ type: IS_UPLOAD_RESUME, payload: true });
      await dispatch({ type: UPLOAD_RESUME_AUTH, payload: false });
    }
  };

  const getUserIdToken = async () => {
    const localAuthToken = await getToken();
    setAuthToken(localAuthToken);
  };

  useEffect(() => {
    if (isAuth && authMethod) {
      setAuthStatus(authMethod);
      handleShow();
    }
  }, [isAuth, authMethod]);

  useEffect(() => {
    if (props.user != null && firstName == null) {
      let interval = setInterval(() => {
        if (GetValue(FIRST_NAME) == null) {
          return;
        }
        setFirstname(GetValue(FIRST_NAME));
        setLastname(GetValue(LAST_NAME));
        clearInterval(interval);
      }, 500);
    }
  });

  useEffect(() => {
    if (profileDetail && profileDetail.firstName && profileDetail.lastName) {
      setFirstname(profileDetail.firstName || GetValue(FIRST_NAME));
      setLastname(profileDetail.lastName || GetValue(LAST_NAME));
      handlePageVisible();
    }
  }, [profileDetail]);

  useEffect(() => {
    if (props?.user) {
      getUserIdToken();
      setFirstname(GetValue(FIRST_NAME));
      setLastname(GetValue(LAST_NAME));
      handlePageVisible();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props?.user]);

  useEffect(() => {
    if (screenName) {
      console.log("screen name -->>", screenName);
      props.firebase.analytics.setCurrentScreen(screenName);
      handleSubmissionId(screenName);
    }
  }, [props.firebase.analytics, screenName]);

  useEffect(() => {
    const isUserErrorMsg = fbEventParams && "message" in fbEventParams;
    fbEventParams.viewType = isMobile ? "mobile" : "desktop";
    if (fbEventName !== "" && fbEventName !== USER_ERROR && fbEventName !== FIREBASEAUTH_ERROR && !isUserErrorMsg) {
      console.log("screen logEvent -->>", fbEventName, fbEventParams);
      props.firebase.analytics.logEvent(fbEventName, fbEventParams);
    }

    if (
      fbEventName &&
      fbEventParams.message !== "" &&
      isUserErrorMsg &&
      (fbEventName === USER_ERROR || fbEventName === FIREBASEAUTH_ERROR)
    ) {
      console.log("screen logEvent user err -->>", fbEventName, fbEventParams);
      props.firebase.analytics.logEvent(fbEventName, fbEventParams);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.firebase.analytics, fbEventName, fbEventParams]);

  useEffect(() => {
    if (isUpdResumeAuth || authUploadResume) {
      handleShow();
    }
  }, [isUpdResumeAuth, authUploadResume]);

  useEffect(() => {
    setActiveMenu(location.pathname);
    if (location?.pathname === ROUTES.UPLOAD_YOUR_RESUME) {
      handleShowUpdResume();
    }
  }, [location.pathname]);

  useEffect(() => {
    if (activeMenu === ROUTES.SUPPORT) {
      setShowSupport(true);
    } else {
      setShowSupport(false);
    }
  }, [activeMenu]);

  useEffect(() => {
    if (isSessionExpiring) {
      handleFbEvents(SESSION_WARNING, {});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSessionExpiring]);

  usePageVisibility();

  useEnterKeyListener({
    querySelectorToExecuteClick: `#${submissionId}`,
  });

  window.handleSettings = () => {
    handleSettingModal(true);
  };

  window.handleResume = () => {
    setShowUpdDoc(true);
    setViewUploadedDoc("resume")
  };

  window.showUploadResume = () => {
    handleShowUpdResume();
  };

  window.gotoHome = () => {
    window.location.href = ROUTES.HOME;
  };

  window.gotoSearch = () => {
    window.location.href = ROUTES.SEARCH;
  };

  window.gotoPostJobs = () => {
    window.location.href = EMPLOYERS_BASE_URL + ROUTES.POST_JOBS;
  };

  window.showAuth = () => {
    handleAuthModal(true);
  };

  window.showApply = (jobId) => {
    handleApplyModal(!showApplyForJob, jobId);
  };

  window.logoutAuth = () => {
    handleLogOut();
  };

  if(GetValue(IS_SESSION_EXPIRED) === "true") {
    SetValue(IS_SESSION_EXPIRED, "false"); //display message only once
    const currentTime = new Date().getTime();
    const expiration = parseInt(GetValue(SESSION_EXPIRATION));
    if (currentTime < expiration + 12 * 60 * 60 * 1000) { //display message only in the first 12 hours after expiration
      setShowSesExpAlert(true);
    }
  }

  return (
    <>
      <AuthModal
        show={show}
        handleClose={handleClose}
        handleShow={handleShow}
        handleSignInModal={handleSignInModal}
        handleAuthModal={handleAuthModal}
        handleSignUpModal={handleSignUpModal}
        authStatus={authStatus}
        updateUserInformation={updateUserInformation}
        handleModalCloseState={handleModalCloseState}
        modalCloseStatus={modalCloseStatus}
        handleModalOpenState={handleModalOpenState}
        modalOpenStatus={modalOpenStatus}
        submissionId={submissionId}
        handleSubmissionId={handleSubmissionId}
      />

      <SignInModal
        showSignIn={showSignIn}
        handleSignInModal={handleSignInModal}
        handleShow={handleShow}
        updateUserInformation={updateUserInformation}
        fetchUserInformation={fetchUserInformation}
        loaderHandler={loaderHandler}
        loading={loading}
        handleModalCloseState={handleModalCloseState}
        modalCloseStatus={modalCloseStatus}
        handleModalOpenState={handleModalOpenState}
        modalOpenStatus={modalOpenStatus}
        handleForgotPassModal={handleForgotPassModal}
        submissionId={submissionId}
        handleSubmissionId={handleSubmissionId}
      />

      <SignUpModal
        showSignUp={showSignUp}
        handleSignUpModal={handleSignUpModal}
        handleShow={handleShow}
        updateUserInformation={updateUserInformation}
        loaderHandler={loaderHandler}
        loading={loading}
        handleModalCloseState={handleModalCloseState}
        modalCloseStatus={modalCloseStatus}
        handleModalOpenState={handleModalOpenState}
        modalOpenStatus={modalOpenStatus}
        submissionId={submissionId}
        handleSubmissionId={handleSubmissionId}
      />

      <ForgotPassModal
        showForgotPass={showForgotPass}
        handleSignInModal={handleSignInModal}
        handleShow={handleShow}
        handleForgotPassModal={handleForgotPassModal}
        updateUserInformation={updateUserInformation}
        loaderHandler={loaderHandler}
        loading={loading}
        handleModalCloseState={handleModalCloseState}
        modalCloseStatus={modalCloseStatus}
        handleModalOpenState={handleModalOpenState}
        modalOpenStatus={modalOpenStatus}
        submissionId={submissionId}
        handleSubmissionId={handleSubmissionId}
      />

      {showUpdResume && <UploadResume />}

      {settings && <Settings handleCloseSettingStatus={handleCloseSettingStatus} />}
      {showUpdDoc && <DocumentList setShowResumeDialog={setShowUpdDoc} showResumeDialog={showUpdDoc} viewUploadedDoc={viewUploadedDoc} />}
      {showApplyForJob && isAppliedSearchJob && <ApplyForJob jobId={jobIdToApply} setShowApplyForJob={setShowApplyForJob} />}

      {isSessionExpiring && <SessionExpiration handleExtendSession={handleExtendSession} handleLogOut={handleLogOut} />}

      {showSesExpAlert && <SessionExpiredAlert message="the previous session has expired and you have been logged out" />}
    </>
  );
};

const NavBar = compose(withRouter, withFirebase)(TopNavBar);

export default Navigation;
