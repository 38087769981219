import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { ErrorBoundary } from "react-error-boundary";
import Navigation from "../components/Navigation";
import HomePage from "../pages/Home";
import Footer from "../components/Footer";
import PrivacyPolicy from "../pages/PrivacyPolicy";
import TermsCondition from "../pages/TermsCondition";
import Support from "../pages/Support";
import HowItWorks from "../pages/HowItWorks";
import ScrollToTop from "../components/ScrollToTop";
import AccountPage from "../pages/Account";
import ExternalLinks from "../pages/ExternalLinks";
import SwitchWithSlide from "../components/Slider/SwitchWithSlide";
import SignOut from "../pages/SignOut";
import ApplyForJob from "../pages/ApplyForJob";
import ResetPassword from "../pages/ResetPasswordModal";
import QrApply from "../pages/QrApply";
import EmailConfirmationModal from "../pages/EmailConfirmationModal";
import AccountConfirmation from "../pages/AccountConfirmation";
import Search from "../pages/Search";
import SearchResults from "../pages/Search/searchResults";
import SearchJobDescription from "../pages/Search/searchJobDescription";
import SearchExpand from "../pages/SearchExpand";
import SearchJobDetail from "../pages/SearchJobDetail";
import Job from "../pages/Job";
import NotFound from "../pages/NotFound";
import * as ROUTES from "../constants/routes";
import { withAuthentication } from "../components/Session";
import { firebaseWebAppError } from "../helpers/useWebAppError";
import "./index.css";
import "bootstrap/dist/css/bootstrap.min.css";
import '../css/UserManagement.css';

const App = (props) => {
  return (
    <BrowserRouter>
      <>
        <Navigation />
        <ScrollToTop />
        <Switch>
          <Route exact path={ROUTES.EMAIL_CONFIRMATION} component={EmailConfirmationModal} />
          <Route exact path={ROUTES.APPLYING_FOR_JOB} component={ApplyForJob} />
          <Route exact path={ROUTES.HOME} component={HomePage} />
          <Route exact path={ROUTES.UPLOAD_YOUR_RESUME} component={HomePage} />
          <Route exact path={ROUTES.PRIVACY_POLICY} component={PrivacyPolicy} />
          <Route exact path={ROUTES.TERMS_CONDITION} component={TermsCondition} />
          <Route exact path={ROUTES.SUPPORT} component={Support} />
          <Route exact path={ROUTES.ACCOUNT_CONFIRM} component={AccountConfirmation} />
          <Route exact path={ROUTES.QR_APPLY + '/:id'} component={QrApply} />
          <Route exact path={ROUTES.SEARCH} component={Search} />
          <Route exact path={ROUTES.SEARCH_EXPAND} component={SearchExpand} />
          <Route exact path={ROUTES.JOB} component={Job} />
          {/* <Route exact path={ROUTES.JOB} component={SearchJobDetail} /> */}
          <Route exact path={ROUTES.JOBS} component={Job} />
          <Route exact path={ROUTES.SEARCH_RESULTS} component={SearchResults} />
          <Route exact path={ROUTES.SEARCH_JOB_DESCRIPTION} component={SearchJobDescription} />
          <SwitchWithSlide>
            <Route exact path={ROUTES.SIGN_OUT} component={SignOut} />
            <Route exact path={ROUTES.PASSWORD_RESET} component={ResetPassword} />
            <Route exact path={ROUTES.ACCOUNT} component={AccountPage} />
            <Route exact path={ROUTES.ACCOUNT_SETTINGS} component={ExternalLinks} />
          </SwitchWithSlide>
        </Switch>
      </>
    </BrowserRouter>
  );
};

export default withAuthentication(App);
