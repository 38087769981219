export const APPLY_JOB_STEP = "APPLY_JOB_STEP";
export const APPLY_JOB_URL = "APPLY_JOB_URL";
export const SERCH_JOBS_SUCCESS = "SERCH_JOBS_SUCCESS";
export const SELECTED_JOBS_SUCCESS = "SELECTED_JOBS_SUCCESS";
export const SET_CURRENT_PAGE = "SET_CURRENT_PAGE";
export const SET_PREVIOUS_PAGE = "SET_PREVIOUS_PAGE";
export const SET_SEARCH_INPUT_DETAIL = "SET_SEARCH_INPUT_DETAIL";
export const JOB_LOCATION_DETAILS = "JOB_LOCATION_DETAILS";
export const JOB_DETAILS = "JOB_DETAILS";
export const JOB_QUALIFICATION = "JOB_QUALIFICATION";
export const STAGED_JOB_ID = "STAGED_JOB_ID";
export const CLEAR_JOB_DETAILS = "CLEAR_JOB_DETAILS";
export const STAGED_JOB_DETAILS = "STAGED_JOB_DETAILS";
export const APPLY_SEARCHED_JOB = "APPLY_SEARCHED_JOB";
export const JOB_ID = "JOB_ID";
export const IS_UPLOAD_RESUME = "IS_UPLOAD_RESUME";
export const UPLOAD_APPLICATION_DETAIL = "UPLOAD_APPLICATION_DETAIL";
export const IS_UPLOAD_DOC = "IS_UPLOAD_DOC";
export const IS_VIEW_UPLOAD_DOC = "IS_VIEW_UPLOAD_DOC";
export const IS_REMOVE_UPLOADED_DOC = "IS_REMOVE_UPLOADED_DOC";
export const JOB_STEPS_DETAILS = "JOB_STEPS_DETAILS";
export const TOTAL_JOB_STEPS_COUNT = "TOTAL_JOB_STEPS_COUNT";
export const SET_DOCUMENT_STEP = "SET_DOCUMENT_STEP";
export const JOB_STEP_RESUME = "JOB_STEP_RESUME";
export const SEARCH_RESULTS = "SEARCH_RESULTS"
export const SEARCH_EXPAND_RESULT = "SEARCH_EXPAND_RESULT"
export const SUCCESS_UPLOAD_RESUME = "SUCCESS_UPLOAD_RESUME"
