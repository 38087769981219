import React, { useState } from "react";
import { Modal } from "react-bootstrap";

const ExternalJob = ({setShow, show, externalUrl, setShowApplyForJob}) => {
    const [modalCloseStatus, setModalCloseStatus] = useState("close-left");

    const handleClose = () => {
        setShowApplyForJob && setShowApplyForJob(false);
        setShow(false)
        handleModalCloseState("close-left");
    };
    const handleModalCloseState = (status) => {
        setModalCloseStatus(status);
      };

    const handleSubmit = () => {
        setShowApplyForJob && setShowApplyForJob(false);
        setShow(false)
        window.open(externalUrl, '_blank');
    }

    return (
        <Modal
            centered
            show={show}
            onHide={handleClose}
            className={`auth-modal login-modal add-skill-alert ${
                show ? "right" : modalCloseStatus
              }`}
        >
            <Modal.Header style={{display: 'flex', justifyContent: 'flex-end'}}>
                <span
                    className="model-cancel-job"
                    onClick={() => {
                    handleClose();
                    handleModalCloseState("close-right");
                    }}
                >
                    <span className="cancel-job">Cancel</span>
                        <img src="/icon/cancel.svg" className="back-arrow cancel-icon" alt="cancel" />
                    </span>
            </Modal.Header>
            <Modal.Body className="auth-modal-body account-conf-modal-body">
                <div>
                    <span className="from-up-label">Continue on the company's website</span>
                </div>
                <div>
                    <span className="model-body-text">
                        This job is available on the company's website: in order to continue press the button below.
                    </span>
                </div>
                    <button type="submit" className="btn-pwa-signUp" onClick={handleSubmit}>
                        continue
                    </button>
            </Modal.Body>
        </Modal>
    )
}

export default ExternalJob;