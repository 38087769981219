import axios from "axios";
import { ApiGet, ApiPost, ApiPut, ApiDelete } from "../util/Networking";
import {
  STAGED_JOB,
  STAGED_JOBS,
  JOBS,
  CLONE,
  CLOSED,
  APPLICATIONS,
  CANDIDATES,
  STATUS,
  USER,
  FILES,
  APPROVAl_REQUEST,
} from "../constants/api";
import { SEARCH_URL } from "../constants/keys";

export const saveStagedJob = (jobDetail) => {
  return new Promise((resolve, reject) => {
    ApiPost(`${STAGED_JOB}`, { ...jobDetail })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error);
      });
  });
};

export const getStagedJobList = () => {
  return new Promise((resolve, reject) => {
    ApiGet(`${STAGED_JOBS}`)
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error);
      });
  });
};

export const getStagedJob = (id) => {
  return new Promise((resolve, reject) => {
    ApiGet(`${STAGED_JOB}/${id}`)
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error);
      });
  });
};

export const updateStagedJob = async (id, jobDetails) => {
  return new Promise((resolve, reject) => {
    ApiPut(`${STAGED_JOB}/${id}`, {
      ...jobDetails,
    })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error);
      });
  });
};

export const requestApprovalStagedJob = async (id) => {
  return new Promise((resolve, reject) => {
    ApiPut(`${STAGED_JOB}/${id}${APPROVAl_REQUEST}`)
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error);
      });
  });
};

export const approveStagedJob = async (id) => {
  return new Promise((resolve, reject) => {
    ApiPut(`${STAGED_JOB}/${id}/approval`)
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error);
      });
  });
};

export const deleteStagedJob = async (id) => {
  return new Promise((resolve, reject) => {
    ApiDelete(`${STAGED_JOB}/${id}`)
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error);
      });
  });
};

export const publishStagedJob = async (id) => {
  return new Promise((resolve, reject) => {
    ApiPut(`${STAGED_JOB}/${id}/published`)
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error);
      });
  });
};

export const getJobsList = (showDraft, showPublished, showClosed) => {
  return new Promise((resolve, reject) => {
    ApiGet(
      `${JOBS}?showDraft=${showDraft || false}&showPublished=${showPublished || false}&showClosed=${
        showClosed || false
      }`,
    )
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error?.response || error);
      });
  });
};

export const getJobDetails = (id) => {
  return new Promise((resolve, reject) => {
    ApiGet(`${JOBS}/${id}`)
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error);
      });
  });
};

export const cloneJob = (id) => {
  return new Promise((resolve, reject) => {
    ApiPost(`${JOBS}/${id}${CLONE}`)
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error);
      });
  });
};

export const closeJob = (id) => {
  return new Promise((resolve, reject) => {
    ApiPut(`${JOBS}/${id}${CLOSED}`)
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error);
      });
  });
};

export const getApplications = (id) => {
  return new Promise((resolve, reject) => {
    ApiGet(`${JOBS}/${id}${APPLICATIONS}`)
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error);
      });
  });
};

export const getCandidates = () => {
  return new Promise((resolve, reject) => {
    ApiGet(`${CANDIDATES}`)
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error);
      });
  });
};

export const saveApplicationStatus = async (userId, applicationId, status) => {
  return new Promise((resolve, reject) => {
    ApiPut(`${USER}/${userId}${APPLICATIONS}/${applicationId}${STATUS}/${status}`)
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error);
      });
  });
};

export const getApplicationFiles = (userId, applicationId) => {
  return new Promise((resolve, reject) => {
    ApiGet(`${USER}/${userId}${APPLICATIONS}/${applicationId}${FILES}`)
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error);
      });
  });
};

export const findJobs = async (start, pageSize, city, state, zipCode, radius, keywords) => {

  return new Promise((resolve, reject) => {
    axios
      .get(
        `${SEARCH_URL}/findJobs?start=${start}&pageSize=${pageSize}&radius=${radius}&city=${city}&state=${state}&zipCode=${zipCode}&keywords=${keywords}`,
      )
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error);
      });
  });
};
