import React, { useState, useEffect, useRef } from "react";
import { Col, Row, Card, Pagination } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { compose } from "recompose";
import { withFirebase } from "../../components/Firebase";
import * as ROUTES from "../../constants/routes";
import { errorMessage, searchJobsValidationMessage } from "../../util/Validatios";
import { setPreviousPage, setCurrentPage, storeSearchJobDetails } from "../../redux/actions/jobs.action";
import Loader from "../../components/Loader";
import { PAGE_SIZE, DEFAULT_MILES, SEARCH_VALUES } from "../../constants/keys";
import { findJobs } from "../../Action/jobs";
import { OK } from "../../constants/apiStatusCodes";
import { fbSetScreenName, fbSetEvent, fbSetParams } from "../../Action/firebaseAnalytics";
import { OPEN_SCREEN, SEARCH_JOB_RESULT_SCREEN, APP_BUTTON, EXPAND_JOB } from "../../constants/firebaseAnalytics";
import { GetValue } from "../../util/DataRepositry";

export const SearchResults = ({ history }) => {
  const [searchResults, setSearchResults] = useState([]);
  const [selectedJob, setSelectedJob] = useState({});
  const [previous, setPrevious] = useState(-1);
  const [error, setError] = useState("");
  const [hasMore, setHasMore] = useState(false);
  const [start, setStart] = useState(0);
  const [loading, setLoading] = useState(true);

  const selectedJobDetails = useSelector((state) => state.job.selectedJobDetails);
  const currentPage = useSelector((state) => state.job.currentPage);
  const searchJobResult = useSelector((state) => state.job.searchJobDetails);

  const dispatch = useDispatch();
  const searchFilterRef = useRef(null);


  useEffect(() => {
    const element = document.getElementById("searchJobButton");
    element.classList.remove("d-none");
  }, [])

  const handleGetJobs = (startPoint, city, selectedState, zipCode, miles, position) => {
    return findJobs(
      startPoint,
      PAGE_SIZE,
      city || "",
      selectedState || "",
      zipCode,
      miles?.value || DEFAULT_MILES,
      position || "",
    )
      .then((results) => {
        if (results?.status === OK && results?.data?.openings?.length > 0) {
          setSearchResults(results?.data?.openings);
          setHasMore(results?.data?.hasMore);
          dispatch(storeSearchJobDetails(results?.data?.openings));
        } else if (results?.status === OK && results?.data?.openings?.length === 0) {
          setError(searchJobsValidationMessage.noResultFound);
        } else {
          setError(errorMessage.others);
        }
        setLoading(false);
        return results?.data;
      })
      .catch((e) => {
        setError(errorMessage.others);
        setLoading(false);
      });
  };

  const handleSearch = async (startPoint) => {
    if (history?.location?.state?.searchInputDetail) {
      setLoading(true);
      const { zipCode, miles, position, city, selectedState } = history?.location?.state?.searchInputDetail;
      setError("");
      searchFilterRef.current.scrollTo(0, 0);
      await handleGetJobs(startPoint, city, selectedState, zipCode, miles, position);
    }
  };

  const handlePrevious = () => {
    handleSearch(previous);
    setStart(previous);
  };

  const handleSelectJob = (jobDetail) => {
    handleFBEvents(APP_BUTTON, {
      cta: EXPAND_JOB,
    });
    setSelectedJob(jobDetail);

    history.push(ROUTES.JOBS + "/" + jobDetail?.jobId, {
      searchJobDesc: jobDetail,
      currentPage: start - PAGE_SIZE,
      previousPage: previous,
      hasMore: hasMore,
      isSearch: false,
      searchInputDetail: history?.location?.state?.searchInputDetail,
    });
  };

  const handleFBEvents = (eventName, params) => {
    dispatch(fbSetEvent(eventName));
    dispatch(fbSetParams(params));
  };

  const handlefbSetScreen = () => {
    handleFBEvents(OPEN_SCREEN, {});
    dispatch(fbSetScreenName(SEARCH_JOB_RESULT_SCREEN));
  };

  const handleInitSearchjob = async () => {
    const localSearchValue = await GetValue(SEARCH_VALUES);
    const parseLocalValue = await JSON.parse(localSearchValue);
    const searchResponse = await handleGetJobs(
      0,
      parseLocalValue?.city,
      parseLocalValue?.state,
      parseLocalValue?.zipCode,
      parseLocalValue?.miles?.value,
      parseLocalValue?.position,
    );

    const inputValues = {
      position: parseLocalValue?.position,
      city: parseLocalValue?.city,
      zipCode: parseLocalValue?.zipCode,
      miles: parseLocalValue?.miles?.value,
      selectedState: parseLocalValue?.state,
      currentPage: start,
      previousPage: previous,
      hasMore: searchResponse?.hasMore,
      searchJobDetails: searchResponse?.openings,
    };
    // history.pushState({ searchInputDetail: inputValues || {} });
    // history.replace({ ...history.location, searchInputDetail: inputValues });
    history.replace({ state: { searchInputDetail: inputValues } });
  };

  useEffect(() => {
    if (history?.location?.state?.currentPage) {
      setStart(history?.location?.state?.currentPage);
    }
  }, [history?.location?.state?.currentPage]);

  useEffect(() => {
    if (
      Math.sign(history?.location?.state?.previousPage) >= 0 &&
      history?.location?.state?.searchJobDetails?.length > 0
    ) {
      setPrevious(history?.location?.state?.previousPage);
    }
  }, [history?.location?.state?.previousPage]);

  useEffect(() => {
    if (history?.location?.state?.selectedJob) {
      setSelectedJob(history?.location?.state?.selectedJob);
    }
  }, [history?.location?.state?.selectedJob]);

  useEffect(() => {
    if (selectedJobDetails) {
      setSelectedJob(selectedJobDetails);
    }
  }, [selectedJobDetails]);

  useEffect(() => {
    if (history?.location?.state?.hasMore) {
      setHasMore(history?.location?.state?.hasMore);
    }
  }, [history?.location?.state?.hasMore]);

  useEffect(() => {
    if (hasMore && searchResults?.length) {
      setPrevious(start - PAGE_SIZE);
      setStart(start + PAGE_SIZE);
    }
  }, [hasMore, searchResults]);

  useEffect(() => {
    if (currentPage > PAGE_SIZE + 1) {
      dispatch(setPreviousPage(""));
      dispatch(setCurrentPage(""));
    }
  }, [currentPage, dispatch]);

  useEffect(() => {
    if (!history?.location?.state?.isSearch && searchJobResult?.length > 0) {
      setSearchResults(searchJobResult);
      setLoading(false);
    }
  }, [history?.location?.state?.isSearch]);

  useEffect(() => {
    if (!history?.location?.state?.isSearch && searchJobResult?.length === 0) {
      handleInitSearchjob();
    } 
    if (history?.location?.state?.searchJobDetails?.length === 0) {
      setLoading(false);
      setError(searchJobsValidationMessage.noResultFound);
    }
    handlefbSetScreen();
  }, []);

  return (
    <div>
      <div className="container-fluid">
        <Row>
          <Col
            xl={9}
            lg={8}
            col={12}
            className={`joblist-sections edit-filter-block`}
            ref={searchFilterRef}
          >
            <div onClick={() => history.push(ROUTES.SEARCH)} className="d-flex back-edit-filter">
              <img className="float-right fullscreen-icon back-filter-icon" src="/icon/white_back_arrow.svg" alt="back" />
              <label className="edit-filter text-white px-2">Edit filters</label>
            </div>

            {loading && <Loader loaderStyle="search-loader" />}

            {!loading &&
              !error &&
              searchResults?.length > 0 &&
              searchResults?.map((searchVal, index) => {
                return (
                  <Card
                    onClick={() => handleSelectJob(searchVal)}
                    key={index}
                    className={`${
                      searchVal?.jobId === selectedJob?.jobId ? "selected-search-job" : ""
                    } search-application-type`}
                  >
                    <Card.Header className="pl-6">
                      <div className="job-type-title text-center">
                        <Card.Title className="job-type text-brown mb-3 mt-2">{searchVal?.title || ""}</Card.Title>
                        <Card.Subtitle className="mb-2 text-brown job-type-subtitle">
                          {searchVal?.companyName || ""} - {searchVal?.location || ""}
                        </Card.Subtitle>
                      </div>
                    </Card.Header>
                  </Card>
                );
              })}

            {error && (
              <div className="error-msg search-error-container">
                <p>{error}</p>
              </div>
            )}

            {!loading && !error && (
              <Pagination className="pagination-data float-right bottom-pagination">
                {Math.sign(previous) >= 0 && <Pagination.Prev onClick={() => handlePrevious()} />}
                {hasMore && <Pagination.Next onClick={() => handleSearch(start)} />}
              </Pagination>
            )}
          </Col>
        </Row>
      </div>
    </div>
  );
};
export default compose(withRouter, withFirebase)(SearchResults);
