import React, { useEffect, useState } from "react";
import { Modal, Row, Col } from "react-bootstrap";
import { withRouter, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { compose } from "recompose";
import Validator from "validatorjs";
import * as ROUTES from "../../constants/routes";
import { withFirebase } from "../../components/Firebase";
import FloatingInputBox from "../../components/FloatingInputBox";
import LoadingIndicator from "../../components/LoadingIndicator";
import useEnterKeyListener from "../../helpers/useEnterKeyListener";
import { updateUserInformation } from "../../Action/authentication";
import PrivacyPolicyModal from "../PrivacyPolicy/privacyPolicyModal";
import { errorMessage, accountConfirmValidation, accConfirmValidationMsg } from "../../util/Validatios";
import Home from "../Home";
import { APPLY_JOB_STEP, IS_UPLOAD_RESUME, IS_UPLOAD_DOC } from "../../redux/constants/jobs.constants";
import { IS_AUTH, UPLOAD_RESUME_AUTH } from "../../redux/constants/auth.constants";
import { fbSetScreenName, fbSetEvent, fbSetParams } from "../../Action/firebaseAnalytics";
import { OPEN_SCREEN, ACCOUNT_VERIFIED_SCREEN, API_ERROR, SET_PROFILE } from "../../constants/firebaseAnalytics";
import {
  INTRODUCTION,
  APP_NAME,
  SKILLS,
  SKILLS_INTRODUCTION,
  RESUME,
  VIDEO,
  COVER_LETTER,
  VIDEO_INTRODUCTION,
  ADD_PERSONAL_INFO,
} from "../../constants/keys";
import { appFirebaseAuthStateRefresh } from "../../components/Firebase/fbAuthentication";
import { resetLocalAuthToken } from "../../Action/authentication";
import { setAuthHeader } from "../../ApiConfig";
import { OK, BAD_REQUEST } from "../../constants/apiStatusCodes";
import { ClearLocalStorageSessionData } from "../../util/DataRepositry";
import { setDocSteps, applyJobstepResume } from "../../redux/actions/jobs.action";
import Job from "../Job";

import "./index.css";

const AccountConfirmation = ({ firebase, history }) => {
  const [showAccConfirm, setShowAccConfirm] = useState(true);
  const [modalCloseStatus, setModalCloseStatus] = useState("close-left");
  const [error, setError] = useState("");
  const [inputVal, setInputVal] = useState({});
  const [isAcceptTerms, setIsAcceptTerms] = useState(false);
  const [isPrivacyView, setIsPrivacyView] = useState(false);
  const [isPrivacyModal, setIsPrivacyModal] = useState(false);
  const [ischecked, setIschecked] = useState(false);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const location = useLocation();
  const isJobAuth = useSelector((state) => state.auth.isAuth);
  const accountConfirm = useSelector((state) => state.profile.accountConfirm);
  const jobUrl = useSelector((state) => state.job.applyJobURL);
  const isUpdResumeAuth = useSelector((state) => state.auth.isUpdResumeAuth);

  const isAppliedSearchJob = useSelector((state) => state.job.isAppliedSearchJob);
  const authUploadResume = useSelector((state) => state.auth.authUploadResume);
  const updApplicationDetail = useSelector((state) => state.job.updApplicationDetail);
  const jobStepsDetails = useSelector((state) => state.job.jobStepsDetails);

  const handleResetModal = (status) => {
    setShowAccConfirm(status);
  };

  const handleModalCloseState = (status) => {
    setModalCloseStatus(status);
  };

  const handlePrivacyViewer = (status) => {
    setIsPrivacyView(status);
  };

  const onChangeHandler = (evt) => {
    const value = evt.target.value;
    setInputVal({
      ...inputVal,
      [evt.target.name]: value,
    });
  };

  const handleConfirmAccModal = () => {
    handleModalCloseState("close-left");
    setShowAccConfirm(false);

    setTimeout(() => {
      if (accountConfirm) {
        history.push(jobUrl, { jobSteps: INTRODUCTION });
      } else {
        history.push(ROUTES.HOME);
      }
    }, 200);
  };

  const validate = () => {
    let validation = new Validator(
      {
        firstname: inputVal.firstName,
        lastname: inputVal.lastName,
      },
      accountConfirmValidation,
      accConfirmValidationMsg,
    );

    if (validation.fails()) {
      let errorMessages = "";
      if (validation.errors.get("firstname").length) {
        errorMessages = validation.errors.get("firstname");
      } else if (validation.errors.get("lastname").length) {
        errorMessages = validation.errors.get("lastname");
      }

      setError(errorMessages);
      return false;
    }
    return true;
  };

  const renderNextStep = () => {
    const screenName = jobStepsDetails[0].screen;
    dispatch(setDocSteps(screenName));

    switch (screenName) {
      case INTRODUCTION:
        return INTRODUCTION;
      case SKILLS:
      case SKILLS_INTRODUCTION:
      case RESUME:
      case COVER_LETTER:
      case VIDEO_INTRODUCTION:
      case VIDEO:
        return SKILLS;
      default:
        return ADD_PERSONAL_INFO;
    }
  };

  const handleStates = async () => {
    if (isUpdResumeAuth) {
      await dispatch({
        type: IS_UPLOAD_RESUME,
        payload: true,
      });
    } else {
      await dispatch({
        type: APPLY_JOB_STEP,
        payload: renderNextStep(),
      });
    }

    await dispatch({
      type: IS_AUTH,
      payload: false,
    });

    await dispatch({
      type: UPLOAD_RESUME_AUTH,
      payload: false,
    });
  };

  const handleSubmitResume = async () => {
    await dispatch({ type: IS_UPLOAD_DOC, payload: true });
  };

  const handleRefreshAuthToken = async (refreshToken) => {
    await resetLocalAuthToken(refreshToken);
    await setAuthHeader();
  };

  const onAccFormSubmit = () => {
    dispatch(applyJobstepResume(SKILLS))
    if (!validate()) {
      return;
    }

    if (!isAcceptTerms) {
      setError("Please accept the terms of service and privacy policy");
      return;
    }

    setLoading(true);

    const email = location?.state?.userDetails?.email;

    updateUserInformation(inputVal.firstName, inputVal.lastName, email)
      .then(async (res) => {
        setLoading(false);
        if (res.status !== OK) {
          handleFBEvents(API_ERROR, {
            status: res.status,
            apiCall: SET_PROFILE,
          });
          setError(errorMessage.others);
        } else {
          const forcedRefreshToken = await appFirebaseAuthStateRefresh(firebase);
          if (forcedRefreshToken) {
            handleRefreshAuthToken();
          }
          if (accountConfirm) {
            await handleStates();
          } else if (
            isUpdResumeAuth &&
            authUploadResume &&
            updApplicationDetail?.id &&
            updApplicationDetail?.mediaType
          ) {
            await handleSubmitResume();
          } else {
            await handleFBEvents(API_ERROR, {
              status: res.status || BAD_REQUEST,
              apiCall: SET_PROFILE,
            });
          }
          window.handleAuthSuceess({ email, firstName:inputVal.firstName, lastName:inputVal.lastName });
          handleConfirmAccModal();
        }
      })
      .catch((e) => {
        handleFBEvents(API_ERROR, {
          status: e.response.status,
          apiCall: SET_PROFILE,
        });

        setError(errorMessage.others);
        setLoading(false);
      });
  };

  const handleVerifyBackDrop = async () => {
    handleResetModal(false);
    await firebase.doSignOut();
    await ClearLocalStorageSessionData();
    window.location.replace(ROUTES.HOME);
  };

  const handleFBEvents = (eventName, params) => {
    dispatch(fbSetParams(params));
    dispatch(fbSetEvent(eventName));
  };

  const handlefbSetScreen = () => {
    dispatch(fbSetScreenName(ACCOUNT_VERIFIED_SCREEN));
    handleFBEvents(OPEN_SCREEN, {});
  };

  useEffect(() => {
    if (location?.state?.fName || location?.state?.lName) {
      setInputVal({
        firstName: location?.state?.fName || "",
        lastName: location?.state?.lName || "",
      });
    }
  }, [location]);

  useEffect(() => {
    if (showAccConfirm) {
      handlefbSetScreen();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showAccConfirm]);

  useEnterKeyListener({
    querySelectorToExecuteClick: "#AccountVerifiedBtn",
  });

  return (
    <>
      {!isAppliedSearchJob ?  <Home /> : <Job />}
      <Modal
        centered
        show={showAccConfirm}
        onHide={() => handleVerifyBackDrop()}
        className={`auth-modal login-modal ${showAccConfirm ? "right" : modalCloseStatus}`}
        id="app-modal"
      >
        <Modal.Header>
          <div className="reset-password-back">
            <span
              onClick={() => {
                handleModalCloseState("close-right");
                handleResetModal(false);
              }}
            ></span>
          </div>
        </Modal.Header>
        <Modal.Body className="auth-modal-body account-conf-modal-body">
          {loading && <LoadingIndicator loaderStyle="homePageLoader" />}
          <div>
            <div className="credentials-details signup-modal-title accountconf-title">
              <span>Let’s confirm your account</span>
            </div>

            <div className="input-from-container">
              <div className="form-floating">
                <FloatingInputBox
                  id="firstName"
                  name="firstName"
                  placeholder="First Name"
                  type="text"
                  className="form-floating-input auth-floating-input"
                  value={inputVal.firstName}
                  onChangeHandler={onChangeHandler}
                />
              </div>
            </div>
            <div className="input-from-container">
              <div className="form-floating">
                <FloatingInputBox
                  id="lastName"
                  name="lastName"
                  placeholder="Last Name"
                  type="text"
                  className="form-floating-input auth-floating-input"
                  value={inputVal.lastName}
                  onChangeHandler={onChangeHandler}
                />
              </div>
            </div>
            <div className="check-box account-term-container account-conf-terms-container">
              <label>
                <Row className="term-chkbox-row">
                  <Col xl={1} md={1} sm={1} xs={1} className="terms-checkbox-col">
                    <input
                      type="checkbox"
                      name="checkbox-01"
                      className="blue"
                      checked={ischecked}
                      onChange={(e) => {
                        setIsAcceptTerms(e.target.checked);
                        setError("");
                      }}
                      onClick={() => {
                        setIschecked(!ischecked);
                      }}
                    />
                    <span className="accept-terms-condition"></span>
                  </Col>
                  <Col xl={11} md={11} sm={11} xs={11} className="txt-accept-terms-col">
                    <span className="accept-terms-condition">
                      <span className="accept-terms">I accept {APP_NAME}’s</span>
                      <span
                        className="term-condition"
                        onClick={() => {
                          setIsPrivacyModal(false);
                          handlePrivacyViewer(true);
                          setIschecked(!ischecked);
                        }}
                      >
                        {" "}
                        terms of service{" "}
                      </span>
                      <span> and </span>
                      <span
                        className="term-condition"
                        onClick={() => {
                          setIsPrivacyModal(true);
                          handlePrivacyViewer(true);
                          setIschecked(!ischecked);
                        }}
                      >
                        privacy policy
                      </span>
                    </span>
                  </Col>
                </Row>
              </label>
            </div>

            {error ? (
              <div className="error-msg">
                <p>{error}</p>
              </div>
            ) : (
              <></>
            )}

            <div className="login-btn signup-btn">
              <button type="submit" className="btn-pwa-signUp" onClick={() => onAccFormSubmit()}>
                confirm
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <>
        <PrivacyPolicyModal
          isPrivacyView={isPrivacyView}
          handlePrivacyViewer={handlePrivacyViewer}
          isPrivacyModal={isPrivacyModal}
        />
      </>
    </>
  );
};

export default compose(withRouter, withFirebase)(AccountConfirmation);
