import React from "react";
import { withFirebase } from "../../components/Firebase";

const SignOutButton = ({ firebase, handleLogout }) => {
  return (
    <span
      onClick={() => {
        handleLogout && handleLogout();
      }}
    >
      log out
    </span>
  );
};

export default withFirebase(SignOutButton);
