import {
  SET_SCREEN_NAME,
  EVENT_NAME,
  EVENT_PARAMS,
} from "../redux/constants/firebaseAnalytics.constants";

export const fbSetScreenName = (screenName) => (dispatch) => {
  return dispatch({
    type: SET_SCREEN_NAME,
    payload: screenName,
  });
};

export const fbSetEvent = (eventName) => (dispatch) => {
  return dispatch({
    type: EVENT_NAME,
    payload: eventName,
  });
};

export const fbSetParams = (params) => (dispatch) => {
  return dispatch({
    type: EVENT_PARAMS,
    payload: params,
  });
};
