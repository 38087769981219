import React, { useState, useEffect, useMemo } from "react";
import { Col, Row, Dropdown, Form } from "react-bootstrap";
import { useDispatch } from "react-redux";
import AuthModal from "../AuthModal";
import SignInModal from "../SignInModal";
import SignUpModal from "../SignUpModal";
import ForgotPassModal from "../ForgotPasswordModal";
import { fetchUserInformation, updateUserInformation } from "../../Action/authentication";
import * as ROUTES from "../../constants/routes";
import { SEARCH_VALUES } from "../../constants/keys";
import { searchJobsValidationMessage } from "../../util/Validatios";
import { SetValue, GetValue } from "../../util/DataRepositry";
import { setSearchInputDetail } from "../../redux/actions/jobs.action";
import "../../components/Navigation/shimmer.css";
import "./index.css";

const HomePage = (props) => {
  const isMobile = window.innerWidth <= 991;
  const [show, setShow] = useState(false);
  const [showSignIn, setShowSignIn] = useState(false);
  const [showSignUp, setShowSignUp] = useState(false);
  const [inputVal, setInputVal] = useState({});
  const [showForgotPass, setShowForgotPass] = useState(false);
  const [loading, setLoading] = useState(false);
  const [authStatus, setAuthStatus] = useState("signin");
  const [modalCloseStatus, setModalCloseStatus] = useState("close-right");
  const [modalOpenStatus, setModalOpenStatus] = useState("right");
  const [miles, setMiles] = useState("");
  const [selectedState, setSelectedState] = useState("");
  const [selectedCity, setSelectedCity] = useState("");
  const [inputError, setInputError] = useState({});
  const [isDidMount, setDidMount] = useState(true);

  const dispatch = useDispatch();
  const onChangeHandler = (evt) => {
    const value = evt.target.value;

    setInputVal({
      ...inputVal,
      [evt.target.name]: value,
    });
    setInputError({});
  };


  const validate = () => {
    let errorMessages = {};

    if (!miles) {
      errorMessages.message = searchJobsValidationMessage.miles;
      errorMessages.key = "miles";
    } else if (!selectedCity && !selectedState && (!inputVal.zipCode || !isMobile)) {
      errorMessages.message = isMobile ? searchJobsValidationMessage.location : searchJobsValidationMessage.cityState;
      errorMessages.key = "location";
    } else if (!selectedCity && selectedState && (!inputVal.zipCode || !isMobile)) {
      errorMessages.message = isMobile ? searchJobsValidationMessage.location : searchJobsValidationMessage.city;
      errorMessages.key = "city";
    } else if (selectedCity && !selectedState && (!inputVal.zipCode || !isMobile)) {
      errorMessages.message = isMobile ? searchJobsValidationMessage.location : searchJobsValidationMessage.state;
      errorMessages.key = "state";
    } else if (isMobile && !inputVal.zipCode && !selectedCity && !selectedState) {
      errorMessages.message = searchJobsValidationMessage.zipCode;
      errorMessages.key = "zipCode";
    }

    if (errorMessages?.message) {
      setInputError(errorMessages);
      return false;
    } else {
      return true;
    }
  };

  const loaderHandler = (status) => {
    setLoading(status);
  };

  const handleModalCloseState = (closeStatus) => {
    setModalCloseStatus(closeStatus);
  };

  const handleModalOpenState = (openStatus) => {
    setModalOpenStatus(openStatus);
  };

  const handleClose = () => setShow(false);

  const handleShow = () => setShow(true);

  const handleAuthModal = (status) => {
    handleModalCloseState("close-left");
    handleClose();
    setShow(status);
  };

  const handleSignInModal = (status) => {
    handleModalCloseState("close-left");
    handleClose();
    setShowSignIn(status);
  };

  const handleSignUpModal = (status) => {
    handleModalCloseState("close-left");
    handleClose();
    setShowSignUp(status);
  };

  const handleForgotPassModal = (status) => {
    handleModalCloseState("close-left");
    handleSignInModal(false);
    setShowForgotPass(status);
  };

  const submitSearchJobs = () => {
    setInputError({});
    let isSearchValidate = false;
    if ((!isMobile || !inputVal?.zipCode) && !selectedCity && !inputVal?.position && !selectedState && !miles) {
      isSearchValidate = true;
    } else {
      if (!validate()) {
        isSearchValidate = false;
        return false;
      } else {
        isSearchValidate = true;
      }
    }

    if (isSearchValidate && isMobile) {
      const { position, zipCode } = inputVal;
      const searchInputDetail = {
        position,
        city: selectedCity,
        zipCode,
        miles,
        selectedState,
      };

      dispatch(setSearchInputDetail({ ...searchInputDetail }));
      props.history.push(ROUTES.SEARCH_RESULTS, {
        searchJobDetails: {},
        selectedJob: {},
        searchInputDetail: searchInputDetail,
        currentPage: 0,
        previousPage: -1,
        hasMore: false,
        isSearch: true,
      });
    } else if (isSearchValidate && !isMobile) {
      delete inputVal?.zipCode;
      SetValue(
        SEARCH_VALUES,
        JSON.stringify({
          ...inputVal,
          miles: miles,
          city: selectedCity,
          state: selectedState,
        }),
      );
      props.history.push(ROUTES.SEARCH, { homeSearch: true });
    }
  };

  const handleGetLocalstorageValue = async () => {
    const localSearchValue = await GetValue(SEARCH_VALUES);
    const parseLocalValue = JSON.parse(localSearchValue);

    setInputVal({
      zipCode: parseLocalValue?.zipCode,
      position: parseLocalValue?.position,
    });

    setSelectedCity(parseLocalValue?.city);
    setSelectedState(parseLocalValue?.state);
    setMiles(parseLocalValue?.miles);
  };

  

  useEffect(() => {
    setTimeout(() => {
      setDidMount(false);
    }, 500);
  }, []);

  useEffect(() => {
    if (!isDidMount) {
      SetValue(
        SEARCH_VALUES,
        JSON.stringify({
          ...inputVal,
          miles: miles,
          city: selectedCity,
          state: selectedState,
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputVal, selectedState, miles, selectedCity]);

  useEffect(() => {
    handleGetLocalstorageValue();
  }, []);

  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <div
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
      className="select-miles-dropdown"
    >
      <label className="select-dropdown-title">{children}</label>
      <label className="m-0">
        <img src="/icon/down_arrow.svg" alt="open" />
      </label>
    </div>
  ));

  return (
    <div>
      <AuthModal
        show={show}
        handleClose={handleClose}
        handleShow={handleShow}
        handleSignInModal={handleSignInModal}
        handleSignUpModal={handleSignUpModal}
        handleAuthModal={handleAuthModal}
        authStatus={authStatus}
        updateUserInformation={updateUserInformation}
        handleModalCloseState={handleModalCloseState}
        modalCloseStatus={modalCloseStatus}
        handleModalOpenState={handleModalOpenState}
        modalOpenStatus={modalOpenStatus}
      />

      <SignInModal
        showSignIn={showSignIn}
        handleSignInModal={handleSignInModal}
        handleShow={handleShow}
        updateUserInformation={updateUserInformation}
        fetchUserInformation={fetchUserInformation}
        loaderHandler={loaderHandler}
        loading={loading}
        handleModalCloseState={handleModalCloseState}
        modalCloseStatus={modalCloseStatus}
        handleModalOpenState={handleModalOpenState}
        modalOpenStatus={modalOpenStatus}
        handleForgotPassModal={handleForgotPassModal}
      />

      <SignUpModal
        showSignUp={showSignUp}
        handleSignUpModal={handleSignUpModal}
        handleShow={handleShow}
        updateUserInformation={updateUserInformation}
        loaderHandler={loaderHandler}
        loading={loading}
        handleModalCloseState={handleModalCloseState}
        modalCloseStatus={modalCloseStatus}
        handleModalOpenState={handleModalOpenState}
        modalOpenStatus={modalOpenStatus}
      />

      {showForgotPass && (
        <ForgotPassModal
          showForgotPass={showForgotPass}
          handleSignInModal={handleSignInModal}
          handleShow={handleShow}
          handleForgotPassModal={handleForgotPassModal}
          updateUserInformation={updateUserInformation}
          loaderHandler={loaderHandler}
          loading={loading}
          handleModalCloseState={handleModalCloseState}
          modalCloseStatus={modalCloseStatus}
          handleModalOpenState={handleModalOpenState}
          modalOpenStatus={modalOpenStatus}
        />
      )}
    </div>
  );
};

export default HomePage;
