import React from "react";
import AuthUserContext from "./context";
import { withFirebase } from "../Firebase";
import { GetValue, SetValue, ClearLocalStorageSessionData } from "../../util/DataRepositry";
import {
  AUTH_TOKEN,
  FIRST_NAME,
  LAST_NAME,
  EMAIL,
  SESSION_EXPIRATION,
  KEEP_ME_LOGGED_IN,
  IS_SESSION_EXPIRED,
} from "../../constants/keys";
import { SEARCH_JOB_DESCRIPTION, PRIVACY_POLICY, TERMS_CONDITION, SUPPORT, SEARCH, SEARCH_RESULTS, SEARCH_EXPAND, HOME } from "../../constants/routes";

const withAuthentication = (Component) => {
  class WithAuthentication extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        authUser: null,
      };
    }

    componentDidMount() {
      this.listener = this.props.firebase.auth.onAuthStateChanged(async (newAuthUser) => {
        if (newAuthUser) {
          if (newAuthUser.displayName != null) {
            let names = newAuthUser.displayName.split(" ");
            let first_name = names[0];
            let last_name = names.length > 1 ? names[names.length - 1] : "";
            SetValue(FIRST_NAME, first_name);
            SetValue(LAST_NAME, last_name);
          }

          let email = newAuthUser.email != null ? newAuthUser.email : "NO EMAIL";

          SetValue(EMAIL, email);
          newAuthUser.getIdToken().then(function (idToken) {
            SetValue(AUTH_TOKEN, idToken);
          });
          this.setState({ authUser: newAuthUser });
        } else {
          const expiration = await parseInt(GetValue(SESSION_EXPIRATION));
          const currentTime = new Date().getTime();
          let pathName = window.location.pathname;
          if (this.state.authUser) {
            this.setState({ authUser: null });
            if (currentTime > expiration &&
              GetValue(KEEP_ME_LOGGED_IN) !== "true" &&
              GetValue(AUTH_TOKEN)) {  
                //Navigation component will display the session expired message
                SetValue(IS_SESSION_EXPIRED, "true");
            }
            ClearLocalStorageSessionData();
            if (pathName === PRIVACY_POLICY || 
                pathName === TERMS_CONDITION ||
                pathName === SUPPORT ||
                pathName === SEARCH ||
                pathName === SEARCH_RESULTS ||
                pathName === SEARCH_JOB_DESCRIPTION ||
                pathName === SEARCH_EXPAND) {
                  //if the user is on some selected pages, no need to send them to the home page
                  window.location.replace(pathName);
            }
            else if(pathName.split("/")[1] && pathName.split("/")[1].toLowerCase() === "job") { //if user is on /job/[jobId], logout but stay on that page
              window.location.replace(pathName);
            } else {  //otherwise for all the other routes send users to the home page
              window.location.replace(HOME);
            }
          }
        }
      });
    }

    componentWillUnmount() {
      this.listener();
    }

    render() {
      return (
        <AuthUserContext.Provider value={this.state.authUser}>
          <Component {...this.props} />
        </AuthUserContext.Provider>
      );
    }
  }

  return withFirebase(WithAuthentication);
};

export default withAuthentication;
