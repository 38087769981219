import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import { useDispatch } from "react-redux";
import { compose } from "recompose";
import { withFirebase } from "../../components/Firebase";
import useEnterKeyListener from "../../helpers/useEnterKeyListener";
import { SUBMIT_APPLICATION, OPEN_SCREEN } from "../../constants/firebaseAnalytics";
import { fbSetScreenName, fbSetEvent, fbSetParams } from "../../Action/firebaseAnalytics";
import "./index.css";

const SearchJobNotFound = ({ history, handleShowSearchAlert, title, message }) => {
  const [showSearchAlert, setShowSearchAlert] = useState(false);
  const [modalCloseStatus, setModalCloseStatus] = useState("close-left");
  const dispatch = useDispatch();

  const handleResetModal = (status) => {
    setShowSearchAlert(status);
  };

  const handleModalCloseState = (status) => {
    setModalCloseStatus(status);
  };

  const handleCloseConfModal = () => {
    handleModalCloseState("close-left");
    setShowSearchAlert(false);
    setTimeout(() => {
      handleShowSearchAlert(false);
    }, 100);
  };

  const handleJobBackDrop = () => {
    handleResetModal(false);

    setTimeout(() => {
      handleShowSearchAlert(false);
    }, 100);
  };

  const handleFBEvents = (eventName, params) => {
    dispatch(fbSetParams(params));
    dispatch(fbSetEvent(eventName));
  };

  useEffect(() => {
    setShowSearchAlert(true);
  }, []);

  useEnterKeyListener({
    querySelectorToExecuteClick: "#addSkillAlertBtn",
  });

  const handlefbSetScreen = (screenName) => {
    dispatch(fbSetScreenName(screenName));
    handleFBEvents(OPEN_SCREEN, {});
  };

  useEffect(() => {
    if (showSearchAlert) {
      handlefbSetScreen(SUBMIT_APPLICATION);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showSearchAlert]);

  return (
    <>
      <Modal
        centered
        show={showSearchAlert}
        onHide={() => handleJobBackDrop()}
        className={`auth-modal login-modal add-skill-alert ${showSearchAlert ? "right" : modalCloseStatus}`}
      >
        <Modal.Header>
          <div className="reset-password-back">
            <span
              onClick={() => {
                handleModalCloseState("close-right");
                handleResetModal(false);
              }}
            ></span>
          </div>
        </Modal.Header>
        <Modal.Body className="search-notfound-modal-body">
          <div>
            <div className="search-alert-title mb-4">Job posting not found</div>
            <div className="job-sub-subtitle job-search-msg">{message || "Alert"}</div>

            <div className="login-btn btn-confirm-container">
              <button
                type="button"
                id="addSkillAlertBtn"
                className="btn-close-confirm btn-search-alert"
                onClick={() => handleCloseConfModal()}
              >
                close
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default compose(withRouter, withFirebase)(SearchJobNotFound);
